import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { OperatorFunction, Subscription } from 'rxjs';
import {
  FailedToLoadList,
  ListLoaded,
  LoadList,
  LoadListBO,
  OfferLoaded,
  ResetRequestsOverviewState,
  SetActiveFilter,
  SetFacilityUuid,
  SetFilterPage,
  SetOfferType,
  SetRefNumber,
  SuccessfulDeletedAgencyOffer,
  SuccessfulDeletedOffer
} from '@libs/request-overview-common/state/requests-overview.actions';
import { cloneDeep, identity, keys, pickBy } from 'lodash-es';
import { Actions, ofType } from '@ngrx/effects';
import {
  selectMroList,
  selectPage,
  selectPageSize,
  selectResultList,
  selectSelectedEntityUuid,
  selectTotalElements
} from '@libs/request-overview-common/state/requests-overview.selectors';
import { role, RoleWrapper, UserRoles } from '@libs/shared/models/roles.enum';
import {
  CUSTOM_REFERENCE_NUMBER_MAX_LENGTH,
  OfferOutDto,
  OfferType
} from '@libs/shared/models/offer.model';
import {
  getFilteredApiRoot,
  getLoggedInUserRole
} from '@libs/shared/bms-common/api-root/api-root.selectors';
import { getEmbeddedResource } from '@libs/shared/bms-common/rest/resource.utils';
import { ApiRootLinkRel } from '@libs/shared/linkrels/api-root.linkrel';
import { OfferSubmitSuccess } from '@libs/create-offer-page/create-offer.actions';
import { SimpleFacilityWithUuid } from '@libs/shared/models/facility.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ApiRootResource } from '@libs/shared/bms-common/api-root/api-root.model';
import { AgencyOfferSubmitSuccess } from '@libs/create-agency-offer-page/create-agency-offer.actions';
import { isPlatformWeb } from '@libs/shared/helpers/capacitor';
import { FilterConfigModel } from '@libs/staff-filters/models/filter-config.model';
import { FilterTypes } from '@libs/staff-filters/constants/FilterTypes';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'staffnow-request-listing',
  templateUrl: './request-listing.component.html',
  styleUrls: ['./request-listing.component.scss']
})
export class RequestListingComponent implements OnInit, OnDestroy {
  readonly CUSTOM_REFERENCE_NUMBER_MAX_LENGTH =
    CUSTOM_REFERENCE_NUMBER_MAX_LENGTH;
  @Input() actingAs: UserRoles = null;
  @Input() isMyAgencyTab: boolean = false;

  hideFilters = !isPlatformWeb();
  overflowHidden = !isPlatformWeb();
  offerList: Array<OfferOutDto> = [];
  effectiveRole: RoleWrapper = null;
  isLoading: boolean = false;
  page = 0;
  pageSize = 0;
  totalElements = 0;
  filters;
  refNumberSearch: string = null;
  userRole: RoleWrapper = null;
  selectedTechnicianUuid: string = '';
  component = null;
  offerType: OfferType = null;

  private mroList: Array<{ name: string; value: string; uuid: string }> = [];
  private selectedFacility: { name: string; uuid: string } = null;
  private defaultFilter: string = 'active';
  private defaultPage: number = 0;
  private subscription: Subscription = new Subscription();

  loggedInUserRole: RoleWrapper = null;
  private apiRoot: ApiRootResource = null;
  isLastPage = false;

  protected readonly isPlatformWeb: boolean = isPlatformWeb();

  constructor(
    public store: Store<any>,
    public actions: Actions,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService
  ) {
    this.store.pipe(getLoggedInUserRole, untilDestroyed(this)).subscribe(r => {
      this.loggedInUserRole = role(r);
    });
  }

  ngOnInit() {
    this.setupStoreSubscriptions();
    this.setupActionsSubscriptions();
    this.watchQueryParams();
  }

  private watchQueryParams() {
    this.subscription.add(
      this.activatedRoute.queryParams.subscribe(params =>
        this.updateFromQueryParams(params)
      )
    );
  }

  private updateFromQueryParams(params: Params) {
    if (!this.isPlatformWeb) {
      this.offerList = [];
    }
    this.refNumberSearch = params.refNumber ?? null;
    this.selectedFacility = params.facilityUuid
      ? this.mroList.find(item => item.uuid == params.facilityUuid)
      : null;
    this.offerType = params.offerType ?? OfferType.TEMPORARY;
    const page = params.page || this.defaultPage;
    const filter: string = params.filter || this.defaultFilter;
    this.store.dispatch(SetRefNumber({ refNumber: this.refNumberSearch }));
    this.store.dispatch(
      SetFacilityUuid({ facilityUuid: this.selectedFacility?.uuid })
    );
    this.store.dispatch(SetFilterPage({ page: page - 1 }));
    this.store.dispatch(SetOfferType({ offerType: this.offerType }));
    this.enableFilter(filter);
  }

  private setupStoreSubscriptions() {
    this.storeSubscribe(select(selectPage), page => (this.page = page + 1));
    this.storeSubscribe(
      select(selectPageSize),
      pageSize => (this.pageSize = pageSize)
    );
    this.storeSubscribe(
      select(selectTotalElements),
      totalElements => (this.totalElements = totalElements)
    );
    this.storeSubscribe(select(selectResultList), resultList => {
      if (this.isPlatformWeb) {
        this.offerList = resultList;
      } else {
        if (this.offerList.length > 0) {
          this.offerList.push(...resultList);
        } else {
          this.offerList = cloneDeep(resultList);
        }
        this.isLastPage = this.page * this.pageSize >= this.totalElements;
      }
    });
    this.storeSubscribe(
      select(selectMroList),
      (mroList: SimpleFacilityWithUuid[]) => {
        this.mroList = mroList.map(item => ({
          name: item.name,
          value: item.name,
          uuid: item.uuid
        }));
      }
    );
    this.storeSubscribe(getFilteredApiRoot, apiRoot => {
      this.userRole = role(
        getEmbeddedResource(apiRoot, ApiRootLinkRel.AuthorizedUserProfile)[
          'role'
        ]
      );
      this.setEffectiveRole(this.actingAs || this.userRole.getRole());
    });
    this.storeSubscribe(select(selectSelectedEntityUuid), uuid => {
      this.selectedTechnicianUuid = uuid;
      if (this.actingAs) {
        this.store.dispatch(LoadListBO());
      }
    });
    this.storeSubscribe(getFilteredApiRoot, apiRoot => {
      this.apiRoot = cloneDeep(apiRoot);
    });
  }

  private setupActionsSubscriptions() {
    this.onAction(LoadList, () => (this.isLoading = true));
    this.onAction(FailedToLoadList, () => (this.offerList = []));
    this.onAction(
      SuccessfulDeletedOffer,
      OfferSubmitSuccess,
      SuccessfulDeletedAgencyOffer,
      AgencyOfferSubmitSuccess,
      () => this.triggerListLoad()
    );
    this.onAction(
      OfferLoaded,
      ListLoaded,
      FailedToLoadList,
      () => (this.isLoading = false)
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.store.dispatch(ResetRequestsOverviewState());
  }

  pageChanged($event): void {
    this.search(undefined, $event.page);
  }

  private getEnabledFilterKey() {
    return keys(pickBy(this.filters, identity))[0];
  }

  clearSearch() {
    this.refNumberSearch = null;
    this.onSearchByRefNumber();
  }

  pressedKeyValidation($event) {
    this.refNumberSearch = this.refNumberSearch?.trim();
    const { keyCode, key } = $event;
    const pattern = new RegExp(/^[a-z0-9]+$/i);

    if (!key.match(pattern) || this.refNumberSearch?.length >= 8) {
      $event.preventDefault();
    }

    const ENTER_KEY = 13;
    if (keyCode === ENTER_KEY) {
      this.onSearchByRefNumber();
    }
  }

  onSearchByRefNumber() {
    this.store.dispatch(SetRefNumber({ refNumber: this.refNumberSearch }));
    this.search();
  }

  toggleFilters(filter: string): void {
    this.offerList = [];
    this.search(filter);
  }

  search(filter: string = this.getEnabledFilterKey(), page: number = 1): void {
    this.toggleHideFilters();
    this.router.navigate([], {
      queryParamsHandling: 'merge',
      queryParams: {
        filter: filter,
        refNumber: this.refNumberSearch,
        page: page,
        facilityUuid: this.selectedFacility?.uuid
      }
    });
  }

  private storeSubscribe<T, S>(
    pipedSelector: OperatorFunction<T, S>,
    subscribeFn: (a: S) => void
  ) {
    this.subscription.add(
      this.store.pipe(pipedSelector).subscribe(subscribeFn)
    );
  }

  private onAction(...args: any[]) {
    const subscribeFn = args.pop();
    this.subscription.add(
      this.actions.pipe(ofType(...args)).subscribe(subscribeFn)
    );
  }

  get shouldDisplayPending(): boolean {
    if (this.offerType == OfferType.FIXED_PRICE) {
      return this.isMro || this.isAgency;
    }
    if (this.offerType == OfferType.AGENCY) {
      return this.isAgency;
    }
    return this.isMro || this.isTechnician;
  }

  get shouldDisplayDocumentsNeeded(): boolean {
    if (this.offerType == OfferType.FIXED_PRICE) {
      return false;
    }
    return !this.isMro;
  }

  get shouldDisplayRejected(): boolean {
    if (this.offerType == OfferType.FIXED_PRICE) {
      return !this.isTechnician;
    }
    return true;
  }

  get isMro(): boolean {
    return this.userRole.isMro();
  }

  get isTechnician(): boolean {
    return this.userRole.isTechnician();
  }

  get isAgency(): boolean {
    return this.userRole.isAgency() && !this.effectiveRole.isTechnician();
  }

  get isAgencyOwnTechnician(): boolean {
    return this.userRole.isAgency() && this.effectiveRole.isTechnician();
  }

  get hasMroList(): boolean {
    return this.mroList.length > 1;
  }

  get isPackageOffer(): boolean {
    return this.offerType === OfferType.FIXED_PRICE;
  }

  get isTemporaryJobOpening(): boolean {
    return this.offerType === OfferType.TEMPORARY;
  }

  get isPermanentJobOpening(): boolean {
    return this.offerType === OfferType.PERMANENT;
  }

  private enableFilter(filterKey: string) {
    this.resetFilters();
    this.filters[filterKey] = true;
    this.store.dispatch(SetActiveFilter({ activeFilter: filterKey }));
    this.triggerListLoad();
  }

  private resetFilters() {
    this.filters = {
      all: false,
      active: false,
      pending: false,
      past: false,
      rejected: false,
      documentsNeeded: false
    };
  }

  setEffectiveRole(userRoles: UserRoles | string) {
    this.effectiveRole = role(userRoles);
  }

  private triggerListLoad() {
    if (this.actingAs) {
      this.store.dispatch(LoadListBO());
    } else {
      this.store.dispatch(
        LoadList({ role: this.effectiveRole.getRole() as any })
      );
    }
  }

  navigateToCreateTemporaryJobOpeningForm(): void {
    this.router.navigate(['offer'], {
      state: {
        preselectedFilters: {},
        isPrivate: false,
        selectedTechnicians: [],
        isEdit: false,
        mroUuid: this.selectedFacility?.uuid,
        breadcrumbs: [
          this.translateService.instant('AGENCY.DETAILS.TITLE_JO_APPLICATIONS'),
          this.translateService.instant('REQUEST_LISTING.CREATE_TEMPORARY_JOB_OPENING'),
        ]
      }
    });
  }

  navigateToCreatePermanentJobOpeningForm(): void {
    this.router.navigate(['permanent-offer'], {
      state: {
        preselectedFilters: {},
        isPrivate: false,
        selectedTechnicians: [],
        isEdit: false,
        mroUuid: this.selectedFacility?.uuid,
        breadcrumbs: [
          this.translateService.instant('AGENCY.DETAILS.TITLE_JO_APPLICATIONS'),
          this.translateService.instant('REQUEST_LISTING.CREATE_PERMANENT_JOB_OPENING'),
        ]
      }
    });
  }

  navigateToCreatePackageOfferForm(): void {
    this.router.navigate(['package-offer'], {
      state: {
        isEdit: false,
        mroUuid: this.selectedFacility?.uuid,
        breadcrumbs: [
          this.translateService.instant('AGENCY.DETAILS.TITLE_JO_APPLICATIONS'),
          this.translateService.instant('REQUEST_LISTING.CREATE_FIXED_PRICE_JOB_OPENING'),
        ]
      }
    });
  }

  getConfig(): FilterConfigModel {
    return {
      type: FilterTypes.Text,
      placeholder: 'REQUEST_LISTING.SELECT_AVIATION_COMPANY',
      multiple: false,
      items: this.mroList,
      isIdText: true,
      initialValue: this.selectedFacility?.name
    };
  }

  onSelectedMroChange(selectedName: string): void {
    this.selectedFacility = this.mroList.find(
      item => item.name == selectedName
    );
    this.search();
  }

  get canCreatePackageOffers(): boolean {
    return this.isPackageOffer && this.apiRoot.allowsPackageOffers;
  }

  get canCreatePermanentOffers(): boolean {
    return this.isPermanentJobOpening && this.apiRoot.allowsPermanentJobOffers;
  }

  loadMoreOffers() {
    this.page = this.page + 1;
    this.store.dispatch(SetFilterPage({ page: this.page - 1 }));
    this.triggerListLoad();
  }

  toggleHideFilters(): void {
    this.hideFilters = !this.hideFilters;
    if (this.hideFilters) {
      this.toggleOverflow();
    } else {
      setTimeout(() => this.toggleOverflow(), 1000);
    }
  }

  toggleOverflow(): void {
    this.overflowHidden = !this.overflowHidden;
  }
}
