import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {take, withLatestFrom} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';

import {TechnicianRegistrationFormModel} from './technician-registration-form/technician-registration-form.model';
import {
  GetSlideshowPictures,
  LoadSharedOffer,
  RegistrationAction,
  RegistrationActionTypes,
  ResetForm,
  SharedOfferLoaded,
  TechnicianRegistrationFormSubmitted
} from '../state/registration.actions';
import {Actions, ofType} from '@ngrx/effects';
import {registrationComponentStatus} from '../state/registration.selectors';
import {isFixedPrice, OfferType, SharedAgencyOfferOutDto, SharedFixedPriceOfferOutDto, SharedOfferOutDto} from '@libs/shared/models/offer.model';
import {getUrl} from '@libs/shared/bms-common/rest/resource.utils';
import {getStaticEnvironment} from '@libs/shared/bms-common/environment/environment.selector';
import {BrandName, EnvironmentState} from '@libs/shared/bms-common/environment/environment.model';
import {getApiRoot} from '@libs/shared/bms-common/api-root/api-root.selectors';
import {isPlatformWeb} from "@libs/shared/helpers/capacitor";
import {AuthenticationService} from "@libs/auth/services/authentication.service";
import {EnvironmentLinkRel} from "@libs/shared/linkrels/environment.linkrel";

declare var grecaptcha: any;

@Component({
  selector: 'staffnow-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['registration.component.scss', 'common.scss']
})
export class RegistrationComponent implements OnInit, OnDestroy {
  private readonly AGENCY_JOB_URL: string = '/sharing/offers/agency';
  private readonly TEMPORARY_JOB_URL: string = '/sharing/offers';
  private readonly FIXED_PRICE_JOB_URL: string = '/sharing/offers/fixed-price';
  private subs: Subscription[] = [];
  public googleRecaptchaSiteKey = '';
  public showOffer: boolean = false;
  public slideshowPictures: any[] = [];
  private selectedSlide: number;
  public sharedOffer: SharedOfferOutDto | SharedAgencyOfferOutDto | SharedFixedPriceOfferOutDto = null;

  public registerStatus: 'IDLE' | 'SUCCESS' | 'ERROR' | 'LOADING' | 'LOGIN' = 'IDLE';
  public registerEntity: 'MRO' | 'TECHNICIAN' | null = null;
  public brandName: string = '';
  public privacyPolicyUrl: string = '';
  public termsAndConditionsUrl: string = '';
  public infoEmail: string = '';
  public errorMessage: string = '';
  public customerSupportEmail: string = '';
  public privacyPolicyLink: string = '';
  public termsAndConditionsLink: string = '';

  protected readonly isPlatformWeb: boolean = isPlatformWeb();

  get loginAttempt(): boolean {
    return this.registerStatus === 'LOGIN';
  }

  get registerIdle(): boolean {
    return this.registerStatus === 'IDLE';
  }

  get registerLoading(): boolean {
    return this.registerStatus === 'LOADING';
  }

  get registerError(): boolean {
    return this.registerStatus === 'ERROR';
  }

  get registerSuccessful(): boolean {
    return this.registerStatus === 'SUCCESS';
  }

  get isEntityNull(): boolean {
    return this.registerEntity == null;
  }

  get isEntityTechnician(): boolean {
    return this.registerEntity === 'TECHNICIAN';
  }

  get isEntityMro(): boolean {
    return this.registerEntity === 'MRO';
  }

  public openTechnicianRegistrationForm(): void {
    this.registerEntity = 'TECHNICIAN';
  }

  public openMroRegistrationForm(): void {
    this.registerEntity = 'MRO';
  }

  constructor(
    private store: Store<any>,
    private actions: Actions<RegistrationAction>,
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService
  ) {
    this.store.pipe(getStaticEnvironment).subscribe(env => {
      this.brandName = env.brandConfig.brandName;
      this.privacyPolicyUrl = env.brandConfig.privacyPolicy;
      this.termsAndConditionsUrl = env.brandConfig.termsAndConditions;
      this.infoEmail = env.brandConfig.infoEmail;
    });
  }

  ngOnInit() {
    this.watchRegistrationState();
    this.initEnvironment();
    this.watchOfferId();
    this.waitForSlideshowPictures();
    this.waitForSharedOffer();
    this.goToTechnicianForm();
  }

  private goToTechnicianForm() {
    this.route.queryParams.subscribe(queryParams => {
      if (queryParams['account'] === 'technician')
        this.openTechnicianRegistrationForm();
    });
  }

  public submitTechnicianRegistrationForm(
    formValue: TechnicianRegistrationFormModel
  ): void {
    grecaptcha.ready((): void => {
      grecaptcha
        .execute(this.googleRecaptchaSiteKey, {action: 'submit'})
        .then((token: string): void => {
          this.dispatchRegistrationSubmission(token, formValue);
        });
    });
  }

  private dispatchRegistrationSubmission(
    token: string,
    formValue: TechnicianRegistrationFormModel
  ) {
    if (this.isEntityTechnician) {
      const payload = formValue as TechnicianRegistrationFormModel;
      this.store.dispatch(
        new TechnicianRegistrationFormSubmitted(token, {
          ...payload
        })
      );
    }
  }

  public resetFormCallback = (): void => {
    this.resetForm();
  };

  public resetForm(): void {
    this.store.dispatch(new ResetForm());
  }

  public login(): void {
    this.registerStatus = 'LOGIN';
  }

  public ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  public getSlide(): string {
    return this.slideshowPictures[this.selectedSlide];
  }

  private watchRegistrationState(): void {
    this.subs.push(
      this.store
        .select(registrationComponentStatus)
        .subscribe(registerComponent => {
          this.registerStatus = registerComponent.registerStatus;
          this.errorMessage = registerComponent.errorMessage;
          this.registerEntity = registerComponent.registerEntity;
        })
    );
  }

  private initEnvironment(): void {
    this.subs.push(
      this.store.pipe(getStaticEnvironment).subscribe((env: EnvironmentState) => {
        this.googleRecaptchaSiteKey = env.googleRecaptchaSiteKey;
        this.customerSupportEmail = env.brandConfig.customerSupportEmail;
        this.privacyPolicyLink = env.brandConfig.privacyPolicy;
        this.termsAndConditionsLink = env.brandConfig.termsAndConditions;
        this.store.dispatch(new GetSlideshowPictures(env));
        const currentUrl: string = this.router.url;
        if (currentUrl.includes('/sharing/')) {
          const urlParts = window.location.href.split('/');
          const refNumber = urlParts.pop();
          this.store.dispatch(LoadSharedOffer({refNumber, linkRel: this.getLinkRel(currentUrl)}));
        }
      })
    );
  }

  private getLinkRel(currentUrl: string): string {
    if (currentUrl.includes(this.FIXED_PRICE_JOB_URL)) {
      return EnvironmentLinkRel.FixedPriceJobShare;
    } else if (currentUrl.includes(this.AGENCY_JOB_URL)) {
      return EnvironmentLinkRel.AgencyShare;
    } else if (currentUrl.includes(this.TEMPORARY_JOB_URL)) {
      return EnvironmentLinkRel.Share;
    }
    throw Error('Unknown sharing!');
  }

  private getOfferTypeParam(currentUrl: string): OfferType {
    if (currentUrl.includes(this.FIXED_PRICE_JOB_URL)) {
      return OfferType.FIXED_PRICE;
    } else if (currentUrl.includes(this.AGENCY_JOB_URL)) {
      return OfferType.AGENCY;
    } else {
      return OfferType.TEMPORARY;
    }
  }

  private watchOfferId(): void {
    this.subs.push(
      this.getApiRootWithParams$().subscribe(([apiRoot, paramsMap]) => {
        if (apiRoot && this.authenticationService.shouldRedirectAfterLogin) {
          const routeConfig: NavigationExtras = {
            queryParams: {
              refNumber: paramsMap.get('offerRef'),
              offerType: this.getOfferTypeParam(this.router.url)
            }
          };
          this.router.navigate(['job-overview'], routeConfig);
        }

        if (paramsMap.get('offerRef')) {
          this.showOffer = true;
        }
      })
    );
  }

  private waitForSlideshowPictures(): void {
    this.subs.push(
      this.actions
        .pipe(ofType(RegistrationActionTypes.SlideshowPicturesLoaded), take(1))
        .subscribe(action => {
          this.slideshowPictures = action.payload.map(pic => getUrl(pic));
          this.selectedSlide = Math.floor(
            Math.random() * this.slideshowPictures.length
          );
        })
    );
  }

  private waitForSharedOffer(): void {
    this.subs.push(
      this.actions
        .pipe(ofType(SharedOfferLoaded), take(1))
        .subscribe(action => {
          this.sharedOffer = action.offer;
        })
    );
  }

  private getApiRootWithParams$() {
    return this.store.pipe(
      select(getApiRoot),
      withLatestFrom(this.route.paramMap)
    );
  }

  public goToResetPassword(): void {
    this.store
      .pipe(getStaticEnvironment)
      .subscribe((env: EnvironmentState) => {
        window.location.assign(
          `${env.auth.initialUrl}/request-new-password`
        );
      });
  }

  public isStaffnowBrand(): boolean {
    return this.brandName == BrandName.StaffNow;
  }

  public isFixedPriceOffer(): boolean {
    return isFixedPrice(this.sharedOffer);
  }

}
