import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {ActionsSubject, Store} from '@ngrx/store';

import {UserProfileService} from '../../services/user-profile.service';
import {
  AgencyOrderingListLoaded,
  GetAgencyOrderingList,
  GetMroList,
  GetMroListForApiUrl,
  MroListLoaded
} from 'apps/back-office/src/app/state/app.actions';
import {map, take, tap} from 'rxjs/operators';
import {
  AddTechnicianUserToAgency,
  GrantCrossRegionAccess,
  RemoveTechnicianUserFromAgency,
  UpdateProfileStatus
} from '../../state/user-profile.actions';
import {ofType} from '@ngrx/effects';
import {ModalService} from '@libs/common-ui/services/modal.service';
import {Availability, DayObject} from '@libs/shared/models/availability.model';
import {getEmbeddedResource, getUrl, hasEmbeddedResource, hasLink} from '@libs/shared/bms-common/rest/resource.utils';
import {UserProfileLinkRel} from '@libs/shared/linkrels/user-profile.linkrel';
import {accountStatusConstants, profileStatusConstants} from '@libs/shared/constants/statuses.constants';
import {AbstractProfileComponent} from '@libs/user-profile/components/abstract-profile-component';
import {DocumentsService} from '@libs/user-profile/services/documents.service';
import {ActivatedRoute} from '@angular/router';
import {ConversationService} from '../../../../../../apps/staffnow-platform/src/app/modules/request-overview/services/conversation.service';
import {RequestOverviewLinkRel} from '@libs/shared/linkrels/request-overview.linkrel';
import {role} from '@libs/shared/models/roles.enum';
import {AppRegionEnum} from '@libs/shared/bms-common/environment/environment.model';
import {BsModalService} from 'ngx-bootstrap/modal';
import {SelectEntityFromListModalComponent} from '@libs/common-ui/select-entity-from-list-modal/select-entity-from-list-modal.component';
import {UntilDestroy} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'staffnow-user-info-section',
  templateUrl: './user-info-section.component.html',
  styleUrls: ['./user-info-section.component.scss']
})
export class UserInfoSectionComponent
  extends AbstractProfileComponent
  implements OnChanges {
  @Input() public canMarkInReview: boolean = false;
  @Input() public selectedAircraftsArray: Array<any>;
  @Input() public availability: Availability;
  @Output() public goToConversation: EventEmitter<any> = new EventEmitter();
  @Output() public isStaffie: EventEmitter<{
    isStaffie: boolean;
    mroUuid: string;
    wasAlreadyStaffie: boolean;
  }> = new EventEmitter();

  public currentMonth: string = '';
  public mappedPeriods = new Map<string, DayObject>();
  public agenciesList: Array<{ uuid: string; name: string }> = [];
  public mrosList: Array<{ uuid: string; name: string }> = [];
  public mrosListFromOtherRegion: Array<{ uuid: string; name: string }> = [];
  public linkedToAgency: boolean = false;
  public isStaffieChecked: boolean = false;
  public canConfigureAutomaticReply: boolean = false;
  private lastAgencySelectInputDateTime: any;

  constructor(
    store: Store,
    documentsService: DocumentsService,
    activatedRoute: ActivatedRoute,
    conversationService: ConversationService,
    private modalService: ModalService,
    private userProfileService: UserProfileService,
    private actionsSubject: ActionsSubject,
    private bsModalService: BsModalService
  ) {
    super(store, documentsService, activatedRoute, conversationService);
    this.store.dispatch(GetAgencyOrderingList());
    this.store.dispatch(GetMroList());
    this.addSub(
      this.actionsSubject
        .pipe(
          ofType(AgencyOrderingListLoaded),
          map(action => action.payload),
          map(agencies =>
            agencies.map(agency => ({uuid: agency.uuid, name: agency.name}))
          ),
          tap(agenciesList => (this.agenciesList = agenciesList))
        )
        .subscribe()
    );
    this.addSub(
      this.actionsSubject
        .pipe(
          ofType(MroListLoaded),
          map(action => action.payload),
          map(mros => mros.map(mro => ({uuid: mro.uuid, name: mro.name}))),
          tap(mrosList => (this.mrosList = mrosList)),
          take(1)
        )
        .subscribe(() => {
        })
    );
    this.onUserProfileChange(() => {
      this.canConfigureAutomaticReply = hasLink(this.userProfile, UserProfileLinkRel.ConfigureAutomaticReply);
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.lastAgencySelectInputDateTime = new Date().getTime();
  }

  //TODO(SN-988): to be refactored
  ngOnChanges(simpleChanges: SimpleChanges) {
    if (
      (this.availability.months.length > 0 && this.currentMonth === '') ||
      simpleChanges.availability
    ) {
      this.currentMonth = this.availability.months[0];
      this.mappedPeriods = this.userProfileService.computeEventMap(
        this.availability._embedded.calendarEvents
      );
    }
    this.isStaffieChecked = this.isUserStaffie();
  }

  public verifyAccount() {
    this.modalService.openConfirmModal(
      'SYSTEM.INFO.VERIFY_TECHNICIAN_PROFILE',
      () => this.handleProfileStatusChange(profileStatusConstants.verified)
    );
  }

  public markAsInReview() {
    this.modalService.openConfirmModal(
      'SYSTEM.INFO.ACCOUNT_UNDER_REVIEW',
      () => this.handleProfileStatusChange(profileStatusConstants.inReview)
    );
  }

  public deactivateAccount() {
    this.modalService.openConfirmModal(
      'SYSTEM.INFO.DEACTIVATE_PROFILE',
      () => this.handleAccountStatusChange(true)
    );
  }

  public activateAccount() {
    this.handleAccountStatusChange(false);
  }

  public isUserStaffie() {
    return this.userProfile?._embedded.profile.isStaffie;
  }

  public goToMailbox() {
    this.goToConversation.emit();
  }

  public shouldShowSendMessageButton() {
    return (
      hasLink(this.userProfile, UserProfileLinkRel.GetConversation) ||
      hasLink(this.userProfile, UserProfileLinkRel.CreateConversation)
    );
  }

  public isStaffieChangedHandler(value: boolean) {
    this.isStaffieChecked = value;
    if (this.isUserStaffie() && !value) {
      this.handleStaffieForMro(null);
    }
  }

  public handleStaffieForMro(mroUuid: string) {
    this.isStaffie.emit({
      isStaffie: this.isStaffieChecked,
      mroUuid: mroUuid,
      wasAlreadyStaffie: this.isUserStaffie()
    });
  }

  public isAgencyTechnician() {
    return hasEmbeddedResource(this.userProfile, 'agency');
  }

  public agencyTechnicianHandler(event, value: boolean) {
    this.linkedToAgency = value;
    const payload = {
      agencyUuid: hasEmbeddedResource(this.userProfile, 'agency')
        ? getEmbeddedResource(this.userProfile, 'agency')['uuid']
        : null,
      removeFromAgencyUrl: hasLink(this.userProfile, 'removeFromAgency')
        ? getUrl(this.userProfile, 'removeFromAgency')
        : ''
    };
    if (!value) {
      if (this.isAgencyTechnician()) {
        this.modalService.openConfirmModal(
          'SYSTEM.INFO.REMOVE_TECHNICIAN_FROM_AGENCY_CONFIRMATION',
          () => {
            this.store.dispatch(RemoveTechnicianUserFromAgency({payload}));
          }
        );
      }
    }
  }

  public getAgency(agencyUuid: string) {
    const throttle =
      new Date().getTime() - this.lastAgencySelectInputDateTime > 400;
    if (throttle) {
      const payload = {
        agencyUuid: agencyUuid,
        addToAgencyUrl: hasLink(this.userProfile, 'addToAgency')
          ? getUrl(this.userProfile, 'addToAgency')
          : ''
      };
      this.store.dispatch(AddTechnicianUserToAgency({payload}));
    }
    this.lastAgencySelectInputDateTime = new Date().getTime();
  }

  public isProfileVerified(): boolean {
    return (
      this.userProfile._embedded.profile.profileStatus ===
      profileStatusConstants.verified
    );
  }

  public isProfileInReview(): boolean {
    return (
      this.userProfile._embedded.profile.profileStatus ===
      profileStatusConstants.inReview
    );
  }

  public isAccountDeactivated(): boolean {
    const accountStatus = this.userProfile._embedded.profile.accountStatus;
    return (
      accountStatus === accountStatusConstants.deactivated ||
      accountStatus === accountStatusConstants.deleted
    );
  }

  public isAccountDeleted(): boolean {
    return (
      this.userProfile._embedded.profile.accountStatus ===
      accountStatusConstants.deleted
    );
  }

  get inReview() {
    return profileStatusConstants.inReview;
  }

  private handleProfileStatusChange(profileStatus) {
    const url = getUrl(
      this.userProfile,
      UserProfileLinkRel.ProfileStatusChange
    );
    this.store.dispatch(
      UpdateProfileStatus({
        url,
        data: {profileStatus}
      })
    );
  }

  private handleAccountStatusChange(blocked) {
    const url = getUrl(
      this.userProfile,
      UserProfileLinkRel.AccountStatusChange
    );
    this.store.dispatch(
      UpdateProfileStatus({
        url,
        data: {blocked}
      })
    );
  }

  public get canContactTechnician(): boolean {
    return (
      (this.loggedInRoleIsAgency && this.isOwnTechnician) ||
      hasLink(
        this.userProfile,
        RequestOverviewLinkRel.GetConversationTechnician
      )
    );
  }

  public grantCrossRegionAccess() {
    const profile = getEmbeddedResource<any>(
      this.userProfile,
      UserProfileLinkRel.Profile
    );
    if (role(this.userProfileRole).isAdminOrModerator()) {
      const url = this.chooseOtherRegionApiUrl(
        this.grantAccessToRegionToBackoffice.href
      );
      this.grantCrossRegionAccessForAdminOrModerator(url, profile);
    } else {
      const getMroListUrl = this.chooseOtherRegionApiUrl(
        this.getMroFacilitiesLink.href
      );
      const url = this.chooseOtherRegionApiUrl(
        this.grantAccessToRegionToMro.href
      );
      this.grantCrossRegionAccessForMro(
        getMroListUrl,
        url,
        this.userProfile.position,
        profile
      );
    }
  }

  private grantCrossRegionAccessForAdminOrModerator(url: string, profile: any) {
    this.store.dispatch(
      GrantCrossRegionAccess({
        url,
        payload: {
          role: profile.role,
          email: profile.email,
          firstName: profile.firstName,
          lastName: profile.lastName
        }
      })
    );
  }

  private grantCrossRegionAccessForMro(
    getMroListUrl: string,
    grantAccessUrl: string,
    position: string,
    profile: any
  ) {
    this.store.dispatch(GetMroListForApiUrl({apiUrl: getMroListUrl}));
    this.addSub(
      this.actionsSubject
        .pipe(
          ofType(MroListLoaded),
          map(action => action.payload),
          map(mros => mros.map(mro => ({uuid: mro.uuid, name: mro.name}))),
          tap(mrosList => (this.mrosListFromOtherRegion = mrosList)),
          take(1)
        )
        .subscribe(() => {
          this.bsModalService.show(SelectEntityFromListModalComponent, {
            ignoreBackdropClick: false,
            initialState: {
              title: 'Select Aviation Company',
              placeholder: 'Select Aviation Company',
              bindLabel: 'name',
              entityList: this.mrosListFromOtherRegion,
              onConfirmCallback: (facility: any) => {
                this.store.dispatch(
                  GrantCrossRegionAccess({
                    url: grantAccessUrl,
                    payload: {
                      position,
                      facilityUuid: facility.uuid,
                      role: profile.role,
                      email: profile.email,
                      firstName: profile.firstName,
                      lastName: profile.lastName,
                      defaultUser: false
                    }
                  })
                );
              }
            }
          });
        })
    );
  }

  private chooseOtherRegionApiUrl(apiUrl: string): string {
    switch (this.apiRegion) {
      case AppRegionEnum.EU:
        return apiUrl.replace(this.europeApiUrl, this.usaApiUrl);
      case AppRegionEnum.USA:
        return apiUrl.replace(this.usaApiUrl, this.europeApiUrl);
      default:
        return apiUrl;
    }
  }

  public canGrantCrossRegionAccess(): boolean {
    return !this.userProfileCanAccessToDifferentApiRegion() && this.grantAccessLinkIsPresent();
  }

  private userProfileCanAccessToDifferentApiRegion(): boolean {
    return this.userAccessibleRegions?.some(it => it !== this.apiRegion);
  }

  private grantAccessLinkIsPresent(): boolean {
    const profileRoleWrapper = role(this.userProfileRole);
    return (profileRoleWrapper.isAdminOrModerator() && !!this.grantAccessToRegionToBackoffice) ||
      (profileRoleWrapper.isMro() && !!this.grantAccessToRegionToMro)
  }
}
