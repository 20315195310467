import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BsDatepickerViewMode} from "ngx-bootstrap/datepicker/models";
import {AbstractValueAccessor, MakeProvider} from "@libs/shared/helpers/abstract-value-accessor";
import {BsDatepickerDirective} from "ngx-bootstrap/datepicker";
import {dateTimeFromString, dateToUTCString} from "@libs/shared/helpers/date-utils";


@Component({
  selector: 'date-picker-single',
  templateUrl: './date-picker-single.component.html',
  styleUrls: ['./date-picker-single.component.scss'],
  providers: [MakeProvider(DatePickerSingleComponent)]
})
export class DatePickerSingleComponent extends AbstractValueAccessor {
  @Input() set initialValue(date: string) {
    if (!date) {
      this.writeValue(null);
    } else {
      this.writeValue(this.dateStringToJSDate(date));
    }
  }
  @Input() set minDate(date: string) {
    this._minDate = this.dateStringToJSDate(date);
  }
  @Input() set maxDate(date: string) {
    this._maxDate = this.dateStringToJSDate(date);
  }

  private dateStringToJSDate(date: string) {
    return dateTimeFromString(date).toJSDate();
  }

  @Input() public mode: BsDatepickerViewMode = 'day';
  @Input() public placeholder: string = '';
  @Input() public disabled: boolean = false;
  @Output() public dateUpdated: EventEmitter<string> = new EventEmitter<string>();
  public _minDate: Date = null;
  public _maxDate: Date = null;

  public emitChange(date: Date): void {
    if (date) {
      this.writeValue(date);
      this.dateUpdated.emit(dateToUTCString(date));
    }
  }

  public openCalendar(datePicker: BsDatepickerDirective): void {
    if (!this.disabled) {
      datePicker.show();
    }
  }

}
