import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { NgrxFormsModule } from 'ngrx-forms';
import { EffectsModule } from '@ngrx/effects';

import { RegistrationComponent } from './components/registration.component';
import {
  getReducers as getRegistrationReducers,
  reducersInjectionToken
} from './state/registration.reducers';
import {
  RegistrationState,
  REGISTRATION_FEATURE_KEY,
  initialState as registrationInitialState
} from './state/registration-state.model';
import { TechnicianRegistrationFormComponent } from './components/technician-registration-form/technician-registration-form.component';
import { SharedModule } from '../shared/shared.module';
import { RegistrationEffects } from './state/registration.effects';
import { RegistrationService } from './services/registration.service';
import { MroRegistrationComponent } from './components/mro-registration/mro-registration.component';
import { GoBackButtonComponent } from './components/go-back-button/go-back-button.component';
import { CompanyDetailsComponent } from './components/mro-registration/steps/company-details/company-details.component';
import { DisplayOptionsComponent } from './components/mro-registration/steps/display-options/display-options.component';
import { AgencySelectionComponent } from './components/mro-registration/steps/agency-selection/agency-selection.component';
import { StepElementDirective } from './components/mro-registration/step-element.directive';
import { SuccessComponent } from './components/mro-registration/steps/success/success.component';
import { StepperComponent } from './components/mro-registration/stepper/stepper.component';
import { CommonUiModule } from '@libs/common-ui/common-ui.module';
import {WrongAppComponent} from "./components/wrong-app/wrong-app.component";
import {UnauthorizedAppComponent} from "./components/unauthorized-app/unauthorized-app.component";

export function getInitialState(): Partial<RegistrationState> {
  return {
    ...registrationInitialState
  };
}

@NgModule({
  imports: [
    CommonModule,
    NgrxFormsModule,
    SharedModule,
    HttpClientModule,
    EffectsModule.forFeature([RegistrationEffects]),
    StoreModule.forFeature(REGISTRATION_FEATURE_KEY, reducersInjectionToken, {
      initialState: getInitialState
    }),
    CommonUiModule
  ],
  declarations: [
    RegistrationComponent,
    TechnicianRegistrationFormComponent,
    MroRegistrationComponent,
    GoBackButtonComponent,
    CompanyDetailsComponent,
    DisplayOptionsComponent,
    AgencySelectionComponent,
    StepElementDirective,
    SuccessComponent,
    StepperComponent,
    WrongAppComponent,
    UnauthorizedAppComponent
  ],
  providers: [
    { provide: reducersInjectionToken, useFactory: getRegistrationReducers },
    RegistrationService
  ],
  exports: [RegistrationComponent]
})
export class RegistrationModule {}
