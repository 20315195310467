<section
    class="profile-info"
    [ngClass]="{ isAdmin: isAdmin, 'input-disabled': isAccountDeleted() }"
>
    <div class="profile-container">
        <div class="main">
            <ng-container *ngIf="isAdmin || canEdit">
                <div
                    *ngIf="userProfile._embedded.profile.oldProfileStatus"
                    class="old-status"
                >
                    <span class="text-muted text-danger">
                        Old Profile Status:
                    </span>
                    <span class="text-danger">
                        <strong>
                            {{
                                userProfile._embedded.profile.oldProfileStatus
                                    | humanReadableProfileStatus
                            }}
                        </strong>
                    </span>
                </div>
                <div class="status">
                    <staffnow-profile-status
                        *ngIf="isTechnicianProfile()"
                    ></staffnow-profile-status>
                </div>
            </ng-container>

            <div class="avatar">
                <staffnow-avatar-upload></staffnow-avatar-upload>
            </div>
            <div class="user-title">
                <h2 class="h2">
                    {{ userProfile._embedded.profile.firstName || 'Unnamed' }}
                    {{ userProfile._embedded.profile.lastName || 'User' }}
                </h2>
                <p class="email" *ngIf="isAdmin || canEdit">
                    <span>{{ userProfile._embedded.profile.email }}</span>
                </p>
                <p *ngIf="userProfile.refNumber">
                    {{ userProfile.refNumber }}
                </p>
                <p
                    class="title text-muted"
                    *ngFor="let title of userProfile?.ameTitles"
                >
                    {{
                        title.type + ' ' + (title.license ? title.license : '')
                    }}
                </p>
                <p class="title text-muted" *ngIf="userProfile['position']">
                    {{ userProfile['position'] }}
                </p>
            </div>
            <div class="user-info">
                <p
                    *ngIf="
                        userProfile['experience'] ||
                        userProfile['experience'] === 0
                    "
                >
                    <span class="text-muted">Experience: </span>
                    <span>
                        {{ userProfile['experience'] }}
                        {{ userProfile['experience'] == 1 ? 'year' : 'years' }}
                    </span>
                </p>
                <p
                    *ngIf="
                        selectedAircraftsArray.length > 0 ||
                        userProfile?.otherAirplanes?.length > 0
                    "
                >
                    <span class="text-muted">Aircraft: </span>
                    <span
                        >{{ selectedAircraftsArray | selectedAircraft }}
                        <span
                            class="aircrafts"
                            *ngIf="userProfile.otherAirplanes"
                        >
                            {{ userProfile?.otherAirplanes }}
                        </span>
                    </span>
                </p>
                <div *ngIf="userProfile['facilityName']" class="facility-name">
                    {{ userProfile['facilityName'] }}
                </div>
            </div>
            <general-button
                *ngIf="canGrantCrossRegionAccess()"
                (onClick)="grantCrossRegionAccess()"
                backgroundStyle="outline"
                class="grant-access-btn"
                icon="lock_open"
                text="GRANT CROSS-REGION ACCESS"
            ></general-button>
            <general-button
                *ngIf="shouldShowSendMessageButton()"
                (onClick)="goToMailbox()"
                backgroundStyle="outline"
                class="send-msg-btn"
                icon="mail_outline"
                text="SEND MESSAGE"
            ></general-button>
        </div>
        <div
            *ngIf="isTechnicianProfile() && !isAdmin"
            class="current-month-availability"
        >
            <p class="text-muted">Availability:</p>
            <staffnow-calendar
                [yearMonth]="currentMonth"
                [mappedPeriods]="mappedPeriods"
                [infoSection]="true"
            >
            </staffnow-calendar>
        </div>
        <div *ngIf="canContactTechnician" class="contactTechnicianButton">
            <general-button
                (onClick)="initiateMessageWithTechnician()"
                backgroundStyle="outline"
                color="secondary"
                size="small"
                class="contact-button"
                text="Contact technician"
            ></general-button>
        </div>

        <div
            *ngIf="canConfigureAutomaticReply"
            class="configure-automatic-reply-wrapper"
        >
            <staffnow-automatic-reply
                [userProfile]="userProfile"
            ></staffnow-automatic-reply>
        </div>

        <!--
        -- ADMIN SECTION
        -->
        <div
            *ngIf="isAdmin"
            [ngClass]="{
                'account-management': true,
                'technician-profile': isTechnicianProfile()
            }"
        >
            <h3 class="h3">
                <strong>Account Management</strong>
            </h3>
            <div class="dates">
                <span class="text-muted">
                    Created On:
                    {{
                        userProfile._embedded.profile.createdOn
                            | localDateWithTimeFormat
                    }}</span
                >
                <span class="text-muted">
                    Last Login On:
                    {{
                        userProfile._embedded.profile.lastLoginOn
                            | localDateWithTimeFormat
                    }}</span
                >
            </div>
            <div
                class="action-buttons"
                [ngClass]="{
                    'already-verified': isProfileVerified(),
                    'already-in-review': isProfileInReview(),
                    deactivated: isAccountDeactivated()
                }"
            >
                <div *ngIf="isProfileVerified()" class="account-verified">
                    <span>
                        Profile <i class="material-icons"> check_circle </i>
                        {{
                            isTechnicianProfile()
                                ? 'Verified by '
                                : 'Created by '
                        }}
                        {{
                            userProfile._embedded.profile.statusChangedBy
                                ?.firstName || 'Unnamed'
                        }}
                        {{
                            userProfile._embedded.profile.statusChangedBy
                                ?.lastName || 'User'
                        }}
                    </span>
                </div>
                <div *ngIf="isProfileInReview()" class="account-verified">
                    <span>
                        Profile marked IN REVIEW by
                        {{
                            userProfile._embedded.profile.statusChangedBy
                                ?.firstName || 'Unnamed'
                        }}
                        {{
                            userProfile._embedded.profile.statusChangedBy
                                ?.lastName || 'User'
                        }}
                    </span>
                </div>
                <h3 *ngIf="isAccountDeactivated()" [ngClass]="'text-danger'">
                    {{
                        'This account has been DEACTIVATED. ' +
                            (isTechnicianProfile()
                                ? 'Status changes cannot be made.'
                                : '')
                    }}
                </h3>
                <general-button
                    *ngIf="!isProfileVerified() && !isAccountDeactivated()"
                    (onClick)="verifyAccount()"
                    color="primary"
                    size="small"
                    text="VERIFY ACCOUNT"
                ></general-button>
                <general-button
                    *ngIf="
                        canMarkInReview &&
                        !isAccountDeactivated() &&
                        !isProfileInReview()
                    "
                    (onClick)="markAsInReview()"
                    backgroundStyle="outline"
                    color="primary"
                    size="small"
                    text="{{
                        'MARK AS ' + (inReview | humanReadableProfileStatus)
                    }}"
                ></general-button>
            </div>
            <borderless-button
                *ngIf="!isAccountDeactivated()"
                (onClick)="deactivateAccount()"
                class="deactivate-account"
                text="DEACTIVATE"
                color="red"
            ></borderless-button>
            <borderless-button
                *ngIf="isAccountDeactivated()"
                (onClick)="activateAccount()"
                class="activate-account"
                text="ACTIVATE"
                color="green-celadon"
            ></borderless-button>
            <div
                class="custom-control custom-checkbox is-staffie-control"
                *ngIf="userProfile?._embedded?.profile?.isStaffie !== null"
            >
                <input
                    class="custom-control-input"
                    id="isStaffie"
                    type="checkbox"
                    #isStaffie
                    [checked]="isStaffieChecked || isUserStaffie()"
                    [disabled]="linkedToAgency || isAgencyTechnician()"
                    (click)="isStaffieChangedHandler(isStaffie.checked)"
                />
                <label
                    class="custom-control-label font-weight-bold"
                    for="isStaffie"
                    >LINK TO AVIATION COMPANY</label
                >
            </div>
            <typeahead-filter
                *ngIf="isStaffieChecked || isUserStaffie()"
                [initialUuid]="userProfile.mroUuid"
                placeholder="Search Mro by name"
                [entityList]="mrosList"
                (onSelect)="handleStaffieForMro($event)"
            >
            </typeahead-filter>
            <div
                class="custom-control custom-checkbox link-to-agency-control"
                *ngIf="isTechnicianProfile()"
            >
                <input
                    class="custom-control-input"
                    id="linkToAgency"
                    type="checkbox"
                    #linkToAgency
                    (click)="
                        agencyTechnicianHandler($event, linkToAgency.checked)
                    "
                    [checked]="linkToAgency.checked || isAgencyTechnician()"
                    [disabled]="isStaffieChecked || isUserStaffie()"
                />
                <label
                    class="custom-control-label font-weight-bold"
                    for="linkToAgency"
                >
                    LINK TO AGENCY
                </label>
            </div>
            <typeahead-filter
                *ngIf="linkedToAgency || isAgencyTechnician()"
                [initialUuid]="userProfile._embedded.agency?.uuid"
                placeholder="Search Agency by name"
                [entityList]="agenciesList"
                (onSelect)="getAgency($event)"
            >
            </typeahead-filter>
        </div>
    </div>
</section>
