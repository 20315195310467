import { Pipe, PipeTransform } from '@angular/core';
import { profileStatusConstants } from '../constants/statuses.constants';

@Pipe({
  name: 'humanReadableProfileStatus'
})
export class HumanReadableProfileStatusPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return profileStatusConstants.serverLangToHumanLang[value];
  }
}
