<h1 class="h1">Newsletter subscription</h1>
<p>
    <ng-container *ngIf="isSubscribedToNewsletter; else notSubscribedMessage">
        You are currently subscribed to {{ apiBrandName$ | async }} newsletter
        and you will receive the latest news and job openings. If you don't want
        to receive it anymore, click the button below.
    </ng-container>
    <ng-template #notSubscribedMessage>
        You are currently not subscribed to
        {{ apiBrandName$ | async }} newsletter. We encourage you to click the
        button below to subscribe and receive the latest news and job openings.
    </ng-template>
</p>
<general-button
    [text]="isSubscribedToNewsletter ? 'Unsubscribe' : 'Subscribe'"
    [isLoading]="isClicked"
    [isDisabled]="isClicked"
    (onClick)="handleChange()"
></general-button>
