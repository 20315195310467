import { Component, OnInit, Input, OnChanges, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  EntityUuidSet,
  SetActingAs
} from '@libs/request-overview-common/state/requests-overview.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { isEmpty } from 'lodash-es';
import { UserRoles } from '@libs/shared/models/roles.enum';

@Component({
  selector: 'staffnow-request-overview-as-technician',
  templateUrl: './request-overview-as-technician.component.html',
  styleUrls: ['./request-overview-as-technician.component.scss']
})
export class RequestOverviewAsTechnician
  implements OnInit, OnChanges, OnDestroy {
  @Input() public userList = [];
  public USER_ROLES = UserRoles;

  public selectedValue: string;
  private subs: Array<Subscription> = [];

  constructor(
    private store: Store<any>,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.watchQueryParams();
    this.initUserList();
  }

  private watchQueryParams(): void {
    this.subs.push(
      this.activatedRoute.queryParams.subscribe(params => {
        this.updateSelectedValue(params.uuid);
      })
    );
  }

  private initUserList() {
    this.userList = this.userList.map(user => ({
      ...user,
      name: `${user.firstName} ${user.lastName}`
    }));
    if (!this.selectedValue && !isEmpty(this.userList)) {
      this.updateUuidQueryParam({ userUuid: this.userList[0].userUuid }, true);
    }
  }

  private updateSelectedValue(newSelectedUuid) {
    this.selectedValue = newSelectedUuid;
    this.store.dispatch(SetActingAs({ role: UserRoles.ROLE_TECHNICIAN }));
    this.store.dispatch(EntityUuidSet({ uuid: this.selectedValue }));
  }

  ngOnChanges(): void {
    this.initUserList();
  }

  public updateUuidQueryParam(event, replaceUrl: boolean = false): void {
    this.router.navigate([], {
      replaceUrl,
      queryParamsHandling: 'merge',
      queryParams: { uuid: event.userUuid }
    });
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
    this.store.dispatch(SetActingAs({ role: null }));
    this.store.dispatch(EntityUuidSet({ uuid: null }));
  }
}
