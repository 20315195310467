import {Action, createAction, props} from '@ngrx/store';
import {TechnicianRegistrationFormModel} from '../components/technician-registration-form/technician-registration-form.model';
import {HttpError} from '@platform/shared/models/HttpError';
import {MroRegistrationData} from './registration-state.model';
import {SharedAgencyOfferOutDto, SharedFixedPriceOfferOutDto, SharedOfferOutDto} from "@libs/shared/models/offer.model";

export enum RegistrationActionTypes {
  TechnicianRegistrationFormSubmitted = '[Registration] Technician Registration form submit',
  FacilityRegistrationFormSubmitted = '[Registration] Facility Registration form submit',
  SuccessRegUser = '[Registration] Successfully registered user',
  FailedToRegUser = '[Registration] Failed to register user',
  ResetForm = '[Registration] Reset form',
  GetSlideshowPictures = '[Registration] Get slideshow pictures',
  SlideshowPicturesLoaded = '[Registration] Slideshow pictures loaded',
  FailedToGetSlideshowPictures = '[Registration] Failed to get slideshow pictures',
  LoadSharedOffer = '[Registration] LoadSharedOffer',
  SharedOfferLoaded = '[Registration] SharedOfferLoaded',
  RegisterMro = '[Registration] Register MRO company',
  RegisterMroSuccessful = '[Registration] Register MRO company successfully',
  RegisterMroFailed = '[Registration] Register MRO company failed'
}

export class TechnicianRegistrationFormSubmitted implements Action {
  public readonly type =
    RegistrationActionTypes.TechnicianRegistrationFormSubmitted;
  public grecaptchaToken: string;
  public payload: TechnicianRegistrationFormModel = null;

  constructor(
    grecaptchaToken: string,
    payload: TechnicianRegistrationFormModel
  ) {
    this.grecaptchaToken = grecaptchaToken;
    this.payload = payload;
  }
}

export class SuccessRegUser implements Action {
  public readonly type = RegistrationActionTypes.SuccessRegUser;
  public isFacility: boolean;

  constructor(isFacility: boolean = false) {
    this.isFacility = isFacility;
  }
}

export class FailedToRegUser implements Action {
  public readonly type = RegistrationActionTypes.FailedToRegUser;
  public payload = null;

  constructor(payload: HttpError) {
    this.payload = payload;
  }
}

export class GetSlideshowPictures implements Action {
  public readonly type = RegistrationActionTypes.GetSlideshowPictures;
  public payload = null;

  constructor(payload: any) {
    this.payload = payload;
  }
}

export class SlideshowPicturesLoaded implements Action {
  public readonly type = RegistrationActionTypes.SlideshowPicturesLoaded;
  public payload = null;

  constructor(payload: any) {
    this.payload = payload;
  }
}

export class FailedToGetSlideshowPictures implements Action {
  public readonly type = RegistrationActionTypes.FailedToGetSlideshowPictures;
}

export class ResetForm implements Action {
  public readonly type = RegistrationActionTypes.ResetForm;
}

export const LoadSharedOffer = createAction(
  RegistrationActionTypes.LoadSharedOffer,
  props<{ refNumber: string; linkRel: string }>()
);

export const SharedOfferLoaded = createAction(
  RegistrationActionTypes.SharedOfferLoaded,
  props<{ offer: SharedOfferOutDto | SharedAgencyOfferOutDto | SharedFixedPriceOfferOutDto }>()
);

export const UpdateSelectedAgenciesList = createAction(
  '[Mro Registration] Update selected agencies',
  props<{ agencies: string[] }>()
);

export const UpdateCompanyImage = createAction(
  '[Mro Registration] Update company image',
  props<{ images: File }>()
);

export const UpdateTermsAgreement = createAction(
  '[Mro Registration] Update terms and conditions',
  props<{ value: boolean }>()
);

export const UpdateCompanyLogo = createAction(
  '[Mro Registration] Update company logo',
  props<{ value: boolean }>()
);

export const UpdateCompanyPromoMaterials = createAction(
  '[Mro Registration] Update company promo materials',
  props<{ value: boolean }>()
);

export const UpdateAdvertiseOpenPositions = createAction(
  '[Mro Registration] Update advertise open positions',
  props<{ value: boolean }>()
);

export const RegisterMro = createAction(
  '[Mro Registration] Register MRO company',
  props<{ grecaptchaToken: string; payload: MroRegistrationData }>()
);

export const RegisterMroSuccessful = createAction(
  '[Mro Registration] Register MRO company successfully',
  props<{ companyUuid: string; }>()
);

export const RegisterMroFailed = createAction(
  '[Mro Registration] Register MRO company failed'
);

export const UploadMroLogo = createAction(
  '[Mro Registration] Upload MRO logo',
  props<{ facilityUuid: string; file: any; }>()
);

export const MroRegistrationFinished = createAction(
  '[Mro Registration] Registration finished'
);

export const UploadMroLogoFailed = createAction(
  '[Mro Registration] Upload MRO logo failed'
);

export const CompanySearch = createAction(
  '[Mro Registration] Search company',
  props<{ controlId: string; companyName: string; }>()
);

export const ResetMroCompanyDetailsForm = createAction(
  '[MRO Registration Form] Reset'
);

export const ResetSelectedAgenciesList = createAction(
  '[Mro Registration] Reset selected agencies'
);

export const ResetDisplayOptions = createAction(
  '[Mro Registration] Reset display options'
);

export const LoadLocationList = createAction(
  '[Mro Registration] LoadLocationList'
);

export type RegistrationAction =
  | TechnicianRegistrationFormSubmitted
  | SuccessRegUser
  | FailedToRegUser
  | ResetForm
  | GetSlideshowPictures
  | FailedToGetSlideshowPictures
  | SlideshowPicturesLoaded;
