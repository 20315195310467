import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'staffnow-profile-nav',
  templateUrl: './profile-nav.component.html',
  styleUrls: ['./profile-nav.component.scss']
})
export class ProfileNavComponent {
  @Input() public menuItems: Array<string>;
  @Input() public activeItem: string = 'Account';

  @Output() public selectedItem = new EventEmitter();

  constructor() {}

  selectNewItem(item: string): void {
    this.selectedItem.emit(item);
  }
}
