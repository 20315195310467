import {
  createFormGroupState,
  createFormStateReducerWithUpdate,
  FormGroupState,
  setErrors,
  StateUpdateFns,
  updateGroup,
  validate
} from 'ngrx-forms';
import { createAction, props } from '@ngrx/store';
import { maxLength, minLength, pattern, required, requiredTrue } from 'ngrx-forms/validation';

import {
  FormState,
  initialTechnicianFormState,
  TechnicianRegistrationFormModel
} from './technician-registration-form.model';
import { isUsaDeployment } from '@libs/shared/bms-common/environment/environment.loader';

const validatePasswords = (
  state: FormState = initialTechnicianFormState
): FormGroupState<TechnicianRegistrationFormModel> => {
  if (state.controls.confirmPassword.value != state.controls.password.value) {
    return updateGroup<TechnicianRegistrationFormModel>({
      confirmPassword: setErrors({ passwordsDontMatch: true })
    })(state);
  }
  return state;
};

function getCommonValidations(): StateUpdateFns<TechnicianRegistrationFormModel> {
  return {
    //TODO(SN-972): ask for password requirements
    email: validate<string>([required, pattern(/^[\w\-+]+(?:\.[\w\-+]+)*@(?:[\w\-+]+\.)+[a-zA-Z]{2,7}$/)]),
    password: validate(required, minLength(8), maxLength(28), pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/)),
    confirmPassword: validate(required),
    termsAgreement: validate(requiredTrue)
  };
}

const updateMyFormGroup = (
  state: FormState = initialTechnicianFormState
): FormGroupState<TechnicianRegistrationFormModel> => {
  //TODO(SN-980): find a better way to do the conditional validation
  if (isUsaDeployment()) {
    const usaValidations = {
      ...getCommonValidations(),
      canWorkInUsaAgreement: validate(requiredTrue)
    };
    return updateGroup<TechnicianRegistrationFormModel>(usaValidations)(state);
  }
  return updateGroup<TechnicianRegistrationFormModel>(getCommonValidations())(state);
};

const formReducer = createFormStateReducerWithUpdate(updateMyFormGroup);

export function technicianRegistrationFormReducer(
  state: FormState = initialTechnicianFormState,
  action: any
): FormGroupState<TechnicianRegistrationFormModel> {
  let myForm = formReducer(state, action);
  myForm = validatePasswords(myForm);
  switch (action.type) {
    case UpdateEmailVerify.type:
      myForm = createFormGroupState<TechnicianRegistrationFormModel>('registrationForm', {
        ...myForm.value,
        emailVerify: action.emailVerify
      });
      myForm = validatePasswords(myForm);
      return { ...myForm };
    default: {
      return { ...myForm };
    }
  }
}

export const UpdateEmailVerify = createAction(
  '[Technician Registration Form] update emailVerify',
  props<{ emailVerify: boolean }>()
);
