<div class="availability-container">
    <div class="page-description">
        <div *ngIf="canEdit && !isUserDeleted">
            <p>
                This calendar automatically reflects the contract periods for the job openings where you have been
                accepted.
            </p>
        </div>
    </div>
    <div *ngIf="isPlatformWeb, else appCalendar" class="calendar-wrapper">
        <div (click)="loadPast(3)" class="mobile-arrow">
            <i class="material-icons"> keyboard_arrow_up </i>
            <p>{{ 'Load previous three months' | translate }}</p>
        </div>
        <div
            (click)="loadPast(3)"
            [tooltip]="'Load previous three months' | translate"
            class="desktop-arrow"
            placement="right"
            triggers="mouseenter:mouseleave"
        >
            <i class="material-icons"> keyboard_arrow_up </i>
        </div>
        <div class="calendar">
            <ng-container *ngTemplateOutlet="staffnowCalendar">
            </ng-container>
        </div>
        <div
            (click)="loadNext(3)"
            [tooltip]="'Load next three months' | translate"
            class="desktop-arrow"
            placement="right"
            triggers="mouseenter:mouseleave"
        >
            <i class="material-icons"> keyboard_arrow_down </i>
        </div>
        <div (click)="loadNext(3)" class="mobile-arrow">
            <p class="arrow-down">{{ 'Load next three months' | translate }}</p>
            <i class="material-icons"> keyboard_arrow_down </i>
        </div>
    </div>
    <ng-template #appCalendar>
        <div class="app-calendar-wrapper">
            <i (click)="loadPast(1)" class="material-icons app-calendar-element"> keyboard_arrow_left </i>
            <div class="calendar app-calendar-element">
                <ng-container *ngTemplateOutlet="staffnowCalendar">
                </ng-container>
            </div>
            <i (click)="loadNext(1)" class="material-icons app-calendar-element"> keyboard_arrow_right </i>
        </div>
    </ng-template>
</div>
<ng-template #staffnowCalendar>
    <staffnow-calendar
        *ngFor="let month of months"
        [mappedPeriods]="mappedPeriods"
        [ngClass]="{ 'input-disabled': isUserDeleted }"
        [yearMonth]="month"
    ></staffnow-calendar>
</ng-template>
