export enum FacilityProfileLinkRel {
  ApprovedAgencies = 'approvedAgencies',
  MroApprovals = 'mroApprovals',
  Verify = 'verifyFacility',
  MarkAsPending = 'markFacilityAsPending',
  UploadFacilityPicture = 'uploadPicture',
  UpdateFacilityProfile = 'updateFacility',
  UpdateAgencyServices = 'updateAgencyServices',
  UploadAgencyDefaultContract = 'UPLOAD_DEFAULT_CONTRACT',
  UploadAgencyApprovals = 'uploadAgencyApprovals',
  LabelDocument = 'label',
  UpdateFacilityAdminProperties = 'updateFacilityAdminProperties',
  AgencyEmbeddedPictures = 'pictures',
  AgencyEmbeddedDefaultContract = 'default_contract',
  AgencyEmbeddedAgencyApprovals = 'agency_approvals',
  MroRates = 'updateMroRates',
  AmeNomenclature = 'ameNomenclature',
  Self = 'self',
  DeleteFacility = 'deleteFacility'
}
