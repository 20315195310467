<div [class]="['platform-login-wrapper', brandName.toLowerCase()]">
    <div class="log-box-container">
        <div class="log-form-column">
            <div class="logo-container">
                <div [class]="['logo', brandName.toLowerCase()]"></div>
            </div>
            <div class="log-form-content">
                <form [formGroup]="form" *ngIf="!submitted">
                    <div class="form-group">
                        <label for="email" class="control-label font-weight-bold">Email address</label>
                        <input
                            id="email"
                            type="email"
                            class="form-control"
                            formControlName="email"
                            placeholder="Your email address"
                            [ngClass]="{
                                'is-valid': (f.email.dirty || submitted) && !f.email.errors,
                                'is-invalid': (f.email.dirty || submitted) && f.email.errors
                            }"
                        />
                        <div class="invalid-feedback">
                            <span class="text-danger" *ngIf="f.email.errors?.required">Email address is required</span>
                            <span class="text-danger" *ngIf="f.email.errors?.email">Email address is not valid</span>
                        </div>
                    </div>
                    <div class="form-group">
                        <general-button
                            class="btn-block"
                            (onClick)="onSubmit()"
                            [isDisabled]="isWorking"
                            [isLoading]="isWorking"
                            text="Request new password"
                        ></general-button>
                    </div>
                    <div class="form-group">
                        <button class="btn btn-block btn-outline-primary" routerLink="/register">
                            {{ 'GO BACK TO HOME' | translate }}
                        </button>
                    </div>
                </form>
                <div *ngIf="submitted">
                    <div class="form-group">
                        <h2>
                            <b>Request created successfully.</b><br />
                            In case the email is registered in our system, you will receive an email.<br />
                            Please contact our support team in case you have any doubts.
                        </h2>
                    </div>
                    <div class="form-group">
                        <button class="btn btn-block btn-primary" routerLink="/register">
                            {{ 'GO BACK TO HOME' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <router-outlet></router-outlet>
</div>
