<div class="form-control date-picker" [ngClass]="{ disabled }" (click)="openCalendar(datePicker)">
    <span *ngIf="value; else placeholderTemplate">
        {{ value | absoluteDateFormat }}
    </span>
    <ng-template #placeholderTemplate>
        <span>{{ placeholder }}</span>
    </ng-template>
    <span
        #datePicker="bsDatepicker"
        bsDatepicker
        [bsConfig]="{
            showWeekNumbers: false,
            minMode: mode
        }"
        [bsValue]="value"
        (bsValueChange)="emitChange($event)"
        [minDate]="_minDate"
        [maxDate]="_maxDate"
    ></span>
</div>
