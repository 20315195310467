import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Store} from '@ngrx/store';
import {AuthorizedUserProfileModel} from '@libs/shared/models/authorized-user-profile.model';
import {getEmbeddedResource, getUrl, hasEmbeddedResource, hasLink} from '@libs/shared/bms-common/rest/resource.utils';
import {getFilteredApiRoot, getLoggedInUserRole} from '@libs/shared/bms-common/api-root/api-root.selectors';
import {UserProfileLinkRel} from '@libs/shared/linkrels/user-profile.linkrel';
import {UserRoles, UserRolesUtils} from '@libs/shared/models/roles.enum';
import {CustomNavigationService} from '@libs/shared/services/custom-navigation.service';
import {ApiRootLinkRel} from '@libs/shared/linkrels/api-root.linkrel';
import {copyTextToClipboard} from '@libs/shared/helpers/copy-to-clipboard';
import {ToastMessageService} from '@libs/toast-messages/toast-message.service';
import {map} from 'rxjs/operators';
import {BsModalService} from 'ngx-bootstrap/modal';
import {TextAreaDialogComponent} from '../text-area-dialog/text-area-dialog.component';
import {RequestTechnicianDeletion} from 'apps/staffnow-platform/src/app/state/app.actions';
import {getBrandName, getStaticEnvironment} from '@libs/shared/bms-common/environment/environment.selector';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {isPlatformIos} from "@libs/shared/helpers/capacitor";
import {MenuItem} from "@libs/shared/models/menu-item.model";

@UntilDestroy()
@Component({
  selector: 'staffnow-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Input() public menuItems: MenuItem[] = [];
  @Input() public authorizedUser: AuthorizedUserProfileModel = null;
  @Input() public hasMailbox: boolean = false;
  @Input() public messagesCount: number = 0;
  @Input() public notificationsCount: number = 0;
  @Input() public version: string = '';
  @Input() public requestTechnicianDeletionLink: string;

  @Output() public onChangeCookiesSettings = new EventEmitter();
  @Output() public logoutUser = new EventEmitter();
  @Output() public toggleMenu = new EventEmitter();
  @Output() public toggleNotifications = new EventEmitter();

  public isUserMenuOpen: boolean = false;
  public getPrivacyPolicy: string = '';
  public getTermsAndConditions: string = '';
  public getPriceMatrix: string = null;
  public brandName: string = '';
  public technicianRegistrationUrl: string = '';

  public copyRegistrationLinkClicked = false;
  private interval = null;
  public canDisplayCopyInvitationButton = false;
  public getAviationCompanyChargeRatesDocumentLink: string = '';

  constructor(
    private store: Store<any>,
    private customNavigationService: CustomNavigationService,
    private toastService: ToastMessageService,
    private bsModalService: BsModalService
  ) {
    this.store.pipe(getStaticEnvironment).subscribe(env => {
      this.getPrivacyPolicy = env.brandConfig.privacyPolicy;
      this.getTermsAndConditions = env.brandConfig.termsAndConditions;
    });
    this.store.pipe(getBrandName).subscribe(brandName => {
      this.brandName = brandName.toLowerCase();
    });

    this.store
      .pipe(getFilteredApiRoot, untilDestroyed(this))
      .subscribe(apiRoot => {
        if (
          hasEmbeddedResource(
            apiRoot,
            ApiRootLinkRel.TechnicianRegistrationLink
          )
        ) {
          this.technicianRegistrationUrl = getEmbeddedResource(
            apiRoot,
            ApiRootLinkRel.TechnicianRegistrationLink
          );
        }
        if (hasLink(apiRoot, ApiRootLinkRel.PriceMatrix)) {
          this.getPriceMatrix = getUrl(apiRoot, ApiRootLinkRel.PriceMatrix);
        }
        if (hasLink(apiRoot, ApiRootLinkRel.AviationCompanyChargeRatesDocument)) {
          this.getAviationCompanyChargeRatesDocumentLink = getUrl(apiRoot, ApiRootLinkRel.AviationCompanyChargeRatesDocument);
        }
      });
    this.subscribeToIsAgencyOrAdmin();
  }

  get hasPriceMatricUrl(): boolean {
    return !!this.getPriceMatrix;
  }

  get profilePictureUrl(): string {
    if (hasLink(this.authorizedUser, UserProfileLinkRel.GetProfilePicture)) {
      return getUrl(this.authorizedUser, UserProfileLinkRel.GetProfilePicture);
    }
    return null;
  }

  get isBackofficeUser(): boolean {
    return UserRolesUtils.isAdminOrModerator(this.authorizedUser.role);
  }

  get isTechnician(): boolean {
    return UserRolesUtils.isTechnician(this.authorizedUser.role);
  }

  public toggleAsideMenu(): void {
    this.toggleMenu.emit();
  }

  public toggleUserMenu(): void {
    this.isUserMenuOpen = !this.isUserMenuOpen;
  }

  public logout(): void {
    this.isUserMenuOpen = false;
    this.logoutUser.emit();
  }

  public toggleNotificationList() {
    this.toggleNotifications.emit();
  }

  public handleCookiesSettingsChange(): void {
    this.onChangeCookiesSettings.emit();
  }

  public redirectToMainView(): void {
    this.customNavigationService.goToDefaultView();
  }

  public gotoMailbox(): void {
    this.customNavigationService.gotoMailbox();
  }

  private subscribeToIsAgencyOrAdmin() {
    this.store
      .pipe(
        getLoggedInUserRole,
        map((r: UserRoles) => {
          if (r === UserRoles.ROLE_AGENCY || r === UserRoles.ROLE_ADMIN)
            this.canDisplayCopyInvitationButton = true;
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  public copyRegistrationLink(): void {
    copyTextToClipboard(this.technicianRegistrationUrl);
    this.copyRegistrationLinkClicked = true;
    this.toastService.success(
      'You have copied a link with which you can invite technicians to join the platform. ' +
      'Please paste the link in an email or chat where you will send the link to a technician.'
    );
    this.interval = setInterval(() => {
      this.copyRegistrationLinkClicked = false;
      clearInterval(this.interval);
    }, 2000);
  }

  public openRequestDeletionModal() {
    this.bsModalService.show(TextAreaDialogComponent, {
      ignoreBackdropClick: true,
      class: 'job-offer-modal app-modal',
      initialState: {
        title: 'Account deletion request',
        body: 'You are requesting the deletion of your account. We are sorry that you are leaving. Will you share with us the reason for requesting your account to be deleted?',
        disclaimer:
          "Please bear in mind that you are welcome to register again at any time in the future. Should you do that, you'll have to create your profile from scratch.",
        onAcceptCallback: (reason): void => {
          this.store.dispatch(
            RequestTechnicianDeletion({
              requestTechnicianDeletionLink: this.requestTechnicianDeletionLink,
              reason
            })
          );
        }
      }
    });
  }

  get hasAviationCompanyChargeRatesDocumentLink(): boolean {
    return !!this.getAviationCompanyChargeRatesDocumentLink;
  }

  protected readonly isPlatformIos: boolean = isPlatformIos();
}
