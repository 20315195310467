<nav class="profile-nav">
    <ul>
        <li
            *ngFor="let item of menuItems"
            [ngClass]="{ active: activeItem === item }"
            (click)="selectNewItem(item)"
        >
            {{ item }}
        </li>
    </ul>
</nav>
