<div class="{{'registration-wrapper ' + brandName.toLowerCase()}}" [ngClass]="{ 'shared-offer': sharedOffer }">
    <staffnow-mro-registration
        *ngIf="isEntityMro"
        (onReset)="resetForm()"
    ></staffnow-mro-registration>
    <div class="reg-box-container" *ngIf="!isEntityMro">
        <div class="reg-form-column">
            <div class="logo-container">
                <staffnow-registration-go-back
                    *ngIf="loginAttempt || !isEntityNull"
                    [onClick]="resetFormCallback"
                ></staffnow-registration-go-back>
                <div class="{{'logo ' + brandName.toLowerCase()}}"></div>
            </div>
            <div class="reg-form-content">
                <staff-loader
                    *ngIf="registerLoading"
                    [customClass]="'splash-screen-loader'"
                ></staff-loader>
                <ng-container *ngIf="loginAttempt">
                    <staffnow-login-form
                        [privacyPolicyUrl]="privacyPolicyUrl"
                        [termsAndConditionsUrl]="termsAndConditionsUrl"
                    >
                    </staffnow-login-form>
                </ng-container>
                <ng-container *ngIf="registerSuccessful">
                    <success-animation *ngIf="isEntityTechnician">
                        <ng-container message>
                            <div class="important-note text-center">
                                {{ 'REGISTRATION.VERIFY_EMAIL' | translate}}
                            </div>
                            <br />
                            <span [innerHTML]="'REGISTRATION.VERIFY_EMAIL.ACTIVATION_LINK' | translate: {brandName}">
                            </span>
                            <div class="separate-top" [innerHTML]="'REGISTRATION.VERIFY_EMAIL.IMPORTANT_NOTE' | translate">
                            </div>
                            <br />
                            <div class="important-note text-center">
                                <b>{{ 'REGISTRATION.VERIFY_EMAIL.DISREGARD_NOTE' | translate }}</b>
                            </div>
                            <br />
                        </ng-container>
                    </success-animation>
                </ng-container>
                <ng-container *ngIf="registerIdle">
                    <technician-registration-form
                        *ngIf="isEntityTechnician"
                        [brandName]="brandName"
                        [privacyPolicyLink]="privacyPolicyLink"
                        [termsAndConditionsLink]="termsAndConditionsLink"
                        (submit)="submitTechnicianRegistrationForm($event)"
                    >
                    </technician-registration-form>
                    <div *ngIf="isEntityNull" class="form-selector">
                        <div class="registration-label registration-font-size">
                            {{ 'REGISTRATION.REGISTER_AS' | translate }}
                        </div>
                        <general-button
                            (onClick)="openTechnicianRegistrationForm()"
                            backgroundStyle="outline"
                            class="reg-btn"
                            size="large"
                            [text]="'GENERAL.AIRCRAFT_TECHNICIAN' | translate"
                        ></general-button>
                        <general-button
                            (onClick)="openMroRegistrationForm()"
                            *ngIf="isPlatformWeb"
                            backgroundStyle="outline"
                            class="reg-btn"
                            size="large"
                            [text]="'GENERAL.AVIATION_COMPANY' | translate"
                        ></general-button>
                    </div>
                </ng-container>

                <div
                    class="login-container"
                    *ngIf="registerIdle || registerSuccessful"
                >
                    <p
                        *ngIf="registerIdle"
                        class="text-center font-weight-bold registration-font-size"
                    >
                        {{ 'REGISTRATION.ALREADY_HAVE_ACCOUNT' | translate }}
                    </p>

                    <general-button
                        (onClick)="login()"
                        class="mt-3 login-btn"
                        [text]="'GENERAL.LOGIN' | translate"
                    ></general-button>
                </div>

                <ng-container *ngIf="registerError">
                    <fail-animation
                        [message]="errorMessage | translate"
                    ></fail-animation>
                    <div style="text-align: center">
                        <general-button
                            (onClick)="resetForm()"
                            class="reset-btn"
                            [text]="'GENERAL.RESET' | translate"
                        ></general-button>
                        <div>
                            <span>
                                {{
                                    'REGISTRATION.FORGOT_YOUR_PASSWORD' | translate
                                }}
                            </span>
                            <general-button
                                (onClick)="goToResetPassword()"
                                class="reset-btn"
                                [text]="'REGISTRATION.REQUEST_NEW_PASSWORD' | translate"
                                color="secondary"
                            ></general-button>
                        </div>
                    </div>
                </ng-container>
                <div style="text-align: center">
                    <p class="option">
                        {{
                            'REGISTRATION.CONTACT_US'
                                | translate
                        }}
                    </p>
                    <ng-container
                        [ngTemplateOutlet]="contactEmail"
                        [ngTemplateOutletContext]="{ email:customerSupportEmail }">
                    </ng-container>
                </div>
            </div>
        </div>

        <div
            class="image-container-column"
            *ngIf="!showOffer; else offerContainer"
            id="slideshow"
        >
            <img [src]="getSlide()" alt="slide-image"/>
        </div>
        <ng-template #offerContainer>
            <div class="job-offer-container">
                <div class="job-offer" *ngIf="sharedOffer">
                    <staffnow-job-offer-info
                        [offer]="sharedOffer"
                        [isSharedOfferDto]="true"
                        [displayOfferLocation]="true"
                    >
                        <ng-container *ngIf="isStaffnowBrand() && isFixedPriceOffer()" additional-content>
                            <span class="additional-message">
                                {{ 'REGISTRATION.ADDITIONAL_MESSAGE' | translate }}
                                <ng-container
                                    [ngTemplateOutlet]="contactEmail"
                                    [ngTemplateOutletContext]="{ email:infoEmail }">
                                </ng-container>
                            </span>
                        </ng-container>
                    </staffnow-job-offer-info>
                </div>
            </div>
        </ng-template>
    </div>
</div>
<ng-template #contactEmail let-email='email'>
    <a
        class="contact-mail"
        [href]="'mailto:' + email"
    >
        {{ email }}
    </a>
</ng-template>
