import { Pipe, PipeTransform } from '@angular/core';
import { FormControlState } from 'ngrx-forms';
import {
  FormErrorMessages,
  PARAMETER_PLACEHOLDER
} from '../constants/FormValidationErrorMessages';

@Pipe({
  name: 'validationError'
})
export class ErrorMessagePipe implements PipeTransform {
  private ERROR_MESSAGES = FormErrorMessages;

  transform(value: FormControlState<any>, args?: any): string {
    if (value && (value.isTouched || value.isDirty) && value.errors) {
      const errorKey = Object.keys(value.errors)[0];
      const error = this.ERROR_MESSAGES[errorKey];
      if (error) {
        return error.message.replace(
          PARAMETER_PLACEHOLDER,
          value.errors[errorKey][error.paramName]
        );
      }
    }

    return '';
  }
}
