import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { cloneDeep, map, pickBy } from 'lodash-es';

import {
  BackendReportConfig,
  getReportConfig,
  isGroupReport,
  orderReportList,
  PlatformReport
} from '@libs/shared/models/reports.model';
import { getLoggedInUserRole } from '@libs/shared/bms-common/api-root/api-root.selectors';
import { role, UserRoles } from '@libs/shared/models/roles.enum';
import { LoadMroFacilitiesForReports } from '../../../state/app.actions';

@Component({
  selector: 'staffnow-platform-reports-list',
  templateUrl: './reports-platform-list.component.html',
  styleUrls: ['./reports-platform-list.component.scss']
})
export class ReportsPlatformListComponent implements OnDestroy {
  public reportsPlatform: Array<PlatformReport> = [];

  public innerWidth: number;
  public selectedReportKey: string = null;
  public getReportTitle = (report: PlatformReport) => report.type;
  public getReportKey = (report: PlatformReport) => report.key;
  public allReports: { [key: string]: BackendReportConfig } = null;
  private subs: Array<Subscription> = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<any>
  ) {
    this.subs.push(
      this.activatedRoute.data.subscribe(data => {
        this.allReports = cloneDeep(data.links);
        this.reportsPlatform = this.generatePlatformReports();
        this.setSelectedReport(this.reportsPlatform[0].key);
      })
    );
    this.loadMroFacilities();
  }

  private generatePlatformReports(): Array<PlatformReport> {
    const nonGroupReports: { [key: string]: BackendReportConfig } = pickBy(
      this.allReports,
      (_, key) => !isGroupReport(key)
    );
    return orderReportList(
      map(nonGroupReports, (report, key) => ({
        ...getReportConfig(key),
        url: report.href,
        groupUrl: this.getAssociatedGroupReportUrl(key)
      }))
    );
  }

  private getAssociatedGroupReportUrl(key) {
    return this.allReports[getReportConfig(key).groupReportKey]?.href;
  }

  private loadMroFacilities() {
    this.store
      .pipe(
        getLoggedInUserRole,
        take(1),
        filter((r: UserRoles) => role(r).isHumanResourcesOrCentralQuality()),
        tap(() => this.store.dispatch(LoadMroFacilitiesForReports()))
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  get selectedReport(): PlatformReport {
    return this.reportsPlatform.find(r => r.key === this.selectedReportKey);
  }

  public setSelectedReport(reportKey: string): void {
    this.selectedReportKey = reportKey;
  }
}
