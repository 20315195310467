import { createAction, props } from '@ngrx/store';
import {LocationPO} from "@libs/shared/models/location.model";

export const EnvironmentLoaded = createAction(
  '[Environment] Environment loaded',
  props<{ payload: any }>()
);

export const LocationListLoaded = createAction(
    '[Environment] LocationListLoaded',
    props<{
        locations: LocationPO[]
    }>()
);

