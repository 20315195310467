import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { catchError, map, switchMap, throttleTime, withLatestFrom } from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ToastMessageService } from '@libs/toast-messages/toast-message.service';

import { UserProfileService } from '../services/user-profile.service';
import {
  AddTechnicianUserToAgency,
  BlocklistLoaded,
  ChangeApprovedDocumentStatus,
  ChangeDocumentStatus,
  ChangeNewsletterSubscription,
  ChangeUserEmail,
  DocumentPossessionSuccessfullySet,
  ErrorGetDocumentAreas,
  FailedToChangeDocumentStatus,
  FailedToChangeProfileStatus,
  FailedToChangeUserEmail,
  FailedToDeleteFile,
  FailedToDownloadDocument,
  FailedToFetchUserAvailability,
  FailedToGetBlocklist,
  FailedToGetNotificationSubscriptions,
  FailedToLoadUser,
  FailedToSetTechnicianRefNumber,
  FailedToSetTemporaryPassword,
  FailedToSubmitBlocklist,
  FailedToSubmitNotificationSubscriptions,
  FailedToToggleTechnicianWeeklyJoSummarySubscription,
  FetchUserAvailability,
  FileSuccessfullyDeleted,
  FinishAllDocumentsDownload,
  FinishedUpdatingTechnicianSelfIdentification,
  GetBlocklist,
  GetDocumentAreas,
  GetListOfSkills,
  GetNotificationSubscriptions,
  GrantCrossRegionAccess,
  InitiateAllDocumentsDownload,
  InitiateDocumentDownload,
  InitiateUpdateRequest,
  ListOfSkillsLoaded,
  LoadTechnicianPerformanceFeedbacks,
  NotificationSubscriptionsLoaded,
  RemoveDocument,
  RemoveTechnicianUserFromAgency,
  ResetLoading,
  SetDocumentExpirationDate,
  SetDocumentPossession,
  SetTechnicianRefNumber,
  SetTemporaryPassword,
  SubmitBlocklist,
  SubmitNotificationSubscriptions,
  SubmitProfileChanges,
  SuccessfulDocumentDownload,
  SuccessfullySubmitSubscriptionsList,
  SuccessfullyToggledTechnicianWeeklyJoSummarySubscription,
  SuccessGetDocumentAreas,
  TechnicianPerformanceFeedbacksLoaded,
  TechnicianUserRemovedFromAgency,
  TechnicianUserToAgencyAdded,
  ToggleTechnicianToMatchingOffersSubscription,
  ToggleTechnicianWeeklyJoSummarySubscription,
  UpdateAuthorizedUserInfo,
  UpdateBackofficeNotes,
  UpdateMroFavoriteTechnicians,
  UpdateNewsletterSubscription,
  UpdateProfileStatus,
  UpdateTechnicianSelfIdentification,
  UpdateTechnicianSkills,
  UpdateUserProfile,
  UserAvailabilityLoaded,
  UserProfileUpdated
} from './user-profile.actions';
import { selectProfileUrl } from './user-profile.selectors';
import { DocumentsService } from '../services/documents.service';
import {
  getFilteredApiRoot,
  getLoggedInUserRole,
  getOwnProfileUrl
} from '@libs/shared/bms-common/api-root/api-root.selectors';
import { UserProfileType } from '@libs/shared/models/user-profile.type';
import { Resource } from '@libs/shared/bms-common/rest/resource.model';
import { ResourceFactory } from '@libs/shared/bms-common/rest/resource.factory';
import { getEmbeddedResource, getUrl } from '@libs/shared/bms-common/rest/resource.utils';
import { DocumentArea, DocumentsLinkRel } from '../../lib/model/documents.model';
import { downloadFileBlob } from '@libs/shared/helpers/download-blob-file';
import { UserRolesUtils } from '@libs/shared/models/roles.enum';
import { ApiRootLinkRel } from '@libs/shared/linkrels/api-root.linkrel';
import { Skill } from '@libs/shared/models/skills.model';
import { ListDtoWrapper } from '@libs/shared/models/responses/list-wrapper.model';
import { DownloadService } from '@libs/shared/services/download.service';
import {
  SendExperienceConfirmationRequest,
  SendExperienceConfirmationRequestFail,
  SendExperienceConfirmationRequestSuccess
} from '@libs/user-profile/components/technician-request/experience-confirmation-request/experience-confirmation-request.actions';
import {
  SendPerformanceFeedbackRequest,
  SendPerformanceFeedbackRequestFail,
  SendPerformanceFeedbackRequestSuccess
} from '../components/technician-request/performance-feedback-request/performance-feedback-request.actions';
import {
  AutomaticReplyFailedToLoad,
  AutomaticReplyFailedToSave,
  AutomaticReplyLoaded,
  AutomaticReplySuccessfullySaved,
  LoadAutomaticReply,
  SaveAutomaticReply
} from '@libs/user-profile/components/automatic-reply/automatic-reply.actions';
import { DataWrapper } from '@libs/shared/models/responses/data-wrapper.model';
import { AutomaticReply } from '@libs/user-profile/components/automatic-reply/automatic-reply.model';
import { DURATION_1000_MILLISECONDS } from '@libs/shared/constants/duration.constants';
import { ErrorMessageService } from '@libs/common-ui/services/error-message/error-message.service';
import { SubscriptionItem } from '@libs/shared/models/technician-subscription.model';
import { TechnicianPerformanceFeedback } from '@libs/user-profile/components/views/backoffice-performance-feedback-management/technician-performance-feedback.model';
import { BlocklistDto } from '@libs/user-profile/state/user-profile-state.model';
import { TranslateService } from '@ngx-translate/core';
import { AdminOrModeratorProfileUpdated } from '@libs/shared/bms-common/api-root/api-root.actions';

interface StaffnowSuccessResponse {
  message?: string;
  textPayload?: string;
  booleanPayload?: boolean;
}

@Injectable()
export class UserProfileEffects {
  public submitProfileChangesToBackend$ = createEffect(() =>
    this.actions.pipe(
      ofType(InitiateUpdateRequest),
      withLatestFrom(this.store.pipe(selectProfileUrl), this.store.pipe(getLoggedInUserRole)),
      switchMap(([action, profileUrl, userRole]) => {
        let url = profileUrl;
        if (!UserRolesUtils.isAdminOrModerator(userRole)) {
          url += '/self-update';
        }
        return this.httpService.put(url, action.payload).pipe(
          map(() => {
            this.toastMessageService.success(this.translateService.instant('SYSTEM.INFO.UPDATED_USER_PROFILE'));
            this.store.dispatch(AdminOrModeratorProfileUpdated());
            return UpdateUserProfile();
          }),
          catchError((response: HttpErrorResponse) => {
            this.toastMessageService.fail(this.translateService.instant('SYSTEM.ERROR.UPDATE_USER_PROFILE'));
            this.errorMessageService.handleErrorResponse(response);
            return of(FailedToLoadUser());
          })
        );
      })
    )
  );

  public updateUserProfile$ = createEffect(() =>
    this.actions.pipe(
      ofType(UpdateUserProfile),
      withLatestFrom(this.store.pipe(selectProfileUrl), this.store.pipe(getOwnProfileUrl)),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      switchMap(([_, profileUrl, ownProfileUrl]) => {
        return this.resourceFactory
          .fromId(profileUrl)
          .get<UserProfileType>()
          .pipe(
            switchMap(response => {
              const actions: Action[] = [UserProfileUpdated({ payload: response })];
              if (profileUrl === ownProfileUrl) {
                actions.push(UpdateAuthorizedUserInfo({ payload: response }));
              }
              return actions;
            }),
            catchError((response: HttpErrorResponse) => {
              this.errorMessageService.handleErrorResponseWithCustomMessage(
                response,
                this.translateService.instant('SYSTEM.ERROR.GET_USER_INFO')
              );
              return of(FailedToLoadUser());
            })
          );
      })
    )
  );

  public updateBackofficeNotes$ = createEffect(() =>
    this.actions.pipe(
      ofType(UpdateBackofficeNotes),
      switchMap(action => {
        return this.httpService.put(action.url, { text: action.notes }).pipe(
          map(() => {
            this.toastMessageService.success(this.translateService.instant('SYSTEM.INFO.UPDATE_BACK_OFFICE_NOTES'));
            return UpdateUserProfile();
          }),
          catchError((response: HttpErrorResponse) => {
            this.errorMessageService.handleErrorResponse(response);
            return of(ResetLoading());
          })
        );
      })
    )
  );

  public updateTechnicianSkills$ = createEffect(() =>
    this.actions.pipe(
      ofType(UpdateTechnicianSkills),
      switchMap(({ skillsIds, otherSkills, url }) => {
        return this.httpService.patch(url, { skillsIds, otherSkills }).pipe(
          map(() => {
            this.toastMessageService.success('Successfully updated technician skills!');
            return UpdateUserProfile();
          }),
          catchError((response: HttpErrorResponse) => {
            this.errorMessageService.handleErrorResponse(response);
            return of(ResetLoading());
          })
        );
      })
    )
  );

  public updateTechnicianSelfIdentification$ = createEffect(() =>
    this.actions.pipe(
      ofType(UpdateTechnicianSelfIdentification),
      switchMap(action => {
        return this.httpService.put(action.url, action.technicianSelfIdentification).pipe(
          switchMap(() => {
            this.toastMessageService.success('Successfully updated technician Self ID!');
            return [FinishedUpdatingTechnicianSelfIdentification(), UpdateUserProfile()];
          }),
          catchError((response: HttpErrorResponse) => {
            this.errorMessageService.handleErrorResponse(response);
            return of(FinishedUpdatingTechnicianSelfIdentification());
          })
        );
      })
    )
  );

  public getBlocklist$ = createEffect(() =>
    this.actions.pipe(
      ofType(GetBlocklist),
      switchMap(action => {
        return this.httpService.get(action.url).pipe(
          switchMap((response: BlocklistDto) => {
            return [BlocklistLoaded({ payload: response })];
          }),
          catchError((response: HttpErrorResponse) => {
            this.errorMessageService.handleErrorResponse(response);
            return of(FailedToGetBlocklist());
          })
        );
      })
    )
  );

  public submitBlocklist$ = createEffect(() =>
    this.actions.pipe(
      ofType(SubmitBlocklist),
      throttleTime(DURATION_1000_MILLISECONDS),
      switchMap(action => {
        return this.httpService.post(action.targetUrl, { facilities: action.facilities }).pipe(
          switchMap((response: StaffnowSuccessResponse) => {
            this.toastMessageService.success(response.message);
            return [GetBlocklist({ url: action.targetUrl })];
          }),
          catchError((response: HttpErrorResponse) => {
            this.errorMessageService.handleErrorResponse(response);
            return of(FailedToSubmitBlocklist());
          })
        );
      })
    )
  );

  public profileFormSubmitted$ = createEffect(() =>
    this.actions.pipe(
      ofType(SubmitProfileChanges),
      map(action =>
        InitiateUpdateRequest({
          payload: this.userService.prepareBackendRequestPayload(action.formValue)
        })
      )
    )
  );

  public removeDocument$ = createEffect(() =>
    this.actions.pipe(
      ofType(RemoveDocument),
      switchMap(action => {
        return this.resourceFactory
          .fromId(action.payload.file.fileUrl)
          .delete()
          .pipe(
            switchMap(() => {
              this.toastMessageService.success(this.translateService.instant('SYSTEM.INFO.DELETED_DOCUMENT'));
              return [
                UpdateUserProfile(),
                FileSuccessfullyDeleted({
                  context: action.payload.file.context,
                  documentAreaId: action.documentAreaId,
                  documentContextsEmptyAfterRemoval: action.documentContextsEmptyAfterRemoval
                })
              ];
            }),
            catchError((response: HttpErrorResponse) => {
              this.errorMessageService.handleErrorResponse(response);
              return of(FailedToDeleteFile());
            })
          );
      })
    )
  );

  public changeDocumentStatus$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChangeDocumentStatus),
      switchMap(action => {
        return this.httpService.patch(action.payload.userUrl, action.payload.changes).pipe(
          map(() => {
            this.toastMessageService.success(this.translateService.instant('SYSTEM.INFO.CHANGED_DOCUMENT_STATUS'));
            return UpdateUserProfile();
          }),
          catchError((response: HttpErrorResponse) => {
            this.errorMessageService.handleErrorResponse(response);
            return of(FailedToChangeDocumentStatus());
          })
        );
      })
    )
  );

  public changeApprovedDocumentStatus$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChangeApprovedDocumentStatus),
      switchMap(action => {
        return this.httpService.patch(action.fileUrl, null).pipe(
          map(() => {
            this.toastMessageService.success(this.translateService.instant('SYSTEM.INFO.CHANGED_DOCUMENT_STATUS'));
            return UpdateUserProfile();
          }),
          catchError((response: HttpErrorResponse) => {
            this.errorMessageService.handleErrorResponseWithCustomMessage(
              response,
              this.translateService.instant('SYSTEM.ERROR.CHANGED_DOCUMENT_STATUS')
            );
            return of(FailedToChangeDocumentStatus());
          })
        );
      })
    )
  );

  public updateProfileStatus$ = createEffect(() =>
    this.actions.pipe(
      ofType(UpdateProfileStatus),
      switchMap(action => {
        return this.httpService.patch(action.url, action.data).pipe(
          map(() => {
            this.toastMessageService.success(this.translateService.instant('SYSTEM.INFO.CHANGE_PROFILE_STATUS'));
            return UpdateUserProfile();
          }),
          catchError((response: HttpErrorResponse) => {
            this.errorMessageService.handleErrorResponse(response);
            return of(FailedToChangeProfileStatus());
          })
        );
      })
    )
  );

  public addTechnicianToAgency$ = createEffect(() =>
    this.actions.pipe(
      ofType(AddTechnicianUserToAgency),
      switchMap(action => {
        return this.httpService
          .patch(action.payload.addToAgencyUrl, {
            agencyUuid: action.payload.agencyUuid
          })
          .pipe(
            switchMap(() => {
              this.toastMessageService.success(
                this.translateService.instant('SYSTEM.INFO.ASSIGNED_TECHNICIAN_TO_AGENCY')
              );
              return [TechnicianUserToAgencyAdded(), UpdateUserProfile()];
            }),
            catchError((response: HttpErrorResponse) => {
              this.errorMessageService.handleErrorResponse(response);
              return of(FailedToChangeProfileStatus());
            })
          );
      })
    )
  );

  public removeTechnicianFromAgency$ = createEffect(() =>
    this.actions.pipe(
      ofType(RemoveTechnicianUserFromAgency),
      switchMap(action => {
        return this.httpService.delete(action.payload.removeFromAgencyUrl + action.payload.agencyUuid).pipe(
          switchMap(() => {
            this.toastMessageService.success(
              this.translateService.instant('SYSTEM.INFO.UNASSIGNED_TECHNICIAN_TO_AGENCY')
            );
            return [TechnicianUserRemovedFromAgency(), UpdateUserProfile()];
          }),
          catchError((response: HttpErrorResponse) => {
            this.errorMessageService.handleErrorResponse(response);
            return of(FailedToChangeProfileStatus());
          })
        );
      })
    )
  );

  public fetchUserAvailability$ = createEffect(() =>
    this.actions.pipe(
      ofType(FetchUserAvailability),
      switchMap(action => {
        return this.httpService.get(action.availabilityUrl).pipe(
          map(availabilityData => UserAvailabilityLoaded({ payload: availabilityData })),
          catchError((response: HttpErrorResponse) => {
            this.errorMessageService.handleErrorResponseWithoutWarningTheUser(response);
            return of(FailedToFetchUserAvailability());
          })
        );
      })
    )
  );

  public getNotificationSubscriptions$ = createEffect(() =>
    this.actions.pipe(
      ofType(GetNotificationSubscriptions),
      switchMap(action => {
        return this.httpService.get(action.subscriptionsUrl).pipe(
          switchMap((response: { subscriptions: SubscriptionItem[] }) => {
            return [
              NotificationSubscriptionsLoaded({
                subscriptions: response.subscriptions
              })
            ];
          }),
          catchError((response: HttpErrorResponse) => {
            this.errorMessageService.handleErrorResponse(response);
            return of(FailedToGetNotificationSubscriptions());
          })
        );
      })
    )
  );

  public submitNotificationSubscriptions$ = createEffect(() =>
    this.actions.pipe(
      ofType(SubmitNotificationSubscriptions),
      switchMap(action => {
        return this.httpService.put(action.subscriptionsUrl, action.subscriptions).pipe(
          map(() => {
            this.toastMessageService.success(this.translateService.instant('SYSTEM.INFO.EDITED_SETTINGS'));
            return SuccessfullySubmitSubscriptionsList();
          }),
          catchError((response: HttpErrorResponse) => {
            this.errorMessageService.handleErrorResponse(response);
            return of(FailedToSubmitNotificationSubscriptions());
          })
        );
      })
    )
  );

  public toggleTechnicianToMatchingOffersSubscription$ = createEffect(() =>
    this.actions.pipe(
      ofType(ToggleTechnicianToMatchingOffersSubscription),
      switchMap(action => {
        return this.httpService.patch(action.url, {}).pipe(
          switchMap(() => {
            this.toastMessageService.success(
              action.isSubscribing
                ? this.translateService.instant('SYSTEM.INFO.SUCCESSFULLY_SUBSCRIBED_TO_ALL_MATCHING_OFFERS')
                : this.translateService.instant('SYSTEM.INFO.SUCCESSFULLY_UNSUBSCRIBED_FROM_ALL_MATCHING_OFFERS')
            );
            return [UpdateUserProfile(), GetNotificationSubscriptions({ subscriptionsUrl: action.subscriptionsUrl })];
          }),
          catchError((response: HttpErrorResponse) => {
            this.errorMessageService.handleErrorResponseWithCustomMessage(
              response,
              'Failed to subscribe to all matching offers'
            );
            return of(UpdateUserProfile());
          })
        );
      })
    )
  );

  public toggleTechnicianWeeklyJoSummarySubscription$ = createEffect(() =>
    this.actions.pipe(
      ofType(ToggleTechnicianWeeklyJoSummarySubscription),
      switchMap(action => {
        return this.httpService.patch(action.url, {}).pipe(
          switchMap(() => {
            this.toastMessageService.success(
              action.isSubscribing
                ? this.translateService.instant('SYSTEM.INFO.SUCCESSFULLY_SUBSCRIBED_TO_WEEKLY_JO_SUMMARY')
                : this.translateService.instant('SYSTEM.INFO.SUCCESSFULLY_UNSUBSCRIBED_FROM_WEEKLY_JO_SUMMARY')
            );
            return [UpdateUserProfile(), SuccessfullyToggledTechnicianWeeklyJoSummarySubscription()];
          }),
          catchError((response: HttpErrorResponse) => {
            this.errorMessageService.handleErrorResponseWithCustomMessage(
              response,
              'Failed to subscribe/unsubscribe to all matching offers'
            );
            return of(FailedToToggleTechnicianWeeklyJoSummarySubscription());
          })
        );
      })
    )
  );

  public InitiateDocumentDownload$ = createEffect(() =>
    this.actions.pipe(
      ofType(InitiateDocumentDownload),
      switchMap(action => {
        return this.resourceFactory
          .fromId(action.documentDownloadUrl)
          .get()
          .pipe(
            switchMap((resource: Resource) => {
              const downloadUrl = getUrl(resource, 'download');
              return this.downloadService.doGetRequest(downloadUrl).pipe(
                map(response => {
                  downloadFileBlob(response);
                  return SuccessfulDocumentDownload();
                }),
                catchError((response: HttpErrorResponse) => {
                  this.errorMessageService.handleErrorResponse(response);
                  return of(FailedToDownloadDocument());
                })
              );
            }),
            catchError((response: HttpErrorResponse) => {
              this.errorMessageService.handleErrorResponse(response);
              return of(FailedToDownloadDocument());
            })
          );
      })
    )
  );

  public downloadAllDocuments$ = createEffect(() =>
    this.actions.pipe(
      ofType(InitiateAllDocumentsDownload),
      switchMap(action => {
        const downloadUrl = action.documentDownloadUrl;
        return this.downloadService.doGetRequest(downloadUrl).pipe(
          map(response => {
            downloadFileBlob(response);
            return FinishAllDocumentsDownload();
          }),
          catchError((response: HttpErrorResponse) => {
            this.errorMessageService.handleErrorResponseWithCustomMessage(
              response,
              'Unexpected error creating Zip file'
            );
            return of(FinishAllDocumentsDownload());
          })
        );
      })
    )
  );

  public getDocumentsConfig$ = createEffect(() =>
    this.actions.pipe(
      ofType(GetDocumentAreas),
      switchMap(action => {
        return this.documentsService.getDocumentAreas(action.technicianUuid).pipe(
          map(documentAreas =>
            SuccessGetDocumentAreas({
              documentAreas: getEmbeddedResource<DocumentArea[]>(documentAreas, 'documentAreaOutDtoList')
            })
          ),
          catchError((response: HttpErrorResponse) => {
            this.errorMessageService.handleErrorResponse(response);
            return of(ErrorGetDocumentAreas());
          })
        );
      })
    )
  );

  public updateMroFavoriteTechnicians$ = createEffect(() =>
    this.actions.pipe(
      ofType(UpdateMroFavoriteTechnicians),
      throttleTime(DURATION_1000_MILLISECONDS),
      switchMap(action => {
        return this.httpService.post(action.url, { technicianUuid: action.technicianUuid }).pipe(
          map((response: StaffnowSuccessResponse) => {
            this.toastMessageService.success(response.message);
            return UpdateUserProfile();
          }),
          catchError((response: HttpErrorResponse) => {
            this.errorMessageService.handleErrorResponse(response, 'Failed to update favorites');
            return of(UpdateUserProfile());
          })
        );
      })
    )
  );

  public setDocumentExpirationDate$ = createEffect(() =>
    this.actions.pipe(
      ofType(SetDocumentExpirationDate),
      switchMap(action => {
        const url = getUrl(action.document, DocumentsLinkRel.SET_DOCUMENT_EXPIRATION_DATE);
        return this.httpService.patch(url, { expirationDate: action.selectedDate }).pipe(
          map((response: StaffnowSuccessResponse) => {
            this.toastMessageService.success(response.message);
            return UpdateUserProfile();
          }),
          catchError((response: HttpErrorResponse) => {
            this.errorMessageService.handleErrorResponse(response, 'Failed to set expiration date');
            return of(UpdateUserProfile());
          })
        );
      })
    )
  );

  public setDocumentPossession$ = createEffect(() =>
    this.actions.pipe(
      ofType(SetDocumentPossession),
      switchMap(action => {
        const url = getUrl(action.document, DocumentsLinkRel.SET_DOCUMENT_HAS_POSSESSION);
        return this.httpService
          .patch(url.replace('{hasPossession}', (!action.document.hasPossession).toString()), {})
          .pipe(
            switchMap(() => {
              this.toastMessageService.success(
                this.translateService.instant('SYSTEM.INFO.DOCUMENT_POSSESSION_SUCCESSFULLY_SET')
              );
              return [
                UpdateUserProfile(),
                DocumentPossessionSuccessfullySet({ documentAreaId: action.documentAreaId, document: action.document })
              ];
            }),
            catchError((response: HttpErrorResponse) => {
              this.errorMessageService.handleErrorResponse(response, 'Failed to set expiration date');
              return of(UpdateUserProfile());
            })
          );
      })
    )
  );

  public changeNewsletterSubscription$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChangeNewsletterSubscription),
      switchMap(action => {
        return this.httpService.put(action.url, null).pipe(
          map((response: StaffnowSuccessResponse) => {
            this.toastMessageService.success(response.message);
            return UpdateNewsletterSubscription({
              isSubscribed: response.booleanPayload
            });
          }),
          catchError((response: HttpErrorResponse) => {
            this.errorMessageService.handleErrorResponse(response, 'Failed to change newsletter subscription');
            return EMPTY;
          })
        );
      })
    )
  );

  public changeUserEmail$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChangeUserEmail),
      switchMap(action => {
        return this.httpService.patch(action.url, { text: action.email }).pipe(
          map(() => {
            this.toastMessageService.success('User email changed!');
            return UpdateUserProfile();
          }),
          catchError((response: HttpErrorResponse) => {
            this.errorMessageService.handleErrorResponse(response, 'Failed to change user email');
            return of(FailedToChangeUserEmail());
          })
        );
      })
    )
  );

  public setTemporaryPassword$ = createEffect(() =>
    this.actions.pipe(
      ofType(SetTemporaryPassword),
      switchMap(action => {
        return this.httpService.patch(action.url, { text: action.password }).pipe(
          map(() => {
            this.toastMessageService.success('Password changed!');
            return UpdateUserProfile();
          }),
          catchError((response: HttpErrorResponse) => {
            this.errorMessageService.handleErrorResponse(response, 'Failed to change password');
            return of(FailedToSetTemporaryPassword());
          })
        );
      })
    )
  );

  public setTechnicianRefNumber$ = createEffect(() =>
    this.actions.pipe(
      ofType(SetTechnicianRefNumber),
      switchMap(action => {
        return this.httpService.patch(action.url, { text: action.refNumber }).pipe(
          map(() => {
            this.toastMessageService.success('Ref. Number changed!');
            return UpdateUserProfile();
          }),
          catchError((response: HttpErrorResponse) => {
            this.errorMessageService.handleErrorResponse(response, 'Failed to change Ref. Number');
            return of(FailedToSetTechnicianRefNumber());
          })
        );
      })
    )
  );

  public grantCrossRegionAccess$ = createEffect(() =>
    this.actions.pipe(
      ofType(GrantCrossRegionAccess),
      throttleTime(DURATION_1000_MILLISECONDS),
      switchMap(action => {
        return this.httpService.post(action.url, action.payload).pipe(
          switchMap(() => {
            this.toastMessageService.success(this.translateService.instant('SYSTEM.INFO.GRANTED_CROSS_REGION_ACCESS'));
            const actions = [];
            actions.push(UpdateUserProfile());
            return actions;
          }),
          catchError((response: HttpErrorResponse) => {
            this.errorMessageService.handleErrorResponse(response);
            return of();
          })
        );
      })
    )
  );

  public getListOfSkills$ = createEffect(() =>
    this.actions.pipe(
      ofType(GetListOfSkills),
      withLatestFrom(this.store.pipe(getFilteredApiRoot)),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      switchMap(([ignore, apiRoot]) => {
        return this.httpService.get(getUrl(apiRoot, ApiRootLinkRel.GetListOfSkills)).pipe(
          map((response: ListDtoWrapper<Skill>) => {
            return ListOfSkillsLoaded({ listOfSkills: response.list });
          }),
          catchError((response: HttpErrorResponse) => {
            this.errorMessageService.handleErrorResponse(response, 'Failed to load list of skills');
            return EMPTY;
          })
        );
      })
    )
  );

  public sendExperienceConfirmationRequest$ = createEffect(() =>
    this.actions.pipe(
      ofType(SendExperienceConfirmationRequest),
      throttleTime(DURATION_1000_MILLISECONDS),
      switchMap(action => {
        return this.httpService.post(action.url, { ...action.payload }).pipe(
          map(() => {
            this.toastMessageService.success('Experience confirmation request sent!');
            return SendExperienceConfirmationRequestSuccess();
          }),
          catchError((response: HttpErrorResponse) => {
            this.errorMessageService.handleErrorResponseWithCustomMessage(
              response,
              'Could not send experience confirmation request'
            );
            return of(SendExperienceConfirmationRequestFail());
          })
        );
      })
    )
  );

  public sendPerformanceFeedbackRequest$ = createEffect(() =>
    this.actions.pipe(
      ofType(SendPerformanceFeedbackRequest),
      throttleTime(DURATION_1000_MILLISECONDS),
      switchMap(action => {
        return this.httpService.post(action.url, { ...action.payload }).pipe(
          map(() => {
            this.toastMessageService.success('Performance feedback request sent!');
            return SendPerformanceFeedbackRequestSuccess();
          }),
          catchError((response: HttpErrorResponse) => {
            this.errorMessageService.handleErrorResponseWithCustomMessage(
              response,
              'Could not send performance feedback request'
            );
            return of(SendPerformanceFeedbackRequestFail());
          })
        );
      })
    )
  );

  public loadTechnicianPerformanceFeedbacks$ = createEffect(() =>
    this.actions.pipe(
      ofType(LoadTechnicianPerformanceFeedbacks),
      switchMap(action => {
        return this.httpService.get(action.url).pipe(
          map((feedbacks: TechnicianPerformanceFeedback[]) => {
            return TechnicianPerformanceFeedbacksLoaded({ feedbacks });
          }),
          catchError((response: HttpErrorResponse) => {
            this.errorMessageService.handleErrorResponseWithCustomMessage(
              response,
              "Failed to load technician's feedbacks"
            );
            return EMPTY;
          })
        );
      })
    )
  );

  public SaveAutomaticReply$ = createEffect(() =>
    this.actions.pipe(
      ofType(SaveAutomaticReply),
      throttleTime(DURATION_1000_MILLISECONDS),
      switchMap(action => {
        return this.httpService.post(action.url, { ...action.payload }).pipe(
          map(() => {
            this.toastMessageService.success('Automatic reply saved!');
            return AutomaticReplySuccessfullySaved();
          }),
          catchError((response: HttpErrorResponse) => {
            this.errorMessageService.handleErrorResponse(response);
            return of(AutomaticReplyFailedToSave());
          })
        );
      })
    )
  );

  public LoadAutomaticReply$ = createEffect(() =>
    this.actions.pipe(
      ofType(LoadAutomaticReply),
      switchMap(action => {
        return this.httpService.get(action.url).pipe(
          map((response: DataWrapper<AutomaticReply>) => {
            return AutomaticReplyLoaded({ automaticReply: response.data });
          }),
          catchError((response: HttpErrorResponse) => {
            this.errorMessageService.handleErrorResponse(response);
            return of(AutomaticReplyFailedToLoad());
          })
        );
      })
    )
  );

  constructor(
    private actions: Actions,
    private userService: UserProfileService,
    private resourceFactory: ResourceFactory,
    private toastMessageService: ToastMessageService,
    private store: Store,
    private httpService: HttpClient,
    private documentsService: DocumentsService,
    private downloadService: DownloadService,
    private errorMessageService: ErrorMessageService,
    private translateService: TranslateService
  ) {}
}
