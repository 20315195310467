import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  OnInit
} from '@angular/core';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { isEqual } from 'lodash-es';

@Component({
  selector: 'typeahead-filter',
  templateUrl: './typeahead-filter.component.html'
})
export class TypeaheadFilterComponent implements OnInit, OnChanges {
  @Input() public entityList: Array<{ name: string; uuid: string }> = [];
  @Input() public placeholder: string = null;
  @Input() public maxOptionsList: number = 20;
  @Input() public minSearchLength: number = 0;
  @Input() public initialUuid: string = null;
  @Output() public onSelect = new EventEmitter();
  @Output() public onBlur = new EventEmitter();
  public currentValue: string = null;

  constructor() {}

  public handleSelectedEntityChange(event: TypeaheadMatch): void {
    this.onSelect.emit(event.item.uuid);
  }

  ngOnInit(): void {
    this.setCurrentValue();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.entityList) {
      if (
        !isEqual(
          changes.entityList.currentValue,
          changes.entityList.previousValue
        )
      ) {
        this.currentValue = null;
      }
    }
    this.setCurrentValue();
  }

  public onBlurHandler(): void {
    this.onBlur.emit(this.currentValue);
  }

  public setCurrentValue(): void {
    if (this.initialUuid && this.entityList.length > 0) {
      this.currentValue = this.entityList.find(
        entry => entry.uuid === this.initialUuid
      )?.name;
    }
  }
}
