import { createAction, props } from '@ngrx/store'
import { UserRoles } from '@libs/shared/models/roles.enum'
import { EntityTypes } from '@libs/shared/models/entity-types.enum'
import { Contract } from '@libs/shared/models/contract.model'
import { PackageOfferDto } from '@libs/shared/models/offer.model'
import { PackageContract } from '@libs/shared/models/package-contract.model'

export const LoadList = createAction(
  '[Requests Overview] Load list',
  props<{ role: UserRoles }>()
)

export const LoadListBO = createAction(
  '[Requests Overview] Load requests overview list from BO'
)
export const ListLoaded = createAction(
  '[Requests Overview] List loaded',
  props<{ response: any }>()
)
export const FailedToLoadList = createAction(
  '[Requests Overview] Failed to load list'
)
export const LoadOfferContracts = createAction(
  '[Requests Overview] Load selected offer contracts',
  props<{ getContractsUrl: string, userUuid?: string }>()
)
export const SelectedOfferContractsLoaded = createAction(
  '[Requests Overview] Selected offer contracts loaded',
  props<{ response: any }>()
)
export const FailedToLoadOfferContracts = createAction(
  '[Requests Overview] Failed to load selected offer contracts'
)
export const ProposeStartDate = createAction(
  '[Requests Overview] Start date changed for contract ',
  props<{ startDate: string, contract: any }>()
)
export const AcceptStartDate = createAction(
  '[Requests Overview] Accept proposed start date for contract ',
  props<{ contract }>()
)
createAction(
  '[Requests Overview] Make an offer private',
  props<{ offer: any }>()
)
createAction(
  '[Requests Overview] Make an offer public',
  props<{ offer: any }>()
)
export const CloseOffer = createAction(
  '[Requests Overview] Close offer',
  props<{ offer: any }>()
)
export const ReopenOffer = createAction(
  '[Requests Overview] Reopen offer',
  props<{ offer: any }>()
)
export const ApproveOffer = createAction(
  '[Requests Overview] Approve an offer',
  props<{ offer: any }>()
)
export const LoadPublicProfile = createAction(
  '[Requests Overview] Load public profile',
  props<{ contract: any, entityType: EntityTypes }>()
)
export const PublicProfileLoaded = createAction(
  '[Requests Overview] Loaded public profile successfully',
  props<{ profile: any, contract: Contract, entityType: EntityTypes }>()
)
export const DeleteOffer = createAction(
  '[Requests Overview] Delete offer',
  props<{ url: string }>()
)
export const SuccessfulDeletedOffer = createAction(
  '[Requests Overview] Successful deleted offer'
)
export const FailedToDeleteOffer = createAction(
  '[Requests Overview] Failed to delete offer'
)
export const LoadContract = createAction(
  '[Requests Overview] Load contract',
  props<{ contract: any }>()
)
export const ContractLoaded = createAction(
  '[Requests Overview] Contract loaded',
  props<{ contract: any }>()
)
export const OfferLoaded = createAction(
  '[Requests Overview] Offer loaded',
  props<{ offer: any }>()
)
export const LoadOffer = createAction(
  '[Requests Overview] Load offer',
  props<{ offer: any }>()
)
export const ResetRequestsOverviewState = createAction(
  '[Requests Overview] Reset requests overview state'
)
export const AddStaffiesToOffer = createAction(
  '[Request Overview] Adding staffies to an offer',
  props<{ selectedTechnicians: any[], offer: any }>()
)
export const LoadStaffies = createAction(
  '[Request Overview] Load staffies',
  props<{ offerUuid: string }>()
)
export const StaffiesLoaded = createAction(
  '[Request Overview] Staffies loaded',
  props<{ responseList: any }>()
)
export const InviteAgenciesToPackageOffer = createAction(
  '[Request Overview] Invite agencies to package offer',
  props<{ selectedAgencies: any[], offer: PackageOfferDto }>()
)
export const LoadAgenciesToInvite = createAction(
  '[Request Overview] Load agencies to invite to package offer',
  props<{ offer: PackageOfferDto }>()
)
export const AgenciesLoaded = createAction(
  '[Request Overview] Agencies to invite to package offer loaded',
  props<{ responseList: any[] }>()
)

export const LoadTechniciansForPackageContract = createAction(
  '[Request Overview] LoadTechniciansForPackageContract',
  props<{ agencyUuid?: string, contract: PackageContract }>()
)
export const TechniciansForPackageContractLoaded = createAction(
  '[Request Overview] TechniciansForPackageContractLoaded',
  props<{
    contract?: PackageContract
    technicians: Array<{ name: string, uuid: string }>
  }>()
)
export const AddTechniciansToPackageContract = createAction(
  '[Request Overview] AddTechniciansToPackageContract',
  props<{
    agencyUuid?: string
    technicians: string[]
    contract: PackageContract
  }>()
)
export const RemoveTechnicianFromPackageContract = createAction(
  '[Request Overview] RemoveTechnicianFromPackageContract',
  props<{
    technician: string
    agencyUuid?: string
    contract: PackageContract
  }>()
)
export const ProposeNewPriceForPackageContract = createAction(
  '[Request Overview] ProposeNewPriceForPackageContract',
  props<{
    newPrice: number
    contract: PackageContract
  }>()
)
export const AcceptNewPriceForPackageContract = createAction(
  '[Request Overview] AcceptNewPriceForPackageContract',
  props<{
    contract: PackageContract
  }>()
)

export const LoadTechniciansAndAgencies = createAction(
  '[Request Overview] Load technicians list and agency list',
  props<{ staffiesOnly?: string, offerUuid?: string }>()
)
export const TechniciansAndAgenciesLoaded = createAction(
  '[Request Overview] Technicians and agencies loaded',
  props<{ responseList: any }>()
)
export const AddTechniciansToOffer = createAction(
  '[Request Overview] Add technicians to and offer',
  props<{ selectedTechnicians: any[], offer: any }>()
)
export const SetSort = createAction(
  '[Request Overview] Set sort',
  props<{ sort: string }>()
)
export const SetFilterPage = createAction(
  '[Request Overview] Set page for filtering',
  props<{ page: number }>()
)
export const SetActiveFilter = createAction(
  '[Request Overview] Set active filter',
  props<{ activeFilter: string }>()
)
export const SetRefNumber = createAction(
  '[Request Overview] Set Ref Number',
  props<{ refNumber: string }>()
)
export const SetFacilityUuid = createAction(
  '[Request Overview] SetFacilityUuid',
  props<{ facilityUuid: string }>()
)
export const SetActingAs = createAction(
  '[Request Overview] Set acting as',
  props<{ role: any }>()
)
export const EntityUuidSet = createAction(
  '[Request Overview] Selected entity uuid is set',
  props<{ uuid: string }>()
)

export const EndContract = createAction(
  '[Requests Overview] End contract',
  props<{ contract: any, endDate: string }>()
)
export const AcceptContract = createAction(
  '[Requests Overview] Accept contract',
  props<{ contract: any, agencyUuid?: string, labourRegulationsAcknowledgment?: boolean }>()
)
export const DeclineContract = createAction(
  '[Requests Overview] Decline contract',
  props<{ contract: any, reasons?: any[] }>()
)
export const AgencyAcceptContractAsTechnician = createAction(
  '[Requests Overview] Agency Accept contract as Technician',
  props<{ contract: any }>()
)
export const DocumentsNeededContract = createAction(
  '[Request Overview] Documents needed contract',
  props<{ contract: any, neededDocuments: string }>()
)
export const LinkAgencyToContract = createAction(
  '[Request Overview] Link agency to contract',
  props<{ contract: any, agencyUuid: string }>()
)
export const AcceptDateExtension = createAction(
  '[Requests Overview] AcceptDateExtension',
  props<{ contract: any }>()
)
export const DeclineDateExtension = createAction(
  '[Requests Overview] DeclineDateExtension',
  props<{ contract: any }>()
)

export const SetOfferType = createAction(
  '[Request Overview] Set offer type',
  props<{ offerType: string }>()
)

export const LoadOfferWithContracts = createAction(
  '[Requests Overview] Load offer with contracts',
  props<{ refNumber: string }>()
)
export const LoadPermanentOfferWithContracts = createAction(
  '[Requests Overview] Load permanent offer with contracts',
  props<{ refNumber: string }>()
)
export const LoadPackageOfferWithContracts = createAction(
  '[Requests Overview] Load package offer with contracts',
  props<{ refNumber: string }>()
)
export const LoadPackageOfferAndContractForTechnician = createAction(
  '[Requests Overview] LoadPackageOfferAndContractForTechnician',
  props<{ refNumber: string }>()
)
export const AgencyAcceptsPackageContractInvitation = createAction(
  '[Requests Overview] Agency Accepts Package Contract Invitation',
  props<{ contract: any, payload: any, agencyUuid?: string }>()
)
export const AgencyEditsPackageContract = createAction(
  '[Requests Overview] AgencyEditsPackageContract',
  props<{ contract: PackageContract, payload: any, agencyUuid?: string }>()
)
export const LoadAgencyOfferWithContracts = createAction(
  '[Requests Overview] Load agency offer with contracts',
  props<{ refNumber: string }>()
)
export const CloseAgencyOffer = createAction(
  '[Requests Overview] Close agency offer',
  props<{ refNumber: string, url: string, isBackoffice: boolean }>()
)
export const ReopenAgencyOffer = createAction(
  '[Requests Overview] Reopen agency offer',
  props<{ refNumber: string, url: string, isBackoffice: boolean }>()
)
export const DeleteAgencyOffer = createAction(
  '[Requests Overview] Delete agency offer',
  props<{ url: string, isBackoffice: boolean }>()
)
export const SuccessfulDeletedAgencyOffer = createAction(
  '[Requests Overview] Successful deleted agency offer'
)
export const FailedToDeleteAgencyOffer = createAction(
  '[Requests Overview] Failed to delete agency offer'
)
export const AcceptAgencyOfferContract = createAction(
  '[Requests Overview] Accept agency offer contract',
  props<{ refNumber: string, url: string, isBackoffice: boolean }>()
)
export const DeclineAgencyOfferContract = createAction(
  '[Requests Overview] Decline agency offer contract',
  props<{ refNumber: string, url: string, reason?: string, isBackoffice: boolean }>()
)
export const MarkAgencyOfferContractAsDocumentsNeeded = createAction(
  '[Request Overview] Documents needed agency offer contract',
  props<{ refNumber: string, url: string, explanation: string, isBackoffice: boolean }>()
)

export const PauseContract = createAction(
  '[Requests Overview] PauseContract',
  props<{ contract: Contract, fromDate: string, toDate: string }>()
)

export const ResumeContract = createAction(
  '[Requests Overview] ResumeContract',
  props<{ contract: Contract }>()
)

export const RequestsOverviewActions = {
  LoadListBO,
  ListLoaded,
  FailedToLoadList,
  LoadOfferContracts,
  SelectedOfferContractsLoaded,
  FailedToLoadOfferContracts,
  ProposeStartDate,
  CloseOffer,
  ApproveOffer,
  LoadPublicProfile,
  PublicProfileLoaded,
  DeleteOffer,
  SuccessfulDeletedOffer,
  FailedToDeleteOffer,
  LoadContract,
  ContractLoaded,
  OfferLoaded,
  LoadOffer,
  ResetRequestsOverviewState,
  SetActingAs,
  AddStaffiesToOffer,
  LoadStaffies,
  StaffiesLoaded,
  LoadTechniciansAndAgencies,
  TechniciansAndAgenciesLoaded,
  AddTechniciansToOffer,
  EntityUuidSet,
  SetActiveFilter,
  EndContract,
  AcceptContract,
  DeclineContract,
  DocumentsNeededContract
}
