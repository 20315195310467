<div class="select-container">
    <ng-select
        class="select"
        [items]="userList"
        [(ngModel)]="selectedValue"
        [placeholder]="'GENERAL.SELECT_OPTION' | translate"
        (change)="updateUuidQueryParam($event)"
        [searchable]="true"
        [bindValue]="'userUuid'"
        [bindLabel]="'name'"
    >
    </ng-select>
</div>
<staffnow-request-listing
    [actingAs]="USER_ROLES.ROLE_TECHNICIAN"
></staffnow-request-listing>
