export enum ApiRootLinkRel {
  AuthorizedUserProfile = 'authorizedUserProfile',
  MyProfile = 'myProfile',
  Discover = 'discover',
  MyFacility = 'myFacility',
  CustomerSupport = 'customerSupport',
  GetTechnicians = 'getTechnicians',
  SearchJobOpenings = 'searchJobOpenings',
  GetGroupMroList = 'getGroupMroList',
  Offers = 'offers',
  PackageOffers = 'packageOffers',
  PermanentOffers = 'permanentOffers',
  GetPersonalOffers = 'getPersonalOffers',
  GetContracts = 'getContracts',
  Technicians = 'technicians',
  GetStaffies = 'getStaffies',
  GetMroFacilities = 'getMroFacilities',
  Self = 'self',
  GetAgencyFacilities = 'getAgencyFacilities',
  SubmitOrderedAgencies = 'agencyFacilitiesOrder',
  UpdateAircraft = 'updateAircraft',
  GetOwnTechnicians = 'getOwnTechnicians',
  GetOwnTechniciansSimple = 'getOwnTechniciansSimple',
  GetOwnTechniciansFull = 'getOwnTechniciansFull',
  NotGivenFeedback = 'notGivenFeedback',
  ChangeStaffieStatus = 'changeStaffieStatus',
  Faqs = 'faqs',
  TitlesRequirements = 'titlesRequirements',
  Profile = 'profile',
  AccountStatusEnum = 'accountStatusEnum',
  ProfileStatusEnum = 'profileStatusEnum',
  Airplanes = 'airplanes',
  Languages = 'languages',
  Licenses = 'licenses',
  AmeNomenclatures = 'ameNomenclatures',
  Countries = 'countries',
  GetOfferWithContracts = 'getOfferWithContracts',
  GetPermanentOfferWithContracts = 'getPermanentOfferWithContracts',
  GetPackageOfferWithContracts = 'getPackageOfferWithContracts',
  GetPackageOfferDetailsForTechnician = 'getPackageOfferDetailsForTechnician',
  GetAgencyOfferWithContracts = 'getAgencyOfferWithContracts',
  DocumentAreasURL = 'documentAreasURL',
  ExperienceLetter = 'experienceLetters',
  ExperienceConfirmationRequest = 'experienceConfirmationRequest',
  Mailbox = 'conversations',
  MroFacilityList = 'mroFacilityList',
  GetPendingTasks = 'getPendingTasks',
  GetSimpleMroList = 'getSimpleMroList',
  PriceMatrix = 'getPriceMatrix',
  TechnicianRegistrationLink = 'technicianRegistrationLink',
  RequestDeletion = 'requestDeletion',
  GrantAccessToRegionToBackoffice = 'grantAccessToRegionToBackoffice',
  GrantAccessToRegionToMro = 'grantAccessToRegionToMro',
  GetListOfSkills = 'getListOfSkills',
  Skills = 'skills',
  AgencyJobOpenings = 'agencyJobOpenings',
  AviationCompaniesJobOpenings = 'aviationCompaniesJobOpenings',
  GetAgencyUsers = 'getAgencyUsers',
  GetMroUsers = 'getMroUsers',
  AdministrativeUsers = 'administrativeUsers',
  Queries = 'queries',
  Reports = 'reports',
  GetMroList = 'getMroList',
  GetAviationCompanyListForAdvertisement = 'getAviationCompanyListForAdvertisement',
  GetCustomerSupportTopics = 'getCustomerSupportTopics',
  AviationCompanyChargeRatesDocument = 'getAviationCompanyChargeRatesDocument'
}
