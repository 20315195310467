<div
    class="overview-container"
    [ngClass]="{
        myAgency: isMyAgencyTab,
        'hide-filters': hideFilters,
        overflowHidden: overflowHidden
    }"
>
    <div class="overview-filters align-items-center">
        <h4 class="h4">{{ 'TASKS.VIEW.QUICK_FILTERS.LABEL' | translate }}</h4>
        <div class="filters-buttons">
            <general-button
                (onClick)="toggleFilters('active')"
                [highlight]="filters.active"
                color="primary-lowlight"
                size="small"
                [text]="'GENERAL.ACTIVE' | translate"
            ></general-button>
            <general-button
                *ngIf="shouldDisplayPending"
                (onClick)="toggleFilters('pending')"
                [highlight]="filters.pending"
                color="primary-lowlight"
                size="small"
                [text]="'OFFER.STATUS.PENDING' | translate"
            ></general-button>
            <general-button
                *ngIf="shouldDisplayDocumentsNeeded"
                (onClick)="toggleFilters('documentsNeeded')"
                [highlight]="filters.documentsNeeded"
                color="primary-lowlight"
                size="small"
                [text]="'GENERAL.DOCS_NEEDED' | translate"
            ></general-button>
            <general-button
                (onClick)="toggleFilters('past')"
                [highlight]="filters.past"
                color="primary-lowlight"
                size="small"
                [text]="'OFFER.STATUS.FINISHED' | translate"
            ></general-button>
            <general-button
                *ngIf="shouldDisplayRejected"
                (onClick)="toggleFilters('rejected')"
                [highlight]="filters.rejected"
                color="primary-lowlight"
                size="small"
                [text]="'REQUEST_LISTING.REJECTED_FILTER_LABEL' | translate: {isTechnician: loggedInUserRole.isTechnician()}"
            ></general-button>
            <general-button
                (onClick)="toggleFilters('all')"
                [highlight]="filters.all"
                color="primary-lowlight"
                size="small"
                [text]="'GENERAL.ALL' | translate"
            ></general-button>
            <div class="mro-filter" *ngIf="hasMroList">
                <enum-filter
                    (onChange)="onSelectedMroChange($event)"
                    [filterConfig]="getConfig()"
                    size="small"
                ></enum-filter>
            </div>
        </div>

        <!--OTHER ACTIONS-->
        <div class="search-container">
            <div class="search-input-wrapper">
                <input
                    type="text"
                    class="form-control search-input"
                    [placeholder]="'GENERAL.SEARCH_BY_REF_NO' | translate"
                    (keyup)="pressedKeyValidation($event)"
                    [(ngModel)]="refNumberSearch"
                    [maxlength]="CUSTOM_REFERENCE_NUMBER_MAX_LENGTH"
                />
                <i
                    class="material-icons clear-reference"
                    [style.visibility]="refNumberSearch ? 'visible' : 'hidden'"
                    (click)="clearSearch()"
                >
                    remove_circle
                </i>
            </div>
            <general-button
                (onClick)="onSearchByRefNumber()"
                size="small"
                [text]="'GENERAL.SEARCH' | translate | uppercase"
            ></general-button>
        </div>
        <ng-container *ngIf="isMro">
            <div class="create-offer-button-wrapper">
                <general-button
                    *ngIf="isTemporaryJobOpening"
                    (onClick)="navigateToCreateTemporaryJobOpeningForm()"
                    size="small"
                    [text]="'REQUEST_LISTING.CREATE_TEMPORARY_JOB_OPENING' | translate | uppercase"
                ></general-button>
                <general-button
                    *ngIf="canCreatePermanentOffers"
                    (onClick)="navigateToCreatePermanentJobOpeningForm()"
                    size="small"
                    [text]="'REQUEST_LISTING.CREATE_PERMANENT_JOB_OPENING' | translate | uppercase"
                ></general-button>
                <general-button
                    *ngIf="canCreatePackageOffers"
                    (onClick)="navigateToCreatePackageOfferForm()"
                    size="small"
                    [text]="'REQUEST_LISTING.CREATE_FIXED_PRICE_JOB_OPENING' | translate | uppercase"
                ></general-button>
            </div>
        </ng-container>
        <div class="filter-toggle-button">
            <span (click)="toggleHideFilters()">
                {{
                    (hideFilters ? 'GENERAL.SHOW_FILTERS' : 'GENERAL.HIDE_FILTERS') | translate
                }}
            </span>
        </div>
    </div>

    <div *ngIf="offerList.length > 0; else noOffers">
        <div class="request-listing-wrapper">
            <ng-container *ngIf="isMro">
                <staffnow-mro-request-card
                    *ngFor="let offer of offerList"
                    [offer]="offer"
                    [userRole]="userRole"
                ></staffnow-mro-request-card>
            </ng-container>

            <ng-container *ngIf="isTechnician">
                <staffnow-technician-request-card
                    *ngFor="let offer of offerList"
                    [offer]="offer"
                ></staffnow-technician-request-card>
            </ng-container>

            <ng-container *ngIf="isAgency">
                <staffnow-agency-request-card
                    *ngFor="let offer of offerList"
                    [offer]="offer"
                ></staffnow-agency-request-card>
            </ng-container>

            <ng-container *ngIf="isAgencyOwnTechnician">
                <staffnow-technician-request-card
                    *ngFor="let offer of offerList"
                    [offer]="offer"
                    [agencyTechnicianUuid]="selectedTechnicianUuid"
                ></staffnow-technician-request-card>
            </ng-container>
        </div>
        <div class="overview-pagination">
            <pagination
                *ngIf="isPlatformWeb; else appLoader"
                class="pagination"
                [previousText]="'GENERAL.PREV' | translate"
                firstText="&laquo;"
                lastText="&raquo;"
                [maxSize]="5"
                [totalItems]="totalElements"
                [ngModel]="page"
                [itemsPerPage]="pageSize"
                [boundaryLinks]="true"
                (pageChanged)="pageChanged($event)"
            >
            </pagination>
            <ng-template #appLoader>
                <general-button
                    *ngIf="!isLastPage"
                    [text]="'GENERAL.LOAD_MORE' | translate"
                    [isLoading]="isLoading"
                    (onClick)="loadMoreOffers()"
                ></general-button>
            </ng-template>
        </div>
    </div>
    <ng-template #noOffers>
        <div class="no-offers">
            <div class="h3">
                {{'GENERAL.SEARCH.JO_NO_RESULTS' | translate}}
            </div>
        </div>
    </ng-template>
</div>
