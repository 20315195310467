export interface RegistrationComponentState {
  registerStatus: 'IDLE' | 'LOADING' | 'ERROR' | 'SUCCESS';
  errorMessage: string;
  registerEntity: 'MRO' | 'TECHNICIAN' | null;
}

export const initialState: RegistrationComponentState = {
  registerStatus: 'IDLE',
  errorMessage: '',
  registerEntity: null
};
