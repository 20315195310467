<staffnow-create-offer-banner
    [facilityProfile]="facilityProfile"
    invitationTarget="agencies"
></staffnow-create-offer-banner>
<div class="package-offer-details-container">
    <staffnow-go-back-button
        [currentPath]="breadcrumbs"
    ></staffnow-go-back-button>
    <div *ngIf="isSettingInitialValues" class="loader-wrapper">
        <staff-loader [customClass]="'splash-screen-loader'"></staff-loader>
    </div>
    <div *ngIf="!isSettingInitialValues" class="create-offer-container form-container">
        <!-- CREATE OFFER FORM -->
        <section class="create-offer-main">
            <div>
                <form
                    *ngIf="createOfferForm"
                    [ngrxFormState]="createOfferForm | async"
                    class="form-body"
                    novalidate
                >
                    <div class="form-group highlight row create-offer-header">
                        <!-- PRIORITY -->
                        <div class="form-group header-selector">
                            <label> Priority level* </label>
                            <simple-select
                                [getLabel]="getLabelFunction"
                                [items]="['HIGH', 'MEDIUM', 'LOW']"
                                [ngrxFormControlState]="createOfferFormValue.controls.priority"
                                placeholder="Choose a priority"
                            >
                            </simple-select>
                            <span class="user-guide">
                                <small class="text-muted">
                                    Only visible for Aviation Companies and Agencies.
                                </small>
                            </span>
                        </div>
                    </div>
                    <!-- SELECT COMPANY -->
                    <div *ngIf="wrappedRole.isHumanResourcesOrCentralQuality()" class="form-group row">
                        <div class="form-group col">
                            <aviation-company-selector
                                (onUpdate)="initForm($event)"
                                [facilityList]="facilityList"
                                [isDisabled]="isEditForm"
                                [selectedFacility]="facilityProfile"
                            ></aviation-company-selector>
                        </div>
                    </div>
                    <!-- REFERENCE NUMBER AND DATE -->
                    <div class="form-group row">
                        <div class="form-group col" style="padding-right: 26px">
                            <custom-reference-input
                                *ngIf="!isSettingInitialValues"
                                [formState]="createOfferFormValue"
                                [isEditMode]="isEditForm"
                                [isRestrictedMode]="isRestrictedMode()"
                            ></custom-reference-input>
                        </div>
                        <div class="form-group col">
                            <label>Preferred period*</label>
                            <date-picker-range
                                (dateUpdated)="handleDateChange($event)"
                                [disabled]="isRestrictedMode()"
                                [minDate]="rangePickerMinDate"
                                [range]="rangePickerValue"
                                placeholder="Pick a range"
                            ></date-picker-range>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="form-group col">
                            <label>
                                Title
                                <small class="text-muted optional">
                                    (optional)
                                </small>
                            </label>
                            <input
                                [ngrxFormControlState]="createOfferFormValue.controls.title"
                                autocomplete="off"
                                class="form-control"
                                maxlength="60"
                                placeholder="Type 6 to 60 characters to give a name to your fixed-price job opening"
                                type="text"
                            />
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="form-group col">
                            <label for="location">Location*</label>
                            <ng-select
                                (change)="writeLocationIdToForm()"
                                [(ngModel)]="selectedLocation"
                                [clearSearchOnAdd]="true"
                                [closeOnSelect]="true"
                                [disabled]="isRestrictedMode() || !offersAreHandledCentrally"
                                [items]="locationList"
                                [ngModelOptions]="{ standalone: true }"
                                [searchable]="true"
                                bindLabel="name"
                                id="location"
                                placeholder="Select location"
                            ></ng-select>
                        </div>
                    </div>
                    <!-- SPECIFICATION -->
                    <div class="form-group row">
                        <div class="form-group col">
                            <label> Technical Specifications* </label>
                            <textarea
                                [ngrxFormControlState]="createOfferFormValue.controls.specification"
                                class="form-control"
                                cols="30"
                                [attr.maxlength]="SPECIFICATION_MAX_LENGTH"
                                name="presentation"
                                placeholder="Enter the job opening specifications"
                                rows="5"
                            >
                            </textarea>
                        </div>
                    </div>
                    <!-- FORM ACTIONS -->
                    <div class="form-row justify-content-center create-offer-form-actions">
                        <general-button
                            (onClick)="cancelCreation()"
                            backgroundStyle="outline"
                            class="mr-5"
                            text="CANCEL"
                        ></general-button>
                        <general-button
                            (onClick)="handleFormSubmit()"
                            [isDisabled]="
                                !createOfferFormValue.isValid ||
                                !createOfferFormValue.isDirty
                            "
                            [isLoading]="isSubmitting"
                            [text]="'CONFIRM' | translate"
                        ></general-button>
                    </div>
                </form>
            </div>
        </section>
    </div>
</div>
