import { createAction, props } from '@ngrx/store'
import { BulletPointItem } from '@libs/shared/models/bullet-point.model'
import PendingTask from '../modules/my-tasks/models/pending-task.model'
import { CustomerSupportTopicTypeState } from './app-state.model'

export const SubmitFormToCustomerSupport = createAction(
  '[App] SubmitFormToCustomerSupport',
  props<{ payload: any }>()
)

export const CustomerSupportFormSubmitted = createAction(
  '[App] CustomerSupportFormSubmitted'
)

export const FailedToSubmitCustomerSupportForm = createAction(
  '[App] FailedToSubmitCustomerSupportForm'
)

export const GenerateReport = createAction(
  '[App - Reports] Generate report',
  props<{
    payload: {
      href: string
      range: { fromDate: string, toDate: string }
      uuid: string
    }
  }>()
)

export const ReportGenerated = createAction(
  '[App - Reports] Report generated successfully'
)

export const FailedToGenerateReport = createAction(
  '[App - Reports] Failed to generate report'
)

export const LoadMroFacilitiesForReports = createAction(
  '[App - Reports] Load MRO Facilities'
)

export const MroFacilitiesForReportsLoaded = createAction(
  '[App - Reports] MRO Facilities loaded',
  props<{ response: any }>()
)

export const GetFaqsList = createAction('[Manage Content] Get list of faqs')

export const FaqsListLoaded = createAction(
  '[Manage Content] List of faqs loaded',
  props<{ faqsList: BulletPointItem[] }>()
)

export const FailedToGetFaqs = createAction(
  '[Manage Content] Failed to get list of faqs'
)

export const GetTitlesRequirementList = createAction(
  '[Manage Content] Get list of titles requirements'
)

export const TitlesRequirementListLoaded = createAction(
  '[Manage Content] List of titles requirement loaded',
  props<{ titlesRequirementList: BulletPointItem[] }>()
)

export const FailedToGetTitlesRequirementList = createAction(
  '[Manage Content] Failed to get list of titles requirement'
)

export const GetMyTasksList = createAction(
  '[App - Data] Get list of user pending tasks'
)

export const MyTasksListLoaded = createAction(
  '[App - Data] List of user tasks loaded',
  props<{ myTasksList: PendingTask[] }>()
)

export const FailedToGetMyTasks = createAction(
  '[App - Data] Failed to get list of user tasks'
)

export const IgnoreTask = createAction(
  '[App - Data] Ignore a pending tasks, only for agencies',
  props<{ payload: { url: string } }>()
)

export const TaskIgnoredSuccessfully = createAction(
  '[App - Data] Ignore task was successful, only for agencies'
)

export const TaskIgnoredFailed = createAction(
  '[App - Data] Ignore task failed, only for agencies'
)

export const GetSimpleMroList = createAction(
  '[App - Data] Get list of MRO facilities, only for technician'
)

export const SimpleMroListLoaded = createAction(
  '[App - Data] Loaded list of MRO facilities, only for technician',
  props<{ facilities: any[] }>()
)

export const RequestTechnicianDeletion = createAction(
  '[Manage Content] RequestTechnicianDeletion',
  props<{ requestTechnicianDeletionLink: string, reason: string }>()
)

export const ContractDeletionRequestSuccessful = createAction(
  '[Manage Content] ContractDeletionRequestSuccessful'
)

export const ContractDeletionRequestFailed = createAction(
  '[Manage Content] ContractDeletionRequestFailed'
)

export const GetCustomerSupportTopics = createAction(
  '[App] GetCustomerSupportTopics'
)

export const CustomerSupportTopicsSuccessfullyReturned = createAction(
  '[App] CustomerSupportTopicsSuccessfullyReturned',
  props<{ topicTypes: CustomerSupportTopicTypeState[] }>()
)
