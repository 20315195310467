import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'staffnow-select-entity-from-list-modal',
  templateUrl: './select-entity-from-list-modal.component.html',
  styleUrls: ['./select-entity-from-list-modal.component.scss']
})
export class SelectEntityFromListModalComponent {
  @Input() public title: string = null;
  @Input() public confirmLabel: string = 'Confirm';
  @Input() public entityList: Array<any> = null;
  @Input() public onConfirmCallback: Function = null;
  @Input() public placeholder: string = null;
  @Input() public bindLabel: string = null;
  public selectedEntity: any = null;

  constructor(public bsModalRef: BsModalRef) {}

  public close(): void {
    this.bsModalRef.hide();
  }

  public onConfirm() {
    this.onConfirmCallback(this.selectedEntity);
    this.close();
  }

  public isFormValid() {
    return !!this.selectedEntity;
  }
}
