<staffnow-create-offer-banner [facilityProfile]="facilityProfile"></staffnow-create-offer-banner>
<div class="contract-details-container">
    <staffnow-go-back-button [currentPath]="breadcrumbs"></staffnow-go-back-button>
    @if(isSettingInitialValues) {
    <div class="loader-wrapper">
        <staff-loader [customClass]="'splash-screen-loader'"></staff-loader>
    </div>
    } @else {
    <div class="create-offer-container form-container">
        <!-- CREATE JOB OPENING FORM -->
        <section class="create-offer-main">
            <div class="profile-container">
                @if(createOfferForm) {
                <form [ngrxFormState]="createOfferForm | async" class="form-body" novalidate>
                    <!-- TYPE AND PRIORITY -->
                    <div class="form-group highlight row create-offer-header">
                        @if(allowPrivateOffers) {
                        <div class="form-group offer-type-selector">
                            <label for="type"> Type* </label><br />
                            <button
                                id="type"
                                (click)="setPrivate(false)"
                                [ngClass]="{ 'btn-highlighted': !isPrivate }"
                                class="btn btn-outline-primary mr-4"
                            >
                                @if(!isPrivate) {
                                <span class="material-icons-outlined btn-icon">check_circle</span>
                                } PUBLIC
                            </button>
                            <button
                                (click)="setPrivate(true)"
                                [ngClass]="{ 'btn-highlighted': isPrivate }"
                                class="btn btn-outline-primary"
                            >
                                @if(isPrivate) {
                                <span *ngIf="isPrivate" class="material-icons-outlined btn-icon">check_circle</span>
                                } PRIVATE
                            </button>
                        </div>
                        }
                        <div class="form-group offer-priority-selector">
                            <label for="priority-level"> Priority level* </label>
                            <simple-select
                                id="priority-level"
                                [getLabel]="getLabelFunction"
                                [items]="['HIGH', 'MEDIUM', 'LOW']"
                                [ngrxFormControlState]="createOfferFormValue.controls.priority"
                                placeholder="Choose a priority"
                            ></simple-select>
                            <span class="user-guide">
                                <small class="text-muted"> Only visible for Aviation Companies and Agencies. </small>
                            </span>
                        </div>
                        <!-- AUTOMATIC CLOSE -->
                        <div class="form-group automatic-close-input">
                            <div class="form-group col">
                                <div class="custom-control custom-checkbox">
                                    <input
                                        (change)="toggleAutoClose($event)"
                                        [(ngModel)]="closeAfterDaysIsChecked"
                                        [disabled]="isRestrictedMode()"
                                        class="custom-control-input"
                                        id="close-after-days-check"
                                        name="close-after-days-check"
                                        type="checkbox"
                                    />
                                    <label class="custom-control-label" for="close-after-days-check">
                                        Close automatically after
                                    </label>
                                </div>
                                <div class="input-group">
                                    <input
                                        (keyup)="clipCloseAfterDays()"
                                        [disabled]="!isCloseAfterDaysEnabled"
                                        [max]="MAXIMUM_CLOSE_AFTER_DAYS"
                                        [min]="MINIMUM_CLOSE_AFTER_DAYS"
                                        [ngrxFormControlState]="createOfferFormValue.controls.closeAfterDays"
                                        autocomplete="off"
                                        class="form-control"
                                        id="close-after-days-input"
                                        type="number"
                                    />
                                    <div class="input-group-append">
                                        <span class="input-group-text">days</span>
                                    </div>
                                </div>
                                <span class="user-guide">
                                    <small class="text-muted optional">
                                        Up to {{ MAXIMUM_CLOSE_AFTER_DAYS }} days.
                                    </small>
                                </span>
                            </div>
                        </div>
                        @if (facilityProfile.allowTcnApplicationsToggle) {
                        <div class="form-group highlight row w-100 mb-0">
                            <div class="custom-control custom-checkbox">
                                <input
                                    [ngrxFormControlState]="createOfferFormValue.controls.allowTcnApplications"
                                    [ngrxValueConverter]="allowTcnApplicationsValueConverter"
                                    class="custom-control-input"
                                    id="allowTcnApplications"
                                    name="allowTcnApplications"
                                    type="checkbox"
                                />
                                <label class="custom-control-label" for="allowTcnApplications">
                                    {{ 'CREATE_OFFER.TCN_LABEL' | translate }}
                                </label>
                            </div>
                        </div>
                        }
                    </div>
                    <!-- SELECT COMPANY -->
                    @if(wrappedRole.isHumanResourcesOrCentralQuality()) {
                    <div class="form-group row">
                        <div class="form-group col">
                            <aviation-company-selector
                                (onUpdate)="initFormFacilityDependentFields($event)"
                                [facilityList]="facilityList"
                                [isDisabled]="isEditForm"
                                [selectedFacility]="facilityProfile"
                            ></aviation-company-selector>
                        </div>
                    </div>
                    }
                    <!-- REFERENCE NUMBER AND DATE -->
                    <div class="form-group row">
                        <div class="form-group col" style="padding-right: 26px">
                            @if (!isSettingInitialValues) {
                            <custom-reference-input
                                [formState]="createOfferFormValue"
                                [isEditMode]="isEditForm"
                                [isRestrictedMode]="isRestrictedMode()"
                            ></custom-reference-input>
                            }
                        </div>
                        <div class="form-group col">
                            <label for="pick-period">Pick a period*</label>
                            <date-picker-range
                                id="pick-period"
                                (dateUpdated)="handleDateChange($event)"
                                [minDate]="rangePickerMinDate"
                                [range]="rangePickerValue"
                                placeholder="Pick a range"
                            ></date-picker-range>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="form-group col">
                            <label for="title">Title</label>
                            <input
                                id="title"
                                [ngrxFormControlState]="createOfferFormValue.controls.title"
                                autocomplete="off"
                                class="form-control"
                                maxlength="60"
                                placeholder="Type 6 to 60 characters to give a name to your job opening"
                                type="text"
                            />
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="form-group col">
                            <label for="location">Location*</label>
                            <ng-select
                                (change)="writeLocationIdToForm()"
                                [(ngModel)]="selectedLocation"
                                [clearSearchOnAdd]="true"
                                [closeOnSelect]="true"
                                [disabled]="isRestrictedMode() || !offersAreHandledCentrally"
                                [items]="locationList"
                                [ngModelOptions]="{ standalone: true }"
                                [searchable]="true"
                                bindLabel="name"
                                id="location"
                                placeholder="Select location"
                            ></ng-select>
                        </div>
                    </div>
                    <!-- TECHNICIAN TYPE AND LICENSE -->
                    <div class="form-group row">
                        <div class="form-group col">
                            <label for="technician-type">Technician Type*</label>
                            <select
                                id="technician-type"
                                (change)="onAmeTypeChange($event)"
                                [(ngModel)]="manualUpdateControlValues.ameType"
                                [disabled]="createOfferFormValue.controls.ameType.isDisabled"
                                [ngClass]="{
                                    'is-invalid':
                                        createOfferFormValue.controls.ameType.isDirty &&
                                        createOfferFormValue.controls.ameType.isInvalid
                                }"
                                [ngModelOptions]="{ standalone: true }"
                                class="custom-select"
                                name="type"
                            >
                                <option [value]="null">Select an option</option>
                                @for (option of ameNomenclatureOptions; track option.name) {
                                <option [value]="option.name">
                                    {{ option.name }}
                                </option>
                                }
                            </select>
                        </div>
                        @if(!isSingletonNomenclature) {
                        <div class="form-group col">
                            <label for="level">Technician Level*</label>
                            <select
                                (change)="onAmeLicenseChange($event.target.value)"
                                [(ngModel)]="manualUpdateControlValues.ameTitleId"
                                [disabled]="createOfferFormValue.controls.ameTitleId.isDisabled"
                                [ngClass]="{
                                    'is-invalid':
                                        createOfferFormValue.controls.ameTitleId.isDirty &&
                                        createOfferFormValue.controls.ameTitleId.isInvalid
                                }"
                                [ngModelOptions]="{ standalone: true }"
                                class="custom-select"
                                id="level"
                            >
                                <option [value]="null">Select an option</option>
                                @for (license of ameLicenseTypeOptions; track license.id) {
                                <option [value]="license.id">
                                    {{ license.name }}
                                </option>
                                }
                            </select>
                        </div>
                        }
                    </div>
                    <!-- AIRCRAFTS -->
                    <div class="form-group row">
                        <div class="form-group col aircrafts">
                            <label for="airplanes">Type of aircraft**</label>
                            <multiple-options-select
                                (onChange)="updateListOfAircraft($event)"
                                [disabled]="createOfferFormValue.controls.airplanes.isDisabled"
                                [elements]="aircraftList"
                                [selectedElements]="selectedAircraftList"
                                id="airplanes"
                                placeholder="Select aircraft"
                            ></multiple-options-select>
                        </div>
                        <div class="col">
                            <label for="other-airplanes">Other aircraft**</label>
                            <input
                                (keydown.enter)="handleOtherAirplanesLength($event)"
                                [ngrxFormControlState]="createOfferFormValue.controls.otherAirplanes"
                                autocomplete="off"
                                class="form-control"
                                id="other-airplanes"
                                type="text"
                            />
                        </div>
                    </div>
                    <!-- EXPERIENCE AND OPEN POSITIONS -->
                    <div class="form-group row">
                        <div class="form-group col">
                            <label for="experience">Min Experience*</label>
                            <div class="input-group">
                                <input
                                    (keydown.enter)="experienceValidation($event)"
                                    [disabled]="createOfferFormValue.controls.minExperience.isDisabled"
                                    [ngrxFormControlState]="createOfferFormValue.controls.minExperience"
                                    autocomplete="off"
                                    class="form-control"
                                    id="experience"
                                    max="60"
                                    min="0"
                                    placeholder="Enter min experience"
                                    type="number"
                                />
                                <div class="input-group-append">
                                    <span class="input-group-text" id="years">years</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col">
                            <div>
                                <label for="vacancies">Number of Technicians*</label>
                                <input
                                    (input)="vacanciesValidation($event)"
                                    [ngrxFormControlState]="createOfferFormValue.controls.vacancies"
                                    autocomplete="off"
                                    class="form-control"
                                    id="vacancies"
                                    max="1000"
                                    min="0"
                                    placeholder="Enter number of technicians"
                                    type="number"
                                />
                            </div>
                        </div>
                    </div>

                    <!-- CHARGE RATE -->
                    <div class="form-group">
                        <div class="row">
                            <!--Indicative rate option-->
                            <div class="form-group col">
                                <div class="custom-control custom-checkbox">
                                    <input
                                        (click)="toggleIndicativeRateOption($event)"
                                        (keydown.enter)="toggleIndicativeRateOption($event)"
                                        [disabled]="isEditForm"
                                        [ngrxFormControlState]="createOfferFormValue.controls.hasIndicativeRate"
                                        class="custom-control-input"
                                        id="hasIndicativeRate"
                                        type="checkbox"
                                    />
                                    <label class="custom-control-label" for="hasIndicativeRate">
                                        Publicly listed rate range per hour
                                    </label>
                                </div>
                                @if (facilityProfile.allowsHideRateInJobOffers) {
                                <div class="custom-control custom-checkbox">
                                    <input
                                        [disabled]="isEditForm"
                                        [ngrxFormControlState]="createOfferFormValue.controls.hasHiddenRate"
                                        class="custom-control-input"
                                        id="hasHiddenRate"
                                        type="checkbox"
                                    />
                                    <label class="custom-control-label" for="hasHiddenRate">
                                        {{ 'CREATE_OFFER.HIDDIN_SALARY_LABEL' | translate }}
                                    </label>
                                </div>
                                }
                            </div>
                        </div>
                        <!--With indicative rate-->
                        @if(hasIndicativeRate) {
                        <ng-container>
                            <div class="row">
                                <div class="col">
                                    <label for="chargeRate">
                                        {{ BrandLocalizedTextsEnum.RatePerHour | brandLocalizationTexts }} (lowest)*
                                    </label>
                                    <div class="input-group">
                                        <input
                                            (keypress)="chargeRateValidation($event)"
                                            [ngrxFormControlState]="createOfferFormValue.controls.chargeRate"
                                            autocomplete="off"
                                            class="form-control"
                                            id="chargeRate"
                                            max="chargeRateMaxValue"
                                            min="0"
                                            placeholder="Lowest"
                                            type="number"
                                        />
                                        <div class="input-group-append">
                                            <span class="input-group-text"> {{ currencySymbol }} </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <label for="chargeRate">
                                        {{ BrandLocalizedTextsEnum.RatePerHour | brandLocalizationTexts }} (highest)*
                                    </label>
                                    <div class="input-group">
                                        <input
                                            (keypress)="chargeRateValidation($event)"
                                            [ngrxFormControlState]="createOfferFormValue.controls.maxChargeRate"
                                            autocomplete="off"
                                            class="form-control"
                                            id="maxChargeRate"
                                            max="chargeRateMaxValue"
                                            min="0"
                                            placeholder="Highest"
                                            type="number"
                                        />
                                        <div class="input-group-append">
                                            <span class="input-group-text"> {{ currencySymbol }} </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        } @else {
                        <!--Without indicative rate-->
                        <div class="row">
                            <div class="col">
                                <label for="chargeRate">
                                    {{ BrandLocalizedTextsEnum.RatePerHour | brandLocalizationTexts }}*
                                </label>
                                <div class="input-group">
                                    <input
                                        (keydown.enter)="chargeRateValidation($event)"
                                        [ngrxFormControlState]="createOfferFormValue.controls.chargeRate"
                                        autocomplete="off"
                                        class="form-control"
                                        id="chargeRate"
                                        max="chargeRateMaxValue"
                                        min="0"
                                        placeholder="{{
                                            BrandLocalizedTextsEnum.EnterRatePerHour | brandLocalizationTexts
                                        }}"
                                        type="number"
                                    />
                                    <div class="input-group-append">
                                        <span class="input-group-text"> {{ currencySymbol }} </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col"><!--dummy div for alignment--></div>
                        </div>
                        }
                    </div>

                    <!-- WORKING PATTERN AND DESCRIPTION -->
                    <div class="form-group row">
                        <div class="form-group col-4 pattern-view">
                            <label for="work-pattern">Work Pattern*</label>
                            <div class="custom-controls-stacked" id="work-pattern">
                                <div class="custom-control custom-radio">
                                    <input
                                        (change)="setWorkingPattern($event.target.value)"
                                        [checked]="isPatternSelected(fixedWorkingPatterns.first)"
                                        [disabled]="isRestrictedMode()"
                                        class="custom-control-input"
                                        id="first-radio"
                                        name="pattern"
                                        type="radio"
                                        value="{{ fixedWorkingPatterns.first }}"
                                    />
                                    <label class="custom-control-label" for="first-radio">
                                        5 days on - 2 days off
                                    </label>
                                </div>
                                <div class="custom-control custom-radio">
                                    <input
                                        (change)="setWorkingPattern($event.target.value)"
                                        [checked]="isPatternSelected(fixedWorkingPatterns.second)"
                                        [disabled]="isRestrictedMode()"
                                        class="custom-control-input"
                                        id="second-radio"
                                        name="pattern"
                                        type="radio"
                                        value="{{ fixedWorkingPatterns.second }}"
                                    />
                                    <label class="custom-control-label" for="second-radio">
                                        3 days on - 3 days off
                                    </label>
                                </div>
                                <div class="custom-control custom-radio">
                                    <input
                                        (change)="setWorkingPattern($event.target.value)"
                                        [checked]="isPatternSelected(fixedWorkingPatterns.third)"
                                        [disabled]="isRestrictedMode()"
                                        class="custom-control-input"
                                        id="third-radio"
                                        name="pattern"
                                        type="radio"
                                        value="{{ fixedWorkingPatterns.third }}"
                                    />
                                    <label class="custom-control-label" for="third-radio">
                                        6 days on - 1 days off
                                    </label>
                                </div>
                                <div class="custom-control custom-radio">
                                    <input
                                        (click)="customPatternSet()"
                                        (keydown.enter)="customPatternSet()"
                                        [checked]="isCustomPatternSelected"
                                        [disabled]="isRestrictedMode()"
                                        class="custom-control-input"
                                        id="fourth-radio"
                                        name="pattern"
                                        type="radio"
                                        value="custom"
                                    />
                                    <label class="custom-control-label" for="fourth-radio"> Custom </label>
                                </div>
                                <div class="form-row mt-2">
                                    @if(isCustomPatternSelected) {
                                    <div class="form-group col-6 padded">
                                        <label for="work-days">Work Days*</label>
                                        <input
                                            (input)="setCustomWorkingPattern($event, true)"
                                            [(ngModel)]="customWorkDaysPattern.on"
                                            [disabled]="isRestrictedMode()"
                                            [ngClass]="{
                                                'is-invalid': createOfferFormValue.controls.workDaysOn.isInvalid
                                            }"
                                            class="form-control"
                                            id="work-days"
                                            max="workDaysMaxValue"
                                            maxlength="2"
                                            min="1"
                                            name="ondays"
                                            tooltip="Work days"
                                            type="number"
                                        />
                                    </div>
                                    <div class="form-group col-6">
                                        <label for="off-days"> Off Days* </label>
                                        <input
                                            (input)="setCustomWorkingPattern($event, false)"
                                            [(ngModel)]="customWorkDaysPattern.off"
                                            [disabled]="isRestrictedMode()"
                                            [ngClass]="{
                                                'is-invalid': createOfferFormValue.controls.workDaysOff.isInvalid
                                            }"
                                            class="form-control"
                                            id="off-days"
                                            max="workDaysMaxValue"
                                            maxlength="2"
                                            min="0"
                                            name="offdays"
                                            tooltip="Off days"
                                            type="number"
                                        />
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-8">
                            <label for="job-description"> Job description* </label>
                            <textarea
                                id="job-description"
                                [ngrxFormControlState]="createOfferFormValue.controls.shortPresentation"
                                class="form-control"
                                cols="30"
                                [attr.maxlength]="PRESENTATION_MAX_LENGTH"
                                name="presentation"
                                rows="5"
                            ></textarea>
                        </div>
                    </div>
                    <!-- ADDITIONAL BENEFITS -->
                    @if(!hasIndicativeRate) {
                    <div class="form-group row mb-3">
                        <collapsible heading="Benefits" highlight="true" style="width: 100%">
                            <div style="padding: 0 20px">
                                @for (benefit of benefits;track benefit.description ;let i = $index) {
                                <staffnow-offer-benefit
                                    (changer)="markBenefitsAsDirty()"
                                    [(ngModel)]="benefits[i]"
                                    [currencySymbol]="currencySymbol"
                                    [isDisabled]="isRestrictedMode()"
                                    [isValid]="isBenefitValid(benefits[i])"
                                    [ngModelOptions]="{ standalone: true }"
                                ></staffnow-offer-benefit>
                                }
                            </div>
                        </collapsible>
                    </div>
                    }
                    <!-- FORM ACTIONS -->
                    <div class="form-row justify-content-center create-offer-form-actions">
                        <general-button
                            (onClick)="cancelCreation()"
                            backgroundStyle="outline"
                            class="mr-5"
                            text="CANCEL"
                        ></general-button>
                        <general-button
                            (onClick)="handleFormSubmit()"
                            [isDisabled]="
                                !createOfferFormValue.isValid || !createOfferFormValue.isDirty || !areBenefitsValid()
                            "
                            [isLoading]="isSubmitting"
                            text="CONFIRM"
                        ></general-button>
                    </div>
                </form>
                }
            </div>
        </section>

        <!-- TECHNICIANS -->
        <section class="create-offer-user">
            <div class="user-container">
                <div class="form-head" style="text-align: center">
                    <h1 class="h1">
                        <strong>Selected Technicians</strong>
                    </h1>
                    <span *ngIf="noSelectedTechnicians()"> No technicians selected </span>
                    @for (technician of selectedTechnicians | keyvalue; track technician.key) {
                    <div class="technician-item">
                        @if(technician.value._links?.getProfilePicture) {
                        <div
                            [ngStyle]="{
                                'background-image': 'url(\'' + technician.value._links.getProfilePicture.href + '\')'
                            }"
                            class="profile-pic"
                        ></div>
                        } @else {
                        <div class="profile-pic">
                            <i class="material-icons"> person </i>
                        </div>
                        }
                        <div class="name">
                            <h4 class="h4">
                                {{ technician.value._embedded.profile.firstName }}
                                {{ technician.value._embedded.profile.lastName }}
                            </h4>
                        </div>
                        <div
                            (click)="handleRemoveFromSelectedTechnicians(technician.value)"
                            (keydown.enter)="handleRemoveFromSelectedTechnicians(technician.value)"
                            class="clear"
                        >
                            <i class="material-icons"> clear </i>
                        </div>
                    </div>
                    }
                </div>
            </div>
        </section>
    </div>
    }
</div>
