export enum UserProfileLinkRel {
  Profile = 'profile',
  BlockList = 'blocklist',
  Self = 'self',
  DownloadAllDocuments = 'downloadAllDocuments',
  Availability = 'calendarEvents',
  CreateConversation = 'createConversation',
  GetConversation = 'getConversation',
  ProfilePicture = 'profilePicture',
  GetProfilePicture = 'getProfilePicture',
  AccountStatusChange = 'changeAccountStatus',
  ProfileStatusChange = 'changeProfileStatus',
  DeleteUser = 'deleteUser',
  AmeNomenclature = 'ameNomenclature',
  MroAddToFavorites = 'mroAddToFavorites',
  MroRemoveFromFavorites = 'mroRemoveFromFavorites',
  UpdateBackofficeNotes = 'updateNotes',
  ChangeNewsletterSubscription = 'changeNewsletterSubscription',
  ChangeLoginEmail = 'changeLoginEmail',
  SetTemporaryPassword = 'setTemporaryPassword',
  AccessibleRegions = 'accessibleRegions',
  UpdateTechnicianSkills = 'updateTechnicianSkills',
  UpdateTechnicianSelfIdentification = 'updateTechnicianSelfIdentification',
  UpdateTechnicianRefNumber = 'updateTechnicianRefNumber',
  ToggleTechniciansMatchingOffersSubscription = 'toggleTechniciansMatchingOffersSubscription',
  ToggleTechniciansWeeklyJoSummarySubscription = 'toggleTechniciansWeeklyJoSummarySubscription',
  Subscriptions = 'subscriptions',
  SendExperienceConfirmationRequest = 'sendExperienceConfirmationRequest',
  SendPerformanceFeedbackRequest = 'sendTechnicianPerformanceFeedbackRequest',
  GetTechnicianPerformanceFeedbacks = 'getTechnicianPerformanceFeedbacks',
  ConfigureAutomaticReply = 'configureAutomaticReply',
  WorkShiftOptions = 'workShiftOptions',
  ContractTypeOptions = 'contractTypeOptions',
  WorkPreferencesRel = 'workPreferences'
}
