<section class="profile-settings">
    <staffnow-profile-nav
        *ngIf="profileNavItems.length > 1"
        [menuItems]="profileNavItems"
        (selectedItem)="selectNewActiveItem($event)"
        [activeItem]="activeItem"
    >
    </staffnow-profile-nav>
    <div [ngSwitch]="activeItem">
        <div *ngSwitchCase="tabs.General" class="staffnow-account">
            <staffnow-account
                [selectedAircraftList]="selectedAircraftList"
                [technicianPendingChanges]="technicianPendingChanges"
            >
            </staffnow-account>
        </div>
        <div *ngSwitchCase="tabs.Documents">
            <staffnow-documents
                [technicianPendingChanges]="technicianPendingChanges"
            >
            </staffnow-documents>
        </div>
        <div *ngSwitchCase="tabs.Skills">
            <staffnow-technician-skills></staffnow-technician-skills>
        </div>
        <div *ngSwitchCase="tabs.SelfIdentification">
            <staffnow-technician-self-id
                [technicianSelfIdentification]="technicianSelfIdentification"
                [technicianSelfIdentificationUpdateUrl]="technicianSelfIdentificationUpdateUrl"
            ></staffnow-technician-self-id>
        </div>
        <div *ngSwitchCase="tabs.Availability">
            <staffnow-availability
                [availability]="availability"
                (fetchAvailability)="handleFetchAvailability()"
                (loadMonths)="handleLoadMonths($event)"
            ></staffnow-availability>
        </div>
        <div *ngSwitchCase="tabs.Settings">
            <staffnow-settings
                [blocklist]="blocklist"
                (loadNotificationSubscriptions)="
                    loadNotificationSubscriptions()
                "
                (submitNotificationSubscriptions)="
                    submitNotificationSubscriptions($event)
                "
                (loadBlocklist)="handleLoadBlocklist()"
                (submitBlocklist)="handleSubmitBlocklist($event)"
                (onToggleMatchingOffersSubscription)="onToggleMatchingOffersSubscription($event)"
                (onToggleWeeklyJoSummarySubscription)="onToggleWeeklyJoSummarySubscription($event)"
            >
            </staffnow-settings>
        </div>
        <div *ngSwitchCase="tabs.PerformanceFeedbacks">
            <staffnow-backoffice-performance-feedback-management
                [userProfile]="userProfile"
            >
            </staffnow-backoffice-performance-feedback-management>
        </div>
        <staffnow-backoffice-account-management
            *ngSwitchCase="tabs.Backoffice"
            [userProfile]="userProfile"
            (previousPerformanceFeedbackRequests)="goToPreviousPerformanceFeedbackRequests()"
        ></staffnow-backoffice-account-management>
        <div *ngSwitchDefault>
            <staffnow-account></staffnow-account>
        </div>
    </div>
</section>
