import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core';
import { ActionsSubject } from '@ngrx/store';
import { filter } from 'rxjs/operators';

import { UserProfileActions } from '@libs/user-profile/state/user-profile.actions';
import { Subscription } from 'rxjs';
import { cloneDeep } from 'lodash-es';
import { TechnicianProfileModel } from '@libs/shared/models/technician-profile.model';
import { getEmbeddedResource, getLink, getUrl } from '@libs/shared/bms-common/rest/resource.utils';
import {
  SubscriptionItem,
  SubscriptionNomenclature
} from '@libs/shared/models/technician-subscription.model';
import { UserProfileLinkRel } from '@libs/shared/linkrels/user-profile.linkrel';

export interface ToggleMatchingOffersSubscriptionParameters {
  url: string; subscriptionsUrl: string; isSubscribing: boolean
}

@Component({
  selector: 'staffnow-notification-subscription',
  templateUrl: './notification-subscription.component.html',
  styleUrls: ['./notification-subscription.component.scss'],
  providers: []
})
export class NotificationSubscriptionComponent implements OnChanges, OnDestroy {
  @Input() public userProfile: TechnicianProfileModel;
  @Input() public subsConfig: SubscriptionItem[] = null;
  @Input() public facilities: any[];

  @Output()
  public submitNotificationSubscriptions: EventEmitter<any> = new EventEmitter();

  @Output()
  public toggleMatchingOffersSubscription: EventEmitter<ToggleMatchingOffersSubscriptionParameters> = new EventEmitter();

  public ameNomenclatures: SubscriptionNomenclature[];
  public technicianSubscriptions: SubscriptionItem[] = [];
  public preselectedSubs: { name: string; uuid: string }[] = [];
  public customClass = 'customClass';
  public hasChanges = false;
  public isLoading = false;
  private changesMadeHandler: Subscription;

  get defaultSubscriptionType() {
    return this.userProfile.ameTitles
      .map(title => title.type + ' ' + (title.license ? title.license : ''))
      .join(' ');
  }

  constructor(
    private actionsSubject: ActionsSubject
  ) {
    this.changesMadeHandler = this.actionsSubject
      .pipe(
        filter(
          action =>
            action.type ===
            UserProfileActions.SuccessfullySubmitSubscriptionsList.type
        )
      )
      .subscribe(() => {
        this.hasChanges = false;
        this.isLoading = false;
      });
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (this.subsConfig !== null && this.subsConfig.length > 0) {
      this.technicianSubscriptions = cloneDeep(this.subsConfig);
      this.preselectedSubs = this.subsConfig.map(sub => {
        return {
          name: sub.name,
          uuid: sub.uuid
        };
      });
    } else {
      this.technicianSubscriptions = [];
      this.preselectedSubs = [];
    }
    if (this.userProfile) {
      this.ameNomenclatures = getEmbeddedResource(
        this.userProfile,
        UserProfileLinkRel.AmeNomenclature
      );
    }
  }

  public ngOnDestroy() {
    this.changesMadeHandler.unsubscribe();
  }

  public generateSubscriptionItemTitle(subscription: SubscriptionItem): string {
    const { ameNomenclatures } = subscription;
    let isCustom = false;
    ameNomenclatures.forEach(nomenclature => {
      nomenclature.licenses.forEach(license => {
        if (!this.userHasTitle(license.id) && license.checked) {
          isCustom = true;
        }
      });
    });
    return isCustom ? 'Custom' : this.defaultSubscriptionType;
  }

  public handleMroSelection(selectedItems: any[]): void {
    this.setHasChanges();
    this.removeUnselectedItems(selectedItems);
    this.addNewItems(selectedItems);
  }

  private removeUnselectedItems(selectedItems: any[]) {
    const selectedUuids = selectedItems.map(item => item.uuid);
    this.technicianSubscriptions = this.technicianSubscriptions.filter(sub => selectedUuids.includes(sub.uuid));
  }

  private addNewItems(selectedItems: any[]) {
    const existingUuids: string[] = this.technicianSubscriptions.map(sub => sub.uuid);
    const itemsToAdd = selectedItems.filter(item => !existingUuids.includes(item.uuid));
    this.technicianSubscriptions.push(
      ...itemsToAdd.map(item => this.generateSubscriptionItem(item.name, item.uuid))
    );
  }

  public generateUniqueId(
    uuid: string,
    ameTypeName: string,
    ameLicenseName: string
  ): string {
    return btoa(uuid + ameTypeName + ameLicenseName);
  }

  public submit(): void {
    this.isLoading = true;
    this.submitNotificationSubscriptions.emit(cloneDeep(this.technicianSubscriptions));
  }

  public setHasChanges() {
    if (this.hasChanges) {
      return;
    } else {
      this.hasChanges = true;
    }
  }

  private generateSubscriptionItem(
    name: string,
    uuid: string
  ): SubscriptionItem {
    return {
      name,
      uuid,
      ameNomenclatures: this.ameNomenclatures.map(nomenclature =>
        this.preselectUserTitles(cloneDeep(nomenclature))
      )
    };
  }

  private preselectUserTitles(nomenclatureDeepCopy: SubscriptionNomenclature) {
    nomenclatureDeepCopy.licenses.forEach(license => {
      license.checked = this.userHasTitle(license.id);
    });
    return nomenclatureDeepCopy;
  }

  private userHasTitle(id: number): boolean {
    return this.userProfile.ameTitles.some(title => title.id === id);
  }

  public toggleTechnicianToMatchingOffersSubscription() {
    this.toggleMatchingOffersSubscription.emit({
      url: getUrl(this.userProfile, UserProfileLinkRel.ToggleTechniciansMatchingOffersSubscription),
      subscriptionsUrl: getUrl(this.userProfile, UserProfileLinkRel.Subscriptions),
      isSubscribing: !this.isSubscribedToAllMatchingOffers
    });
  }

  get isSubscribedToAllMatchingOffers(): boolean {
    return !!this.userProfile.isSubscribedToAllMatchingOffers;
  }
}
