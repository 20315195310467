import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UserProfileService} from '../../../services/user-profile.service';
import {Availability, DayObject} from '@libs/shared/models/availability.model';
import {getEmbeddedResource} from '@libs/shared/bms-common/rest/resource.utils';
import {accountStatusConstants} from '@libs/shared/constants/statuses.constants';
import {Store} from '@ngrx/store';
import {AbstractProfileComponent} from '@libs/user-profile/components/abstract-profile-component';
import {DocumentsService} from '@libs/user-profile/services/documents.service';
import {ActivatedRoute} from '@angular/router';
import {isPlatformWeb} from "@libs/shared/helpers/capacitor";

type Direction = 'backward' | "forward";

@Component({
  selector: 'staffnow-availability',
  templateUrl: './availability.component.html',
  styleUrls: ['./availability.component.scss']
})
export class AvailabilityComponent extends AbstractProfileComponent {
  @Input() public availability: Availability;
  @Output() public fetchAvailability: EventEmitter<any> = new EventEmitter();
  @Output() public loadMonths: EventEmitter<any> = new EventEmitter();

  public mappedPeriods = new Map<string, DayObject>();
  months: string[] = [];

  protected readonly isPlatformWeb: boolean = isPlatformWeb();

  constructor(
    store: Store<any>,
    documentsService: DocumentsService,
    activatedRoute: ActivatedRoute,
    private userProfileService: UserProfileService
  ) {
    super(store, documentsService, activatedRoute);
  }

  ngOnInit() {
    super.ngOnInit();
    this.fetchAvailability.emit();
    this.update();
  }

  ngOnChanges() {
    this.update();
  }

  private update() {
    this.mappedPeriods = this.userProfileService.computeEventMap(
      this.availability._embedded.calendarEvents
    );
    if (this.isPlatformWeb) {
      this.months = this.availability.months;
    } else {
      this.months = [];
      this.months.push(this.availability.months[0]);
    }
  }

  get isUserDeleted() {
    return (
      getEmbeddedResource(this.userProfile, 'profile')['accountStatus'] ===
      accountStatusConstants.deleted
    );
  }

  private getMonthBeforeOrAfterCurrent(months: number, direction: Direction) {
    const convertedYearMonth = this.availability.months[0].split('-');
    const year = Number(convertedYearMonth[0]);
    const month = Number(convertedYearMonth[1]) - 1;
    const current = new Date(year, month, 1);

    let monthAfterCurrent: Date;
    let monthBeforeCurrent: Date;

    if (direction === 'forward') {
      monthAfterCurrent = new Date(current.setMonth(current.getMonth() + months));
      const year = new Date(monthAfterCurrent).getFullYear();
      const nextMonth = new Date(monthAfterCurrent).getMonth() + 1;

      return `${year}-${nextMonth.toString().padStart(2, '0')}`;
    } else {
      monthBeforeCurrent = new Date(current.setMonth(current.getMonth() - months));
      const year = new Date(monthBeforeCurrent).getFullYear();
      const prevMonth = new Date(monthBeforeCurrent).getMonth() + 1;

      return `${year}-${prevMonth.toString().padStart(2, '0')}`;
    }
  }

  public loadPast(months: number): void {
    const monthBeforeCurrent = this.getMonthBeforeOrAfterCurrent(months, 'backward');
    this.loadMonths.emit(monthBeforeCurrent);
  }

  public loadNext(months: number): void {
    const monthAfterCurrent = this.getMonthBeforeOrAfterCurrent(months, 'forward');
    this.loadMonths.emit(monthAfterCurrent);
  }

}
