<div class="reg-form-container">
    <form>
        <div>
            <label for="email" class="control-label font-weight-bold">Email</label>
            <input
                class="form-control"
                type="email"
                name="email"
                id="email"
                placeholder="Email"
                autocomplete="off webauthn"
                [(ngModel)]="email"
            />
        </div>

        <div>
            <label for="password" class="control-label font-weight-bold">Password</label>
            <input
                class="form-control"
                type="password"
                id="password"
                name="password"
                placeholder="Password"
                autocomplete="off webauthn"
                [(ngModel)]="password"
            />
        </div>

        <p class="validation-error" *ngIf="wrongCredentials">Email or password incorrect.</p>
        <p class="validation-error" *ngIf="unverifiedEmail">
            Your account is not fully verified. Click
            <a class="a" (click)="resendVerificationEmail()"> here </a>
            to resend the activation link to your email inbox in case you haven't received it.
        </p>

        <general-button
            (onClick)="login()"
            [isDisabled]="!canLogin || loading"
            class="reg-btn"
            size="large"
            text="Sign In"
            type="submit"
        ></general-button>
        <general-button
            (onClick)="requestNewPassword()"
            class="reg-btn"
            size="large"
            text="Request a new Password"
        ></general-button>
    </form>
    <div id="staffnow-terms">
        <a id="staffNowPrivacyPolicy" class="footer-link" target="_blank" [href]="privacyPolicyUrl">
            {{ 'PRIVACY POLICY' }}
        </a>
        <span class="footer-link">|</span>
        <a id="staffNowTermsAndConditions" class="footer-link" target="_blank" [href]="termsAndConditionsUrl">
            {{ 'TERMS & CONDITIONS' }}
        </a>
    </div>
</div>

<staff-loader *ngIf="loading" customClass="splash-screen-loader"></staff-loader>
