import { Component, EventEmitter, Input, Output } from '@angular/core';

import { FilterTypes } from '../../constants/FilterTypes';
import { FilterConfigModel } from '../../models/filter-config.model';

export interface AbstractFilterChangedValue {
  filter: FilterConfigModel;
  value: string | string[] | number | boolean | object;
}

@Component({
  selector: 'abstract-filter',
  templateUrl: './abstract-filter.component.html',
  styleUrls: ['./abstract-filter.component.scss']
})
export class AbstractFilterComponent {
  @Input() public filterConfig: FilterConfigModel = null;
  @Output() public handleChange: EventEmitter<AbstractFilterChangedValue> =
    new EventEmitter<AbstractFilterChangedValue>();
  @Output() public enterPressed: EventEmitter<void> = new EventEmitter<void>();

  public FILTER_TYPES = FilterTypes;

  public isEnterPressed(): void {
    this.enterPressed.emit();
  }

  public onChangeHandler(newValue: string | string[] | number | boolean | object): void {
    this.handleChange.emit({ value: newValue, filter: this.filterConfig });
  }
}
