import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http'
import {Actions, createEffect, ofType} from '@ngrx/effects'
import {Store} from '@ngrx/store'
import {EMPTY, of} from 'rxjs'
import {catchError, map, switchMap, tap, throttleTime, withLatestFrom} from 'rxjs/operators'

import {ToastMessageService} from '@libs/toast-messages/toast-message.service'

import {AppState, CustomerSupportTopicTypeState} from './app-state.model'
import {
  ContractDeletionRequestFailed,
  ContractDeletionRequestSuccessful,
  CustomerSupportFormSubmitted,
  CustomerSupportTopicsSuccessfullyReturned,
  FailedToGenerateReport,
  FailedToGetFaqs,
  FailedToGetMyTasks,
  FailedToGetTitlesRequirementList,
  FailedToSubmitCustomerSupportForm,
  FaqsListLoaded,
  GenerateReport,
  GetCustomerSupportTopics,
  GetFaqsList,
  GetMyTasksList,
  GetSimpleMroList,
  GetTitlesRequirementList,
  IgnoreTask,
  LoadMroFacilitiesForReports,
  MroFacilitiesForReportsLoaded,
  MyTasksListLoaded,
  ReportGenerated,
  RequestTechnicianDeletion,
  SimpleMroListLoaded,
  SubmitFormToCustomerSupport,
  TaskIgnoredFailed,
  TaskIgnoredSuccessfully,
  TitlesRequirementListLoaded
} from './app.actions'
import {identity, pickBy} from 'lodash-es'
import {selectApprovedAgenciesUrl, selectMroApprovalsUrl, selectMroRatesUrl} from './app.selectors'
import {LoadBadges} from '@libs/notifications/state/notifications.actions'
import {getFilteredApiRoot, getPendingTasksUrl} from '@libs/shared/bms-common/api-root/api-root.selectors'
import {ApiRootLinkRel} from '@libs/shared/linkrels/api-root.linkrel'
import {ApiRootLoaded, GlobalReset, LoadLocationList} from '@libs/shared/bms-common/api-root/api-root.actions'
import {getEmbeddedResource, getUrl, hasEmbeddedResource} from '@libs/shared/bms-common/rest/resource.utils'
import {ResourceFactory} from '@libs/shared/bms-common/rest/resource.factory'
import PendingTask from '../modules/my-tasks/models/pending-task.model'
import {
  ApprovedAgenciesLoaded,
  DeleteAgencyApprovals,
  DeleteAgencyApprovalsFailed,
  DeleteAgencyApprovalsSuccessful,
  DeleteAgencyDefaultContract,
  DeleteAgencyDefaultContractFailed,
  DeleteAgencyDefaultContractSuccessful,
  DeleteAgencyPicture,
  DeleteAgencyPictureFailed,
  DownloadAgencyApprovals,
  DownloadAgencyApprovalsFailed,
  DownloadAgencyApprovalsSuccessful,
  DownloadAgencyDefaultContract,
  DownloadAgencyDefaultContractFailed,
  DownloadAgencyDefaultContractSuccessful,
  FacilityProfileLoaded,
  FacilityProfileUpdated,
  FailedToGetApprovedAgencies,
  FailedToGetFacilityProfile,
  FailedToGetMroApprovals,
  FailedToGetMroRates,
  FailedToSubmitApprovedAgencies,
  FailedToUpdateFacilityProfile,
  FailToSubmitMroRates,
  GetApprovedAgencies,
  GetFacilityProfile,
  GetMroApprovals,
  GetMroRates,
  MroApprovalsLoaded,
  MroRatesLoaded,
  SetAgencyApprovalsLabel,
  SetAgencyApprovalsLabelFailed,
  SetAgencyApprovalsLabelSuccessful,
  SubmitApprovedAgencies,
  SubmitMroRates,
  UpdateFacilityProfile
} from '@libs/common-ui/facility-profile/facility-profile.actions'
import {downloadFileBlob, downloadRawBlob} from '@libs/shared/helpers/download-blob-file'
import {extractListOfPromotionalAgencies} from '@libs/shared/helpers/extract-promotional-agencies'
import {MroRate} from '@libs/shared/models/mro-rate.model'
import {CustomNavigationService} from '@libs/shared/services/custom-navigation.service'
import {faqsToBulletPoints, titlesRequirementsToBulletPoints} from '@libs/shared/models/bullet-point.model'
import {DownloadService} from '@libs/shared/services/download.service'
import {NotificationService} from '@libs/notifications/notification.service'
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy'
import {DURATION_1000_MILLISECONDS} from "@libs/shared/constants/duration.constants";
import {ErrorMessageService} from '@libs/common-ui/services/error-message/error-message.service'
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Injectable()
export class AppEffects {
  public navigateOnGlobalReset$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(GlobalReset, FailedToGetFacilityProfile),
        withLatestFrom(this.store.pipe(getFilteredApiRoot)),
        tap(([action, apiRoot]) => {
          this.customNavigationService.resetHistory()
          this.customNavigationService.goToDefaultView()
          this.notificationService.stopListeningToAllWebSocketQueues()
          this.notificationService.listenToBadgesNotifications(
            apiRoot.userAccountId
          )
        })
      ),
    {dispatch: false}
  )

  public submitCustomerSupportForm$ = createEffect(() =>
    this.actions.pipe(
      ofType(SubmitFormToCustomerSupport),
      throttleTime(DURATION_1000_MILLISECONDS),
      withLatestFrom(this.store.pipe(getFilteredApiRoot)),
      switchMap(([action, apiRoot]) => {
        return this.httpService
          .post(
            apiRoot._links[ApiRootLinkRel.CustomerSupport].href,
            action.payload
          )
          .pipe(
            map(() => CustomerSupportFormSubmitted()),
            catchError(response => {
              this.errorMessageService.handleErrorResponseWithCustomMessage(response, this.translateService.instant('SYSTEM.INFO.FAILED_FORM_SUBMIT'));
              return of(FailedToSubmitCustomerSupportForm())
            })
          )
      })
    )
  )

  public getFacilityProfile$ = createEffect(() =>
    this.actions.pipe(
      ofType(GetFacilityProfile),
      switchMap(action => {
        return this.httpService.get(action.facilityUrl).pipe(
          map(response => FacilityProfileLoaded({payload: response})),
          catchError(response => {
            this.errorMessageService.handleErrorResponseWithCustomMessage(response, this.translateService.instant('SYSTEM.INFO.FAILED_GET_FACILITY_PROFILE'));
            return of(FailedToGetFacilityProfile())
          })
        )
      })
    )
  )

  public updateFacilityProfile$ = createEffect(() =>
    this.actions.pipe(
      ofType(UpdateFacilityProfile),
      switchMap(action => {
        return this.httpService.put(action.endpointUrl, action.payload).pipe(
          switchMap(() => {
            this.toastMessageService.success(
              this.translateService.instant('SYSTEM.INFO.FACILITY_UPDATE_SUCCESS')
            )
            return [
              FacilityProfileUpdated(),
              GetFacilityProfile({
                facilityUrl: action.facilityUrl
              })
            ]
          }),
          catchError(response => {
            this.errorMessageService.handleErrorResponse(response);
            return of(FailedToUpdateFacilityProfile())
          })
        )
      })
    )
  )

  public getApprovedAgencies$ = createEffect(() =>
    this.actions.pipe(
      ofType(GetApprovedAgencies),
      withLatestFrom(this.store.pipe(selectApprovedAgenciesUrl)),
      switchMap(([_, approvedAgenciesUrl]) => {
        return this.httpService.get(approvedAgenciesUrl).pipe(
          map((response: any) => {
            return ApprovedAgenciesLoaded({
              payload: {
                selectedFacilities: response.selectedFacilities,
                availableFacilities: extractListOfPromotionalAgencies(
                  getEmbeddedResource(response, 'availableFacilities')
                )
              }
            })
          }),
          catchError(response => {
            this.errorMessageService.handleErrorResponse(response);
            return of(FailedToGetApprovedAgencies())
          })
        )
      })
    )
  )

  public submitApprovedAgencies$ = createEffect(() =>
    this.actions.pipe(
      ofType(SubmitApprovedAgencies),
      throttleTime(DURATION_1000_MILLISECONDS),
      withLatestFrom(this.store.pipe(selectApprovedAgenciesUrl)),
      switchMap(([action, approvedAgenciesUrl]) => {
        return this.httpService
          .post(approvedAgenciesUrl, {facilities: action.facilities})
          .pipe(
            map((response: any) => {
              this.toastMessageService.success(response.message)
              return GetApprovedAgencies()
            }),
            catchError(response => {
              this.errorMessageService.handleErrorResponse(response, 'Failed to submit list');
              return of(FailedToSubmitApprovedAgencies())
            })
          )
      })
    )
  )

  public getMoRates$ = createEffect(() =>
    this.actions.pipe(
      ofType(GetMroRates),
      withLatestFrom(this.store.pipe(selectMroRatesUrl)),
      switchMap(([_, selectMroRatesUrl]) => {
        return this.httpService.get(selectMroRatesUrl).pipe(
          map((response: MroRate[]) =>
            MroRatesLoaded({
              rates: response.map(rate => ({...rate, disable: true}))
            })
          ),
          catchError(response => {
            this.errorMessageService.handleErrorResponse(response);
            return of(FailedToGetMroRates())
          })
        )
      })
    )
  )

  public submitMroRates$ = createEffect(() =>
    this.actions.pipe(
      ofType(SubmitMroRates),
      withLatestFrom(this.store.pipe(selectMroRatesUrl)),
      switchMap(([action, mroRatesURL]) => {
        return this.httpService.put(mroRatesURL, action.rates).pipe(
          map((response: any) => {
            this.toastMessageService.success(response.message)
            return GetMroRates()
          }),
          catchError(response => {
            this.errorMessageService.handleErrorResponse(response, 'Failed to submit rates');
            return of(FailToSubmitMroRates())
          })
        )
      })
    )
  )

  public getMroApprovals$ = createEffect(() =>
    this.actions.pipe(
      ofType(GetMroApprovals),
      withLatestFrom(this.store.pipe(selectMroApprovalsUrl)),
      switchMap(([_, mroApprovalsUrl]) => {
        return this.httpService.get(mroApprovalsUrl).pipe(
          map((response: any) => {
            const resource = hasEmbeddedResource(
              response,
              'simpleFacilityWithUuidDtoList'
            )
              ? getEmbeddedResource<any[]>(
                response,
                'simpleFacilityWithUuidDtoList'
              )
              : []
            return MroApprovalsLoaded({payload: resource})
          }),
          catchError(response => {
            this.errorMessageService.handleErrorResponse(response);
            return of(FailedToGetMroApprovals())
          })
        )
      })
    )
  )

  public requestPermissionForNotifications$ = createEffect(() =>
    this.actions.pipe(
      ofType(ApiRootLoaded),
      withLatestFrom(this.store.pipe(getFilteredApiRoot)),
      map(([_, apiRoot]) => {
        return new LoadBadges()
      })
    )
  )

  public refreshLocationList$ = createEffect(() =>
    this.actions.pipe(
      ofType(ApiRootLoaded),
      switchMap(() => [LoadLocationList()])
    )
  )

  public generateReport$ = createEffect(() =>
    this.actions.pipe(
      ofType(GenerateReport),
      switchMap(action => {
        const href = action.payload.href.split('?')[0]
        const paramsObj = pickBy(
          {
            ...action.payload.range,
            facilityUuid: action.payload.uuid, //TODO: remove facilityuuid param when report migration finished (SN-1397)
            uuid: action.payload.uuid
          },
          identity
        )
        const params = new HttpParams({fromObject: paramsObj})
        return this.downloadService.doGetRequest(href, params).pipe(
          map(response => {
            downloadFileBlob(response)
            this.toastMessageService.success(
              this.translateService.instant('SYSTEM.INFO.REPORT_GENERATE_SUCCESS')
            )
            return ReportGenerated()
          }),
          catchError(response => {
            this.errorMessageService.handleErrorResponseWithCustomMessage(response, this.translateService.instant('SYSTEM.INFO.FAILED_GENERATE_REPORT'));
            return of(FailedToGenerateReport())
          })
        )
      })
    )
  )

  public loadMroFacilitiesForReports$ = createEffect(() =>
    this.actions.pipe(
      ofType(LoadMroFacilitiesForReports),
      withLatestFrom(this.store.pipe(getFilteredApiRoot)),
      switchMap(([_, apiRoot]) =>
        this.resourceFactory
          .from(apiRoot)
          .get(ApiRootLinkRel.GetGroupMroList)
          .pipe(
            map(r => MroFacilitiesForReportsLoaded({response: r})),
            catchError(response => {
              this.errorMessageService.handleErrorResponseWithCustomMessage(response, this.translateService.instant('SYSTEM.INFO.FAILED_GET_FACILITY_LIST'));
              return EMPTY
            })
          )
      )
    )
  )

  public getFaqsList$ = createEffect(() =>
    this.actions.pipe(
      ofType(GetFaqsList),
      withLatestFrom(this.store.pipe(getFilteredApiRoot)),
      switchMap(([_, apiRoot]) => {
        return this.httpService.get(getUrl(apiRoot, ApiRootLinkRel.Faqs)).pipe(
          map((response: any) => {
            return FaqsListLoaded({
              faqsList: faqsToBulletPoints(
                getEmbeddedResource(response, ApiRootLinkRel.Faqs)
              )
            })
          }),
          catchError(response => {
            this.errorMessageService.handleErrorResponseWithCustomMessage(response, this.translateService.instant('SYSTEM.INFO.FAILED_GET_FAQ_LIST'));
            return of(FailedToGetFaqs())
          })
        )
      })
    )
  )

  public getTitlesRequirementList$ = createEffect(() =>
    this.actions.pipe(
      ofType(GetTitlesRequirementList),
      withLatestFrom(this.store.pipe(getFilteredApiRoot)),
      switchMap(([_, apiRoot]) => {
        return this.httpService
          .get(getUrl(apiRoot, ApiRootLinkRel.TitlesRequirements))
          .pipe(
            map((response: any) => {
              return TitlesRequirementListLoaded({
                titlesRequirementList: titlesRequirementsToBulletPoints(
                  getEmbeddedResource(
                    response,
                    ApiRootLinkRel.TitlesRequirements
                  )
                )
              })
            }),
            catchError(response => {
              this.errorMessageService.handleErrorResponseWithCustomMessage(response, this.translateService.instant('SYSTEM.INFO.FAILED_SUBMIT_TITLE_REQUIREMENT'));
              return of(FailedToGetTitlesRequirementList())
            })
          )
      })
    )
  )

  public loadSimpleMrosForTechnician$ = createEffect(() =>
    this.actions.pipe(
      ofType(GetSimpleMroList),
      withLatestFrom(this.store.pipe(getFilteredApiRoot)),
      switchMap(([_, apiRoot]) => {
        return this.httpService
          .get(getUrl(apiRoot, ApiRootLinkRel.GetSimpleMroList))
          .pipe(
            map((response: any[]) =>
              SimpleMroListLoaded({facilities: response})
            ),
            catchError(response => {
              this.errorMessageService.handleErrorResponse(response);
              return EMPTY
            })
          )
      })
    )
  )

  public loadMyTasks$ = createEffect(() =>
    this.actions.pipe(
      ofType(GetMyTasksList),
      withLatestFrom(this.store.pipe(getPendingTasksUrl)),
      switchMap(([_, url]) => {
        return this.httpService.get(url).pipe(
          map((response: PendingTask[]) =>
            MyTasksListLoaded({myTasksList: response})
          ),
          catchError(response => {
            this.errorMessageService.handleErrorResponse(response);
            return of(FailedToGetMyTasks())
          })
        )
      })
    )
  )

  public ignoreTask$ = createEffect(() =>
    this.actions.pipe(
      ofType(IgnoreTask),
      switchMap(action => {
        return this.httpService.patch(action.payload.url, {}).pipe(
          map(() => TaskIgnoredSuccessfully()),
          catchError(response => {
            this.errorMessageService.handleErrorResponse(response);
            return of(TaskIgnoredFailed())
          })
        )
      })
    )
  )

  public deleteAgencyPicture$ = createEffect(() =>
    this.actions.pipe(
      ofType(DeleteAgencyPicture),
      switchMap(action => {
        const deleteUrl = action.deletePictureUrl
        return this.httpService.delete(deleteUrl).pipe(
          map(() => GetFacilityProfile({facilityUrl: action.facilityUrl})),
          catchError(response => {
            this.errorMessageService.handleErrorResponse(response);
            return of(DeleteAgencyPictureFailed())
          })
        )
      })
    )
  )

  public deleteDocumentAgency$ = createEffect(() =>
    this.actions.pipe(
      ofType(DeleteAgencyDefaultContract),
      switchMap(action => {
        const deleteUrl = getUrl(action.document, 'delete')
        return this.httpService.delete(deleteUrl).pipe(
          map(() => DeleteAgencyDefaultContractSuccessful()),
          catchError(response => {
            this.errorMessageService.handleErrorResponse(response);
            return of(DeleteAgencyDefaultContractFailed())
          })
        )
      })
    )
  )

  public deleteDocumentAgencyApprovals$ = createEffect(() =>
    this.actions.pipe(
      ofType(DeleteAgencyApprovals),
      switchMap(action => {
        const deleteUrl = getUrl(action.document, 'delete')
        return this.httpService.delete(deleteUrl).pipe(
          map(() => DeleteAgencyApprovalsSuccessful()),
          catchError(response => {
            this.errorMessageService.handleErrorResponse(response);
            return of(DeleteAgencyApprovalsFailed())
          })
        )
      })
    )
  )

  public setAgencyApprovalsDocumentLabel$ = createEffect(() =>
    this.actions.pipe(
      ofType(SetAgencyApprovalsLabel),
      switchMap(action => {
        const labelUrl = getUrl(action.document, 'label')
        return this.httpService
          .patch(labelUrl, {label: action.newLabel})
          .pipe(
            map(() => SetAgencyApprovalsLabelSuccessful()),
            catchError(response => {
              this.errorMessageService.handleErrorResponse(response);
              return of(SetAgencyApprovalsLabelFailed())
            })
          )
      })
    )
  )

  public downloadDocumentAgencyPicture$ = createEffect(() =>
    this.actions.pipe(
      ofType(DownloadAgencyDefaultContract),
      switchMap(action => {
        const downloadLink = getUrl(action.document, 'self')
        return this.downloadService.doGetRequest(downloadLink).pipe(
          map(response => {
            downloadRawBlob(response.body, (action.document as any).name)
            return DownloadAgencyDefaultContractSuccessful()
          }),
          catchError(response => {
            this.errorMessageService.handleErrorResponse(response);
            return of(DownloadAgencyDefaultContractFailed())
          })
        )
      })
    )
  )

  public downloadDocumentAgencyApprovals$ = createEffect(() =>
    this.actions.pipe(
      ofType(DownloadAgencyApprovals),
      switchMap(action => {
        const downloadLink = getUrl(action.document, 'self')
        return this.downloadService.doGetRequest(downloadLink).pipe(
          map(response => {
            downloadRawBlob(response.body, (action.document as any).name)
            return DownloadAgencyApprovalsSuccessful()
          }),
          catchError(response => {
            this.errorMessageService.handleErrorResponse(response);
            return of(DownloadAgencyApprovalsFailed())
          })
        )
      })
    )
  )

  public requestTechnicianDeletion$ = createEffect(() =>
    this.actions.pipe(
      ofType(RequestTechnicianDeletion),
      throttleTime(DURATION_1000_MILLISECONDS),
      switchMap(action => {
        const link = action.requestTechnicianDeletionLink
        return this.httpService.post(link, action.reason).pipe(
          map(() => {
            this.toastMessageService.success(
              'Deletion request sent successfully!'
            )
            return ContractDeletionRequestSuccessful()
          }),
          catchError(response => {
            this.errorMessageService.handleErrorResponse(response);
            return of(ContractDeletionRequestFailed())
          })
        )
      })
    )
  )

  public getCustomerSupportTopics$ = createEffect(() =>
    this.actions.pipe(
      ofType(GetCustomerSupportTopics),
      withLatestFrom(this.store.pipe(getFilteredApiRoot)),
      switchMap(([action, apiRoot]) => {
        return this.httpService.get<CustomerSupportTopicTypeState[]>(getUrl(apiRoot, ApiRootLinkRel.GetCustomerSupportTopics)).pipe(
          map((topics) => {
            return CustomerSupportTopicsSuccessfullyReturned({topicTypes: topics})
          }),
          catchError(response => {
            this.errorMessageService.handleErrorResponse(response);
            return EMPTY
          })
        )
      })
    )
  )

  constructor(
    private readonly actions: Actions,
    private readonly store: Store<AppState>,
    private readonly resourceFactory: ResourceFactory,
    private readonly toastMessageService: ToastMessageService,
    private readonly httpService: HttpClient,
    private readonly customNavigationService: CustomNavigationService,
    private readonly notificationService: NotificationService,
    private readonly downloadService: DownloadService,
    private readonly errorMessageService: ErrorMessageService,
    private readonly translateService: TranslateService
  ) {
    this.store
      .pipe(getFilteredApiRoot, untilDestroyed(this))
      .subscribe(apiRoot => {
        this.notificationService.listenToBadgesNotifications(
          apiRoot.userAccountId
        )
      })
  }
}
