<i class="material-icons close pointer" (click)="close()">close</i>
<div class="add-entity-modal-wrapper">
    <h2> {{ title }} </h2>
    <ng-select
        [items]="entityList"
        [(ngModel)]="selectedEntity"
        [multiple]="false"
        [closeOnSelect]="false"
        [searchable]="true"
        [placeholder]="placeholder"
        [bindLabel]="bindLabel"
    >
    </ng-select>
    <general-button
        (onClick)="onConfirm()"
        [isDisabled]="!isFormValid()"
        class="mx-auto"
        text="{{confirmLabel}}"
    ></general-button>
</div>
