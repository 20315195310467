<div class="notification-subscription-container">
    <h1 class="h1">Notification of new job openings</h1>
    <div class="guidelines">
        <p>
            Subscribe to receive notifications for newly created job openings
            from specific Aviation Companies.
        </p>
    </div>
    <div class="custom-control custom-checkbox subscribe-to-all-matching-offers-wrapper">
        <input
            type="checkbox"
            class="custom-control-input"
            id="subscribe-to-all-matching-offers"
            [checked]="isSubscribedToAllMatchingOffers"
            (click)="toggleTechnicianToMatchingOffersSubscription()"
        />
        <label
            for="subscribe-to-all-matching-offers"
            class="custom-control-label font-weight-bold"
        >
            Subscribe me to all matching job openings.
        </label>
    </div>
    <div *ngIf="!isSubscribedToAllMatchingOffers">
        <label for="mros">{{ 'Select an Aviation Company' | translate }}</label>
        <multiple-options-select
            id="mros"
            placeholder="Select an Aviation Company"
            [searchable]="true"
            [closeOnSelect]="true"
            [elements]="facilities"
            [selectedElements]="preselectedSubs"
            (onChange)="handleMroSelection($event)"
        ></multiple-options-select>

        <div class="list-of-subscriptions">
            <collapsible
                class="subscription-item"
                *ngFor="let subscription of technicianSubscriptions"
                [heading]="subscription.name + ' - ' + generateSubscriptionItemTitle(subscription)"
            >
                <div class="body">
                    <div
                        *ngFor="let ameType of subscription.ameNomenclatures"
                        class="nomenclature"
                    >
                        <label class="mb-1">{{ ameType.name | translate }}</label>

                        <div class="licenses-list">
                            <span
                                *ngFor="let license of ameType.licenses"
                                class="license-item custom-control custom-checkbox"
                            >
                                <input
                                    id="{{
                                        generateUniqueId(
                                            subscription.uuid,
                                            ameType.name,
                                            license.name
                                        )
                                    }}"
                                    type="checkbox"
                                    class="custom-control-input"
                                    [ngModelOptions]="{ standalone: true }"
                                    (ngModelChange)="setHasChanges()"
                                    [(ngModel)]="license.checked"
                                />
                                <label
                                    for="{{
                                        generateUniqueId(
                                            subscription.uuid,
                                            ameType.name,
                                            license.name
                                        )
                                    }}"
                                    class="custom-control-label"
                                >{{
                                    license.singleton
                                        ? ameType.name
                                        : (license.name | translate)
                                    }}</label
                                >
                            </span>
                        </div>
                    </div>
                </div>
            </collapsible>
        </div>
        <div class="footer">
            <general-button
                text="Confirm Changes"
                [isLoading]="isLoading"
                [isDisabled]="!hasChanges || isLoading"
                (onClick)="submit()"
            ></general-button>
        </div>
    </div>
</div>
