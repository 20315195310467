<staffnow-go-back-button [currentPath]="[
'AGENCY.DETAILS.MY_JO' | translate,
'MRO.PACKAGE_OFFER.PERMANENT_JO_DETAILS' | translate]"></staffnow-go-back-button>
@if(isLoading){
<staff-loader [customClass]="'splash-screen-loader'"></staff-loader>
} @else {
<staffnow-panel [title]="'AGENCY.DETAILS.APPLICATION_STATUS.LABEL' | translate: {refNumber: jobOffer?.refNumber}">
    <ng-container data>
        <div class="technician-details">
            <staffnow-technician-contract-details
                [contract]="contract"
                [isPermanent]="true"
            ></staffnow-technician-contract-details>
            <staffnow-technician-guidelines
                [offer]="offer"
                [contract]="contract"
                [requiresAgencySelectionForOfferApplication]="false"
                [brandName]="brandName"
            ></staffnow-technician-guidelines>
        </div>
    </ng-container>
    <ng-container actions>
        <staffnow-technician-contract-actions
            [contract]="contract"
            [offer]="offer"
            (acceptDocument)="acceptOffer()"
            [isDisabledAcceptDocumentButton]="!canAcceptContract"
            [shouldDisplayAcceptButton]="canAcceptContract"
        ></staffnow-technician-contract-actions>
        <div class="actions">
            <general-button
                (onClick)="initiateMessageWithMro()"
                [isDisabled]="!canCreateConversationWithMro && !canGetPreviousConversationWithMro"
                backgroundStyle="outline"
                size="small"
                [text]="'GENERAL.CONTACT_AVIATION_COMPANY' | translate"
                title="{{ 'SYSTEM.INFO.MESSAGE_MRO_AFTER_ACCEPTING_JO' | translate }}"
                color="secondary"
            ></general-button>
            @if (hasAcceptStartDateLink) {
            <general-button (onClick)="acceptStartDate()" size="small" text="'GENERAL.ACCEPT_START_DATE' | translate"></general-button>
            } @if (hasProposeStartDateLink) {
            <general-button
                (onClick)="handleStartDateProposal()"
                backgroundStyle="outline"
                size="small"
                text="{{ proposeDateLabel }}"
                [color]="datePhaseIsAccepted ? 'secondary' : 'primary'"
            ></general-button>
            }
        </div>
    </ng-container>
</staffnow-panel>
<staffnow-offer-details
    *ngIf="!isLoading"
    [offer]="permanentOffer"
    [title]="'TECHNICIAN.PERMANENT_OFFER_DETAILS' | translate:{offerDescription}"
>
    <ng-container data>
        @if (!permanentOffer.hasHiddenRate) {
        <staffnow-contract-info [label]="'GENERAL.NET_SALARY' | translate">
            {{ offer?.monthlySalary | rate }}
            {{ ' per month' | translate }}
        </staffnow-contract-info>
        }
    </ng-container>
</staffnow-offer-details>
}
