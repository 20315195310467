import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams
} from '@angular/common/http';
import { EMPTY, Observable, of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  tap,
  throttleTime,
  withLatestFrom
} from 'rxjs/operators';

import { ToastMessageService } from '@libs/toast-messages/toast-message.service';

import {
  AcceptAgencyOfferContract,
  AcceptContract,
  AcceptDateExtension,
  AcceptNewPriceForPackageContract,
  AcceptStartDate,
  AddStaffiesToOffer,
  AgenciesLoaded,
  AgencyAcceptContractAsTechnician,
  AgencyAcceptsPackageContractInvitation,
  AgencyEditsPackageContract,
  ApproveOffer,
  CloseAgencyOffer,
  CloseOffer,
  ContractLoaded,
  DeclineAgencyOfferContract,
  DeclineContract,
  DeclineDateExtension,
  DeleteAgencyOffer,
  DeleteOffer,
  DocumentsNeededContract,
  EndContract,
  FailedToDeleteAgencyOffer,
  FailedToDeleteOffer,
  FailedToLoadList,
  FailedToLoadOfferContracts,
  InviteAgenciesToPackageOffer,
  ListLoaded,
  LoadAgenciesToInvite,
  LoadAgencyOfferWithContracts,
  LoadContract,
  LoadList,
  LoadListBO,
  LoadOffer,
  LoadOfferContracts,
  LoadPackageOfferWithContracts,
  LoadPermanentOfferWithContracts,
  LoadPublicProfile,
  LoadStaffies,
  MarkAgencyOfferContractAsDocumentsNeeded,
  PauseContract,
  ProposeNewPriceForPackageContract,
  ProposeStartDate,
  PublicProfileLoaded,
  ReopenAgencyOffer,
  ReopenOffer,
  ResumeContract,
  SelectedOfferContractsLoaded,
  StaffiesLoaded,
  SuccessfulDeletedAgencyOffer,
  SuccessfulDeletedOffer
} from './requests-overview.actions';
import { OfferActions } from '../constants/offer-actions';
import { ContractActions } from '../constants/contract-actions';
import {
  selectActingAs,
  selectFiltersForRequest,
  selectSelectedEntityUuid
} from './requests-overview.selectors';
import { ApiRootLinkRel } from '@libs/shared/linkrels/api-root.linkrel';
import { role, UserRoles } from '@libs/shared/models/roles.enum';
import {
  getReqUrl,
  getReqUrlWithoutQueryParameters
} from '@libs/shared/helpers/get-url-from-resource';
import { RequestOverviewLinkRel } from '@libs/shared/linkrels/request-overview.linkrel';
import {
  getEmbeddedResource,
  getUrl,
  hasLink
} from '@libs/shared/bms-common/rest/resource.utils';
import { getFilteredApiRoot } from '@libs/shared/bms-common/api-root/api-root.selectors';
import { EntityTypes } from '@libs/shared/models/entity-types.enum';
import { buildQueryParams } from '@libs/request-overview-common/state/helper';
import { isPermanent, OfferType } from '@libs/shared/models/offer.model';
import { isNil } from 'lodash-es';
import {
  Header,
  HeaderProviderService
} from '@libs/auth/services/header-provider.service';
import { DURATION_1000_MILLISECONDS } from '@libs/shared/constants/duration.constants';
import { ErrorMessageService } from '@libs/common-ui/services/error-message/error-message.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class RequestsOverviewEffects {
  public loadList$ = createEffect(() =>
    this.actions.pipe(
      ofType(LoadList, LoadListBO),
      withLatestFrom(this.store.select(selectFiltersForRequest)),
      switchMap(([action, filters]) => {
        if (action.type === LoadList.type) {
          const wrappedRole = role(action.role);
          const linkRel =
            wrappedRole.isTechnician() || wrappedRole.isAgency()
              ? this.getOffersLinkRelForNonAviationCompanies(filters)
              : this.getOffersLinkRelForAviationCompanies(filters);
          return this.loadListInternal({
            linkRel,
            filters: { ...filters, showMineOnly: 'true' }
          });
        }
        if (!isNil(filters.admin)) {
          return this.loadListInternal({
            linkRel: this.getOffersLinkRelForNonAviationCompanies(filters),
            filters
          });
        }
        return EMPTY;
      })
    )
  );

  public getSelectedOfferContracts$ = createEffect(() =>
    this.actions.pipe(
      ofType(LoadOfferContracts),
      withLatestFrom(
        this.store.select(selectSelectedEntityUuid),
        this.store.select(selectActingAs)
      ),
      switchMap(([action, selectedEntityUuid, actingAs]) => {
        const reqUrl = action.getContractsUrl?.split('?')[0];
        const uuid = !isNil(actingAs)
          ? !isNil(selectedEntityUuid)
            ? selectedEntityUuid
            : action.userUuid
          : action.userUuid;

        return this.httpService
          .get(reqUrl, { params: buildQueryParams(actingAs, uuid) })
          .pipe(
            map(response => SelectedOfferContractsLoaded({ response })),
            catchError(response => {
              // TODO(SN-986): Add err message
              this.errorMessageService.handleErrorResponseWithoutWarningTheUser(
                response
              );
              return of(FailedToLoadOfferContracts());
            })
          );
      })
    )
  );

  private getOffersLinkRelForNonAviationCompanies(filters: any): string {
    switch (filters.offerType) {
      case OfferType.FIXED_PRICE:
        return ApiRootLinkRel.PackageOffers;
      case OfferType.AGENCY:
        return ApiRootLinkRel.AgencyJobOpenings;
      case OfferType.PERMANENT:
        return ApiRootLinkRel.PermanentOffers;
      default:
        return ApiRootLinkRel.SearchJobOpenings;
    }
  }

  private getOffersLinkRelForAviationCompanies(filters: any): string {
    switch (filters.offerType) {
      case OfferType.FIXED_PRICE:
        return ApiRootLinkRel.PackageOffers;
      case OfferType.PERMANENT:
        return ApiRootLinkRel.PermanentOffers;
      default:
        return ApiRootLinkRel.Offers;
    }
  }

  public proposeStartDateForContract$ = createEffect(() =>
    this.actions.pipe(
      ofType(ProposeStartDate),
      withLatestFrom(this.store.select(selectActingAs)),
      switchMap(([action, actingAs]) => {
        const request = {
          url: getReqUrl(
            action.contract,
            RequestOverviewLinkRel.ProposeContractStartDate
          ),
          body: {
            startDate: action.startDate
          },
          successMessage: this.translateService.instant('SYSTEM.INFO.PROPOSED_START_DATE')
        };
        return this.patchContract(action.contract, request, actingAs);
      })
    )
  );

  public acceptStartDateForContract$ = createEffect(() =>
    this.actions.pipe(
      ofType(AcceptStartDate),
      withLatestFrom(this.store.select(selectActingAs)),
      switchMap(([action, actingAs]) => {
        const request = {
          url: getReqUrl(
            action.contract,
            RequestOverviewLinkRel.AcceptContractStartDate
          ),
          body: {},
          successMessage: this.translateService.instant('SYSTEM.INFO.ACCEPTED_START_DATE')
        };
        return this.patchContract(action.contract, request, actingAs);
      })
    )
  );

  public closeOffer$ = createEffect(() =>
    this.actions.pipe(
      ofType(CloseOffer),
      withLatestFrom(this.store.select(selectActingAs)),
      switchMap(([action, actingAs]) => {
        const request = {
          url: getReqUrl(action.offer, RequestOverviewLinkRel.CloseOffer),
          body: { action: OfferActions.Close },
          successMessage: this.translateService.instant('SYSTEM.INFO.CLOSED_JO')
      };
        if (isPermanent(action.offer)) {
          return this.patchPermanentOffer(action, request, actingAs);
        } else {
          return this.patchOffer(action, request, actingAs);
        }
      })
    )
  );

  public reopenOffer$ = createEffect(() =>
    this.actions.pipe(
      ofType(ReopenOffer),
      withLatestFrom(this.store.select(selectActingAs)),
      switchMap(([action, actingAs]) => {
        const request = {
          url: getReqUrl(action.offer, RequestOverviewLinkRel.ReopenOffer),
          body: { action: OfferActions.Reopen },
          successMessage:  this.translateService.instant('SYSTEM.INFO.REOPENED_JO')
        };
        if (isPermanent(action.offer)) {
          return this.patchPermanentOffer(action, request, actingAs);
        } else {
          return this.patchOffer(action, request, actingAs);
        }
      })
    )
  );

  public approveOffer$ = createEffect(() =>
    this.actions.pipe(
      ofType(ApproveOffer),
      withLatestFrom(this.store.select(selectActingAs)),
      switchMap(([action, actingAs]) => {
        const request = {
          url: getReqUrl(action.offer, RequestOverviewLinkRel.ApproveOffer),
          body: { action: OfferActions.Approve }
        };
        if (isPermanent(action.offer)) {
          return this.patchPermanentOffer(action, request, actingAs);
        } else {
          return this.patchOffer(action, request, actingAs);
        }
      })
    )
  );

  public acceptContract$ = createEffect(() =>
    this.actions.pipe(
      ofType(AcceptContract),
      withLatestFrom(this.store.select(selectActingAs)),
      switchMap(([action, actingAs]) =>
        this.acceptContractInternal(
          action,
          action.agencyUuid,
          actingAs,
          action.labourRegulationsAcknowledgment
        )
      )
    )
  );

  public acceptPackageContractInvitation$ = createEffect(() =>
    this.actions.pipe(
      ofType(AgencyAcceptsPackageContractInvitation),
      withLatestFrom(this.store.select(selectActingAs)),
      switchMap(([action, actingAs]) =>
        this.acceptContractInternal(action, action.agencyUuid, actingAs)
      )
    )
  );

  public agencyEditsPackageContract$ = createEffect(() =>
    this.actions.pipe(
      ofType(AgencyEditsPackageContract),
      withLatestFrom(this.store.select(selectActingAs)),
      switchMap(([action, actingAs]) => {
        const {
          startDate,
          endDate,
          agencyOfferedPrice,
          agencyNotes,
          priceEstimate
        } = action.payload;
        const request = {
          url: getReqUrl(action.contract, RequestOverviewLinkRel.Edit),
          body: {
            agencyUuid: action.agencyUuid,
            startDate,
            endDate,
            agencyOfferedPrice,
            agencyNotes,
            priceEstimate
          },
          successMessage: 'You have successfully edited the contract.'
        };
        return this.patchContract(
          action.contract,
          request,
          actingAs,
          response => {
            this.errorMessageService.handleErrorResponseWithCustomerSupportModal(
              response,
              'The contract cannot be edited'
            );
          }
        );
      })
    )
  );

  public agencyAcceptContractAsTechnician$ = createEffect(() =>
    this.actions.pipe(
      ofType(AgencyAcceptContractAsTechnician),
      switchMap(action =>
        this.getFacilityProfileUuid().pipe(
          switchMap(uuid =>
            this.acceptContractInternal(action, uuid, UserRoles.ROLE_TECHNICIAN)
          ),
          catchError(response => {
            this.errorMessageService.handleErrorResponse(response);
            return EMPTY;
          })
        )
      )
    )
  );

  private getFacilityProfileUuid(): Observable<string> {
    return this.httpService
      .get(getUrl(this.apiRoot, ApiRootLinkRel.MyFacility))
      .pipe(map((facilityProfile: any) => facilityProfile.uuid));
  }

  public endContract$ = createEffect(() =>
    this.actions.pipe(
      ofType(EndContract),
      withLatestFrom(this.store.select(selectActingAs)),
      switchMap(([action, actingAs]) => {
        const request = {
          url: getReqUrl(action.contract, RequestOverviewLinkRel.EndContract),
          body: { action: ContractActions.End, endDate: action.endDate },
          successMessage: this.translateService.instant('SYSTEM.INFO.ENDED_CONTRACT')
        };
        return this.patchContract(action.contract, request, actingAs);
      })
    )
  );

  public deleteOffer$ = createEffect(() =>
    this.actions.pipe(
      ofType(DeleteOffer),
      switchMap(action => {
        return this.httpService.delete(action.url).pipe(
          map((response: any) => {
            this.toastService.success(response.message);
            return SuccessfulDeletedOffer();
          }),
          catchError(response => {
            this.errorMessageService.handleErrorResponse(response);
            return of(FailedToDeleteOffer());
          })
        );
      })
    )
  );

  public loadPublicProfile$ = createEffect(() =>
    this.actions.pipe(
      ofType(LoadPublicProfile),
      filter(action =>
        RequestsOverviewEffects.hasProfileLink(
          action.contract,
          action.entityType
        )
      ),
      switchMap(action => {
        const profileLink = RequestsOverviewEffects.getProfileLink(
          action.contract,
          action.entityType
        );
        return this.httpService.get(profileLink).pipe(
          map(profile => {
            return PublicProfileLoaded({
              profile,
              contract: action.contract,
              entityType: action.entityType
            });
          }),
          catchError(response => {
            this.errorMessageService.handleErrorResponseWithCustomMessage(
              response,
              this.translateService.instant('SYSTEM.ERROR.GET_PUBLIC_PROFILE')
            );
            return EMPTY;
          })
        );
      })
    )
  );

  public declineContract$ = createEffect(() =>
    this.actions.pipe(
      ofType(DeclineContract),
      withLatestFrom(this.store.select(selectActingAs)),
      switchMap(([action, actingAs]) => {
        const reqBody: any = { action: ContractActions.Decline };
        if (!isNil(action.reasons)) {
          reqBody.reasons = action.reasons;
        }
        const request = {
          url: getReqUrl(
            action.contract,
            RequestOverviewLinkRel.DeclineContract
          ),
          body: reqBody,
          successMessage: this.translateService.instant('SYSTEM.INFO.DECLINED_CONTRACT')
        };
        return this.patchContract(
          action.contract,
          request,
          actingAs,
          response => {
            this.errorMessageService.handleErrorResponseWithCustomerSupportModal(
              response,
              'The contract cannot be declined'
            );
          }
        );
      })
    )
  );

  public documentsNeededContract$ = createEffect(() =>
    this.actions.pipe(
      ofType(DocumentsNeededContract),
      withLatestFrom(this.store.select(selectActingAs)),
      switchMap(([action, actingAs]) => {
        const request = {
          url: getReqUrl(
            action.contract,
            RequestOverviewLinkRel.MarkAsDocumentsNeeded
          ),
          body: {
            action: ContractActions.DocumentsNeeded,
            neededDocumentsText: action.neededDocuments
          },
          successMessage: this.translateService.instant('SYSTEM.INFO.DOCUMENTS_NEEDED_CONTRACT')
        };
        return this.patchContract(
          action.contract,
          request,
          actingAs,
          response => {
            this.errorMessageService.handleErrorResponseWithCustomerSupportModal(
              response,
              'The contract cannot be marked as documents needed'
            );
          }
        );
      })
    )
  );

  public loadContract$ = createEffect(() =>
    this.actions.pipe(
      ofType(LoadContract),
      withLatestFrom(
        this.store.select(selectSelectedEntityUuid),
        this.store.select(selectActingAs)
      ),
      switchMap(([action, uuid, actingAs]) => {
        const reqUrl = getReqUrlWithoutQueryParameters(
          action.contract,
          RequestOverviewLinkRel.Self
        );
        if (isNil(reqUrl)) {
          return;
        }
        return this.httpService
          .get(reqUrl, { params: buildQueryParams(actingAs, uuid) })
          .pipe(
            map(response => ContractLoaded({ contract: response })),
            catchError(response => {
              this.errorMessageService.handleErrorResponse(response);
              return EMPTY;
            })
          );
      })
    )
  );

  public loadStaffiesList$ = createEffect(() =>
    this.actions.pipe(
      ofType(LoadStaffies),
      withLatestFrom(this.store.pipe(getFilteredApiRoot)),
      switchMap(([action, apiRoot]) =>
        this.httpService
          .get(
            getReqUrl(apiRoot, ApiRootLinkRel.GetStaffies) + action.offerUuid
          )
          .pipe(
            map(responseList => StaffiesLoaded({ responseList })),
            catchError(response => {
              this.errorMessageService.handleErrorResponse(response);
              return EMPTY;
            })
          )
      )
    )
  );

  public addStaffiesToOffer$ = createEffect(() =>
    this.actions.pipe(
      ofType(AddStaffiesToOffer),
      throttleTime(DURATION_1000_MILLISECONDS),
      switchMap((action: ReturnType<typeof AddStaffiesToOffer>) => {
        const requestUrl = getReqUrl(
          action.offer,
          RequestOverviewLinkRel.AddStaffies
        );
        const requestBody = {
          technicians: action.selectedTechnicians.map(
            technician => technician.userUuid
          )
        };

        return this.httpService.post(requestUrl, requestBody).pipe(
          tap(() => {
            this.toastService.success(
              this.translateService.instant('SYSTEM.INFO.SUCCESSFULLY_ADDED_TECHNICIANS')
            );
          }),
          map(() =>
            LoadOfferContracts({
              getContractsUrl: getReqUrl(
                action.offer,
                RequestOverviewLinkRel.GetContracts
              )
            })
          ),
          catchError(response => {
            this.errorMessageService.handleErrorResponse(response);
            return EMPTY;
          })
        );
      })
    )
  );

  public loadAgenciesList$ = createEffect(() =>
    this.actions.pipe(
      ofType(LoadAgenciesToInvite),
      switchMap(action =>
        this.httpService
          .get(getUrl(action.offer, RequestOverviewLinkRel.GetAgenciesToInvite))
          .pipe(
            map((response: any) => {
              return AgenciesLoaded({
                responseList: getEmbeddedResource(
                  response,
                  'packageOfferAgencyOutDtoList'
                )
              });
            }),
            catchError(response => {
              this.errorMessageService.handleErrorResponse(response);
              return EMPTY;
            })
          )
      )
    )
  );

  public inviteAgencies$ = createEffect(() =>
    this.actions.pipe(
      ofType(InviteAgenciesToPackageOffer),
      throttleTime(DURATION_1000_MILLISECONDS),
      switchMap(action =>
        this.httpService
          .post(getUrl(action.offer, RequestOverviewLinkRel.InviteAgencies), {
            list: action.selectedAgencies.map(agency => agency.uuid)
          })
          .pipe(
            map((response: any) => {
              this.toastService.success('Successfully invited agencies.');
              return LoadPackageOfferWithContracts({
                refNumber: action.offer.refNumber
              });
            }),
            catchError(response => {
              this.errorMessageService.handleErrorResponse(response);
              return EMPTY;
            })
          )
      )
    )
  );

  public proposeNewPriceForPackageContract$ = createEffect(() =>
    this.actions.pipe(
      ofType(ProposeNewPriceForPackageContract),
      withLatestFrom(this.store.select(selectActingAs)),
      switchMap(([action, actingAs]) => {
        const request = {
          url: getReqUrl(action.contract, RequestOverviewLinkRel.ProposePrice),
          body: {
            price: action.newPrice
          },
          successMessage: 'You have successfully proposed a new price.'
        };
        return this.patchContract(
          action.contract,
          request,
          actingAs,
          response => {
            this.errorMessageService.handleErrorResponseWithCustomerSupportModal(
              response,
              'The new price could not be proposed'
            );
          }
        );
      })
    )
  );

  public acceptNewPriceForPackageContract$ = createEffect(() =>
    this.actions.pipe(
      ofType(AcceptNewPriceForPackageContract),
      withLatestFrom(this.store.select(selectActingAs)),
      switchMap(([action, actingAs]) => {
        const request = {
          url: getReqUrl(action.contract, RequestOverviewLinkRel.AcceptPrice),
          body: {},
          successMessage: 'You have successfully accepted the price.'
        };
        return this.patchContract(
          action.contract,
          request,
          actingAs,
          response => {
            this.errorMessageService.handleErrorResponseWithCustomerSupportModal(
              response,
              'The new price could not be accepted'
            );
          }
        );
      })
    )
  );

  public acceptDateExtension$ = createEffect(() =>
    this.actions.pipe(
      ofType(AcceptDateExtension),
      withLatestFrom(this.store.select(selectActingAs)),
      switchMap(([action, actingAs]) => {
        const request = {
          url: getReqUrl(
            action.contract,
            RequestOverviewLinkRel.AcceptDateExtension
          ),
          body: {},
          successMessage: 'You have successfully accepted the new date'
        };
        return this.patchContract(
          action.contract,
          request,
          actingAs,
          response => {
            this.errorMessageService.handleErrorResponseWithCustomerSupportModal(
              response,
              'The new date could not be accepted'
            );
          }
        );
      })
    )
  );

  public declineDateExtension$ = createEffect(() =>
    this.actions.pipe(
      ofType(DeclineDateExtension),
      withLatestFrom(this.store.select(selectActingAs)),
      switchMap(([action, actingAs]) => {
        const request = {
          url: getReqUrl(
            action.contract,
            RequestOverviewLinkRel.DeclineDateExtension
          ),
          body: {},
          successMessage: 'You have successfully declined the new date'
        };
        return this.patchContract(
          action.contract,
          request,
          actingAs,
          response => {
            this.errorMessageService.handleErrorResponseWithCustomerSupportModal(
              response,
              'The action has failed'
            );
          }
        );
      })
    )
  );

  private static hasProfileLink(contract, entityType): boolean {
    const linkRel = this.getProfileLinkRel(entityType);
    return hasLink(contract, linkRel);
  }

  private static getProfileLink(contract, entityType): string {
    const linkRel = this.getProfileLinkRel(entityType);
    return getUrl(contract, linkRel);
  }

  private static getProfileLinkRel(entityType): string {
    switch (entityType) {
      case EntityTypes.TECHNICIAN:
        return RequestOverviewLinkRel.GetTechnician;
      case EntityTypes.MRO:
        return RequestOverviewLinkRel.GetMRO;
      case EntityTypes.AGENCY:
        return RequestOverviewLinkRel.GetAgency;
      default:
        return null;
    }
  }

  private loadListInternal({ linkRel, filters }): Observable<any> {
    const params = this.createParamsForLoadListAction(filters);
    return this.httpService.get(getUrl(this.apiRoot, linkRel), { params }).pipe(
      map(response => ListLoaded({ response })),
      catchError(response => {
        // TODO(SN-986): add err message
        this.errorMessageService.handleErrorResponseWithoutWarningTheUser(
          response
        );
        return of(FailedToLoadList());
      })
    );
  }

  private createParamsForLoadListAction(filters: any): HttpParams {
    return new HttpParams({
      fromObject: {
        pageSize: '24',
        ...filters
      }
    });
  }

  private acceptContractInternal(
    action,
    agencyUuid,
    actingAs,
    labourRegulationsAcknowledgment?: boolean
  ): Observable<any> {
    const reqBody: any = { action: ContractActions.Accept };
    if (!isNil(agencyUuid)) {
      reqBody.agencyUuid = agencyUuid;
    }
    if (!isNil(action.contract.notes)) {
      reqBody.notes = action.contract.notes;
    }
    if (!isNil(labourRegulationsAcknowledgment)) {
      reqBody.labourRegulationsAcknowledgment = labourRegulationsAcknowledgment;
    }
    if (!isNil(action.payload)) {
      const {
        startDate,
        endDate,
        agencyOfferedPrice,
        agencyNotes,
        priceEstimate
      } = action.payload;
      reqBody.startDate = startDate;
      reqBody.endDate = endDate;
      reqBody.agencyOfferedPrice = agencyOfferedPrice;
      reqBody.agencyNotes = agencyNotes;
      reqBody.priceEstimate = priceEstimate;
    }
    const request = {
      url: getReqUrl(action.contract, RequestOverviewLinkRel.AcceptContract),
      body: reqBody,
      successMessage: this.translateService.instant('SYSTEM.INFO.ACCEPTED_CONTRACT')
    };
    return this.patchContract(action.contract, request, actingAs, response => {
      this.errorMessageService.handleErrorResponseWithCustomerSupportModal(
        response,
        'The contract cannot be accepted'
      );
    });
  }

  private patchContract(
    contract,
    req: { url; body; successMessage },
    actingAs,
    onErrorCallback = (response: HttpErrorResponse): void => {
      this.errorMessageService.handleErrorResponse(response);
    }
  ): Observable<any> {
    if (isNil(req.url)) {
      return;
    }
    if (!isNil(actingAs)) {
      req.body.actingAs = actingAs;
    }
    const header: Header = this.headerProviderService.apiMediaTypeJsonV2;
    return this.httpService
      .patch(req.url, req.body, {
        headers: new HttpHeaders().set(header.name, header.value)
      })
      .pipe(
        tap(() => {
          this.toastService.success(req.successMessage);
        }),
        map(() => LoadContract({ contract })),
        catchError(response => {
          onErrorCallback(response);
          return EMPTY;
        })
      );
  }

  private patchOffer(
    { offer },
    req: { url; body; successMessage? },
    actingAs
  ): Observable<any> {
    if (isNil(req.url)) {
      return;
    }
    if (!isNil(actingAs)) {
      req.body.actingAs = actingAs;
    }
    return this.httpService.patch<any>(req.url, req.body).pipe(
      tap(response => {
        this.toastService.success(
          !isNil(req.successMessage) ? req.successMessage : response.message
        );
      }),
      map(() => LoadOffer({ offer })),
      catchError(response => {
        this.errorMessageService.handleErrorResponse(response);
        return EMPTY;
      })
    );
  }

  private patchPermanentOffer(
    action,
    req: { url; body; successMessage? },
    actingAs
  ): Observable<any> {
    if (isNil(req.url)) {
      return;
    }
    if (!isNil(actingAs)) {
      req.body.actingAs = actingAs;
    }
    const refNumber: string = action.offer.refNumber;
    return this.httpService.patch<any>(req.url, req.body).pipe(
      tap(response => {
        this.toastService.success(
          !isNil(req.successMessage) ? req.successMessage : response.message
        );
      }),
      map(() => LoadPermanentOfferWithContracts({ refNumber })),
      catchError(err => {
        this.toastService.fail(err.error.message);
        return EMPTY;
      })
    );
  }

  public closeAgencyOffer$ = createEffect(() =>
    this.actions.pipe(
      ofType(CloseAgencyOffer),
      switchMap(action => {
        return this.patchAgencyOffer(
          action.refNumber,
          action.url,
          this.translateService.instant('SYSTEM.INFO.CLOSED_JO'),
          action.isBackoffice
        );
      })
    )
  );

  public reopenAgencyOffer$ = createEffect(() =>
    this.actions.pipe(
      ofType(ReopenAgencyOffer),
      switchMap(action => {
        return this.patchAgencyOffer(
          action.refNumber,
          action.url,
          this.translateService.instant('SYSTEM.INFO.REOPENED_JO'),
          action.isBackoffice
        );
      })
    )
  );

  public deleteAgencyOffer$ = createEffect(() =>
    this.actions.pipe(
      ofType(DeleteAgencyOffer),
      switchMap(action => {
        return this.httpService.delete(action.url).pipe(
          map((response: any) => {
            this.toastService.success(response.message);
            return action.isBackoffice
              ? LoadListBO()
              : SuccessfulDeletedAgencyOffer();
          }),
          catchError(response => {
            this.errorMessageService.handleErrorResponse(response);
            return of(FailedToDeleteAgencyOffer());
          })
        );
      })
    )
  );

  private patchAgencyOffer(
    refNumber: string,
    url: string,
    successMessage: string,
    isBackoffice: boolean
  ): Observable<any> {
    return this.httpService.patch<any>(url, {}).pipe(
      tap(response => {
        this.toastService.success(
          !isNil(successMessage) ? successMessage : response.message
        );
      }),
      map(() =>
        isBackoffice
          ? LoadListBO()
          : LoadAgencyOfferWithContracts({ refNumber })
      ),
      catchError(response => {
        this.errorMessageService.handleErrorResponse(response);
        return EMPTY;
      })
    );
  }

  public acceptAgencyOfferContract$ = createEffect(() =>
    this.actions.pipe(
      ofType(AcceptAgencyOfferContract),
      switchMap(action => {
        return this.patchAgencyOfferContract(
          action.refNumber,
          action.url,
          {},
          this.translateService.instant('SYSTEM.INFO.ACCEPTED_CONTRACT'),
          action.isBackoffice,
          response => {
            this.errorMessageService.handleErrorResponseWithCustomerSupportModal(
              response,
              'The contract cannot be accepted'
            );
          }
        );
      })
    )
  );

  public declineAgencyOfferContract$ = createEffect(() =>
    this.actions.pipe(
      ofType(DeclineAgencyOfferContract),
      switchMap(action => {
        return this.patchAgencyOfferContract(
          action.refNumber,
          action.url,
          { text: action.reason },
          this.translateService.instant('SYSTEM.INFO.DECLINED_CONTRACT'),
          action.isBackoffice,
          response => {
            this.errorMessageService.handleErrorResponseWithCustomerSupportModal(
              response,
              'The contract cannot be declined'
            );
          }
        );
      })
    )
  );

  public markAgencyOfferContractAsDocumentsNeeded$ = createEffect(() =>
    this.actions.pipe(
      ofType(MarkAgencyOfferContractAsDocumentsNeeded),
      switchMap(action => {
        return this.patchAgencyOfferContract(
          action.refNumber,
          action.url,
          { text: action.explanation },
          this.translateService.instant('SYSTEM.INFO.DOCUMENTS_NEEDED_CONTRACT'),
          action.isBackoffice,
          response => {
            this.errorMessageService.handleErrorResponseWithCustomerSupportModal(
              response,
              'The contract cannot be marked as documents needed'
            );
          }
        );
      })
    )
  );

  public pauseContract$ = createEffect(() =>
    this.actions.pipe(
      ofType(PauseContract),
      switchMap(action => {
        const request = {
          url: getReqUrl(action.contract, RequestOverviewLinkRel.PauseContract),
          body: {
            fromDate: action.fromDate,
            toDate: action.toDate
          },
          successMessage: 'You have successfully paused the contract'
        };
        return this.patchContract(action.contract, request, null, response => {
          this.errorMessageService.handleErrorResponseWithCustomerSupportModal(
            response,
            'The action has failed'
          );
        });
      })
    )
  );

  public resumeContract$ = createEffect(() =>
    this.actions.pipe(
      ofType(ResumeContract),
      switchMap(action => {
        const request = {
          url: getReqUrl(
            action.contract,
            RequestOverviewLinkRel.ResumeContract
          ),
          body: {},
          successMessage: 'You have successfully resumed the contract'
        };
        return this.patchContract(action.contract, request, null, response => {
          this.errorMessageService.handleErrorResponseWithCustomerSupportModal(
            response,
            'The action has failed'
          );
        });
      })
    )
  );

  private patchAgencyOfferContract(
    refNumber: string,
    url: string,
    body: any,
    successMessage: string,
    isBackoffice: boolean,
    onErrorCallback = (response: HttpErrorResponse): void => {
      this.errorMessageService.handleErrorResponse(response);
    }
  ): Observable<any> {
    return this.httpService.patch(url, body).pipe(
      tap(() => {
        this.toastService.success(successMessage);
      }),
      map(() =>
        isBackoffice
          ? LoadListBO()
          : LoadAgencyOfferWithContracts({ refNumber })
      ),
      catchError(response => {
        onErrorCallback(response);
        return EMPTY;
      })
    );
  }

  private apiRoot: any = null;

  constructor(
    private readonly store: Store<any>,
    private readonly actions: Actions,
    private readonly toastService: ToastMessageService,
    private readonly httpService: HttpClient,
    private readonly headerProviderService: HeaderProviderService,
    private readonly errorMessageService: ErrorMessageService,
    private readonly translateService: TranslateService
  ) {
    this.store
      .pipe(getFilteredApiRoot)
      .subscribe(apiRoot => (this.apiRoot = apiRoot));
  }
}
