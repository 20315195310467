import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit
} from '@angular/core';
import { Store } from '@ngrx/store';
import { selectProfileStatus } from '../../state/user-profile.selectors';
import { profileStatusConstants } from '@libs/shared/constants/statuses.constants';

@Component({
  selector: 'staffnow-profile-status',
  templateUrl: './profile-status.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./profile-status.component.scss']
})
export class ProfileStatusComponent implements OnInit {
  public profileStatus = null;
  private profileSubscription = null;

  constructor(private store: Store<any>, private cdRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.profileSubscription = this.store
      .select(selectProfileStatus)
      .subscribe(profileStatus => {
        this.profileStatus = profileStatus;
        this.cdRef.detectChanges();
      });
  }

  handleStatusColoring() {
    switch (this.profileStatus) {
      case profileStatusConstants.verified:
        return 'text-success';
      case profileStatusConstants.pending:
        return 'text-warning';
      case profileStatusConstants.inReview:
        return 'text-primary';
      case profileStatusConstants.incomplete:
        return 'text-secondary';
      default:
        return '';
    }
  }
}
