import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap, throttleTime, withLatestFrom} from 'rxjs/operators';
import {EMPTY, of} from 'rxjs';

import {
  CompanySearch,
  FailedToGetSlideshowPictures,
  FailedToRegUser,
  GetSlideshowPictures,
  LoadLocationList,
  LoadSharedOffer,
  MroRegistrationFinished,
  RegisterMro,
  RegisterMroFailed,
  RegisterMroSuccessful,
  RegistrationActionTypes,
  SharedOfferLoaded,
  SlideshowPicturesLoaded,
  SuccessRegUser,
  UploadMroLogo,
  UploadMroLogoFailed
} from './registration.actions';
import {RegistrationService} from '../services/registration.service';
import {getEmbeddedResource, getUrl} from '@libs/shared/bms-common/rest/resource.utils';
import {ClearAsyncErrorAction, SetAsyncErrorAction} from 'ngrx-forms';
import {Store} from "@ngrx/store";
import {getStaticEnvironment} from "@libs/shared/bms-common/environment/environment.selector";
import {UntilDestroy} from '@ngneat/until-destroy';
import {LocationListLoaded} from "@libs/shared/bms-common/environment/environment.actions";
import {ListDtoWrapper} from "@libs/shared/models/responses/list-wrapper.model";
import {LocationPO} from "@libs/shared/models/location.model";
import {DURATION_1000_MILLISECONDS} from "@libs/shared/constants/duration.constants";
import {ErrorMessageService} from "@libs/common-ui/services/error-message/error-message.service";

@UntilDestroy()
@Injectable()
export class RegistrationEffects {

  public infoEmail: string;

  public submitFormTechnician$ = createEffect(() =>
    this.actions.pipe(
      ofType(RegistrationActionTypes.TechnicianRegistrationFormSubmitted),
      throttleTime(DURATION_1000_MILLISECONDS),
      switchMap((action: any) =>
        this.registrationService.registerTechnician(action.grecaptchaToken, action.payload)
          .pipe(
            map(() => new SuccessRegUser()),
            catchError(response => {
              this.errorMessageService.handleErrorResponseWithoutWarningTheUser(response);
              return of(new FailedToRegUser(response));
            })
          )
      )
    )
  );

  public getSlideshowPictures$ = createEffect(() =>
    this.actions.pipe(
      ofType(RegistrationActionTypes.GetSlideshowPictures),
      switchMap((action: GetSlideshowPictures) => {
        return this.httpService
          .get(getUrl(action.payload, 'getAllPictureHomePL'))
          .pipe(
            map(
              (response: any) =>
                new SlideshowPicturesLoaded(
                  getEmbeddedResource(response, 'pictures')
                )
            ),
            catchError(response => {
              this.errorMessageService.handleErrorResponseWithoutWarningTheUser(response);
              return of(new FailedToGetSlideshowPictures());
            })
          );
      })
    )
  );

  public loadSharedOffer$ = createEffect(() =>
    this.actions.pipe(
      ofType(LoadSharedOffer),
      switchMap(action =>
        this.registrationService
          .getSharedOfferData(action.refNumber, action.linkRel)
          .pipe(
            map(offer => SharedOfferLoaded({offer: offer as any})),
            catchError(response => {
              this.errorMessageService.handleErrorResponse(response);
              return EMPTY;
            })
          )
      )
    )
  );

  public RegisterMroCompany$ = createEffect(() =>
    this.actions.pipe(
      ofType(RegisterMro),
      throttleTime(DURATION_1000_MILLISECONDS),
      switchMap((action) =>
        this.registrationService.registerMro(action.grecaptchaToken, action.payload)
          .pipe(
            map(response => RegisterMroSuccessful({companyUuid: response.text})),
            catchError(response => {
              this.errorMessageService.handleErrorResponseWithoutWarningTheUser(response);
              return of(RegisterMroFailed());
            })
          )
      )
    )
  );

  public UploadMroLogo$ = createEffect(() =>
    this.actions.pipe(
      ofType(UploadMroLogo),
      throttleTime(DURATION_1000_MILLISECONDS),
      switchMap((action) =>
        this.registrationService
          .uploadMroFacilityLogo(action.facilityUuid, action.file)
          .pipe(
            map(response => MroRegistrationFinished()),
            catchError(response => {
              this.errorMessageService.handleErrorResponseWithoutWarningTheUser(response);
              return of(UploadMroLogoFailed());
            })
          )
      )
    )
  );

  public SearchForCompany$ = createEffect(() =>
    this.actions.pipe(
      ofType(CompanySearch),
      throttleTime(DURATION_1000_MILLISECONDS),
      switchMap((action) => {
        return this.registrationService
          .searchCompany(action.companyName)
          .pipe(
            map(_ => new ClearAsyncErrorAction(action.controlId, 'mroExists')),
            catchError(_ => [
              new SetAsyncErrorAction(action.controlId, 'mroExists', {infoEmail: this.infoEmail})
            ])
          );
      })
    )
  );

  loadLocations$ = createEffect(() =>
    this.actions.pipe(
      ofType(LoadLocationList),
      withLatestFrom(this.store.pipe(getStaticEnvironment)),
      switchMap(([action, environment]) => {
          return this.httpService.get(getUrl(environment, 'getAvailableLocations'))
            .pipe(
              map((response: ListDtoWrapper<LocationPO>) => LocationListLoaded({locations: response.list})),
              catchError(response => {
                this.errorMessageService.handleErrorResponse(response);
                return EMPTY;
              })
            );
        }
      )
    )
  );

  constructor(
    private httpService: HttpClient,
    private actions: Actions,
    private registrationService: RegistrationService,
    private errorMessageService: ErrorMessageService,
    private store: Store<any>
  ) {
    this.store
      .pipe(getStaticEnvironment)
      .subscribe(env => {
        this.infoEmail = env.brandConfig.infoEmail;
      });
  }
}
