import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';

import { TranslationModule } from '@libs/translation/translation.module';

import { StaffFiltersComponent } from './staff-filters.component';
import { TextFilterComponent } from './components/text-filter/text-filter.component';
import { AbstractFilterComponent } from './components/abstract-filter/abstract-filter.component';
import { DateFilterComponent } from './components/date-filter/date-filter.component';
import { EnumFilterComponent } from './components/enum-filter/enum-filter.component';
import { AmeTypeLicenseComponent } from './components/ame-type-license-filter/ame-type-license-filter.component';
import { NumberFilterComponent } from './components/number-filter/number-filter.component';
import { DateRangeFilterComponent } from './components/date-range-filter/date-range-filter.component';
import { BooleanFilterComponent } from './components/boolean-filter/boolean-filter.component';
import {CommonUiModule} from "@libs/common-ui/common-ui.module";

@NgModule({
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule,
    TranslationModule,
    CommonUiModule
  ],
  declarations: [
    StaffFiltersComponent,
    TextFilterComponent,
    AbstractFilterComponent,
    DateFilterComponent,
    EnumFilterComponent,
    NumberFilterComponent,
    DateRangeFilterComponent,
    AmeTypeLicenseComponent,
    BooleanFilterComponent
  ],
  exports: [
    StaffFiltersComponent,
    TextFilterComponent,
    AbstractFilterComponent,
    DateFilterComponent,
    EnumFilterComponent,
    NumberFilterComponent,
    DateRangeFilterComponent,
    AmeTypeLicenseComponent,
    BooleanFilterComponent
  ]
})
export class StaffFiltersModule {}
