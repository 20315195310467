import { select } from '@ngrx/store';
import { pipe } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { EnvironmentState } from '@libs/shared/bms-common/environment/environment.model';
import { getEmbeddedResource, hasEmbeddedResource } from "@libs/shared/bms-common/rest/resource.utils";
import { LocationPO } from "@libs/shared/models/location.model";


enum EnvironmentLinkRel {
    Locations = 'locations'
}

const getDynamicEnvironment = pipe(
  select((state: any) => state.environment as EnvironmentState),
  filter(environment => !!environment)
);
export const getStaticEnvironment = pipe(
  getDynamicEnvironment,
  take(1)
);

export const getBrandName = pipe(
  getStaticEnvironment,
  map(env => env.brandConfig.brandName)
);

export const getLocations = pipe(
    getDynamicEnvironment,
    select(env => {
        return hasEmbeddedResource(env, EnvironmentLinkRel.Locations)
            ?  getEmbeddedResource<LocationPO[]>(env, EnvironmentLinkRel.Locations)
            : [];
    })
);
