import { Resource } from '@libs/shared/bms-common/rest/resource.model';

export interface ApiEnvironmentState extends Resource {
  apiPlatformEndpoint: string;
  apiBackofficeEndpoint: string;
  googleRecaptchaSiteKey: string;
}

export interface EnvironmentParameters {
  production: boolean;
  usaApiUrl: string;
  europeApiUrl: string;
  environmentEndpoint: string;
  googleTrackingId?: string;
  auth: {
    initialUrl: string;
    jwtIssuer: string;
    keycloakLoginUrl: string;
    clientId: string;
  };
  brandConfig: {
    brandName: BrandName;
    privacyPolicy: string;
    termsAndConditions: string;
    infoEmail?: string;
    customerSupportEmail?: string;
    secondCustomerSupportEmail?: string;
    appUrl?: string;
    partnerAppUrl?: string;
  };
  euDashboardsEndpoint: string;
  usaDashboardsEndpoint: string;
}

export interface EnvironmentState extends EnvironmentParameters, ApiEnvironmentState {
  remoteEndPoint: string;
}

export enum BrandName {
  StaffNow = 'StaffNow',
  eLAUNCHNow = 'eLAUNCHNow'
}

export enum AppRegionEnum {
  USA = 'usa',
  EU = 'eu'
}

export function staffNowPrivacyPolicyUrl(): string {
  return 'https://staffnow.aero/wp-content/uploads/Privacy-Policy-EN-ES.pdf';
}

export function staffNowTermsAndConditionsUrl(): string {
  return 'https://staffnow.aero/wp-content/uploads/Terms-and-Conditions-EN-ES.pdf';
}

export function staffNowCustomerSupportEmail(): string {
  return 'support@staffnow.aero';
}

export function staffNowSecondCustomerSupportEmail(): string {
  return 'customersupport@staffnow.aero';
}

export function staffNowInfoEmail(): string {
  return 'info@staffnow.aero';
}

export function eLaunchNowPrivacyPolicyUrl(): string {
  return 'https://documents.app.elaunchnow.com/privacy-policy.pdf';
}

export function eLaunchNowTermsAndConditionsUrl(): string {
  return 'https://documents.app.elaunchnow.com/terms-of-use.pdf';
}

export function eLaunchNowCustomerSupportEmail(): string {
  return 'elaunchnow@staffnow.aero';
}
