<header class="staffnow-header" [ngClass]="brandName">
    <div class="aside-left">
        <div class="notifications-badge">
            <i class="material-icons hamburger" (click)="toggleAsideMenu()">
                menu
            </i>
        </div>
        <div
            class="logo-container"
            [class.color]="isBackofficeUser"
            (click)="redirectToMainView()"
        ></div>
    </div>
    <staffnow-navbar *ngIf="menuItems.length > 0" [menuItems]="menuItems"></staffnow-navbar>
    <div class="aside-right">
        <ng-content select="[region-selector]"></ng-content>
        <div
            *ngIf="canDisplayCopyInvitationButton"
            class="notifications-badge"
            (click)="copyRegistrationLink()"
        >
            <i *ngIf="copyRegistrationLinkClicked" class="material-icons"
                >done</i
            >
            <i *ngIf="!copyRegistrationLinkClicked" class="material-icons"
                >file_copy</i
            >
        </div>
        <div
            *ngIf="hasMailbox"
            class="notifications-badge"
            (click)="gotoMailbox()"
        >
            <i class="material-icons">forum</i>
            <div *ngIf="messagesCount > 0" class="count-badge-wrapper">
                <staffnow-counter-badge
                    [amount]="messagesCount"
                ></staffnow-counter-badge>
            </div>
        </div>
        <div
            class="notifications-badge bell notifications-trigger"
            (click)="toggleNotificationList()"
        >
            <i class="material-icons">notifications</i>
            <div *ngIf="notificationsCount > 0" class="count-badge-wrapper">
                <staffnow-counter-badge
                    [amount]="notificationsCount"
                ></staffnow-counter-badge>
            </div>
            <ng-content select="[notification-list]"></ng-content>
        </div>
        <div
            *ngIf="profilePictureUrl"
            (click)="toggleUserMenu()"
            class="user-avatar"
            [ngStyle]="{
                'background-image': 'url(\'' + profilePictureUrl + '\')'
            }"
        ></div>
        <div
            *ngIf="!profilePictureUrl"
            (click)="toggleUserMenu()"
            class="user-avatar"
        >
            <i class="material-icons"> person </i>
        </div>
        <div class="user-info" (click)="toggleUserMenu()">
            <span *ngIf="!isTechnician">{{
                authorizedUser.role | humanReadableRole | translate
            }}</span>
            <span>{{
                (authorizedUser.firstName || 'Unnamed') +
                    ' ' +
                    (authorizedUser.lastName || 'User')
            }}</span>
        </div>
    </div>

    <div
        *ngIf="isUserMenuOpen"
        class="logout-and-terms-container"
        closeOnOutside
        [triggerClass]="['user-avatar', 'user-info']"
        (close)="toggleUserMenu()"
    >
        <div class="dropdown">
            <div
                *ngIf="profilePictureUrl"
                class="user-avatar not-clickable"
                [ngStyle]="{
                    'background-image': 'url(\'' + profilePictureUrl + '\')'
                }"
            ></div>
            <div *ngIf="!profilePictureUrl" class="user-avatar">
                <i class="material-icons"> person </i>
            </div>
            <div class="user-info">
                <p>{{ authorizedUser.role | humanReadableRole | translate }}</p>
                <p>
                    {{
                        (authorizedUser.firstName || 'Unnamed') +
                            ' ' +
                            (authorizedUser.lastName || 'User')
                    }}
                </p>
            </div>
            <borderless-button
                (onClick)="logout()"
                color="{{isBackofficeUser ? 'dark' : 'white'}}"
                text="LOGOUT"
            ></borderless-button>
            <div class="links">
                <p *ngIf="hasPriceMatricUrl">
                    <a a href="{{ getPriceMatrix }}" target="_blank">{{
                        'GENERAL.CHARGE_RATES' | translate
                    }}</a>
                </p>
                <p *ngIf="hasAviationCompanyChargeRatesDocumentLink">
                    <a href="{{ getAviationCompanyChargeRatesDocumentLink }}" target="_blank">
                        {{ 'GENERAL.CHARGE_RATES' | translate }}
                    </a>
                </p>
                <p>
                    <a href="{{ getPrivacyPolicy }}" target="_blank">{{
                        'GENERAL.PRIVACY_POLICY' | translate
                    }}</a>
                </p>
                <p>
                    <a a href="{{ getTermsAndConditions }}" target="_blank">{{
                        'GENERAL.T&C' | translate
                        }}</a>
                </p>
                <p *ngIf="!isBackofficeUser && !isPlatformIos">
                    <a
                        href="javascript:void(0);"
                        (click)="handleCookiesSettingsChange()"
                    >
                        {{ 'GENERAL.CHANGE_COOKIE_SETTINGS' | translate }}
                    </a>
                </p>
                <p *ngIf="isTechnician">
                    <a
                        href="javascript:void(0);"
                        (click)="openRequestDeletionModal()"
                    >
                        {{ 'GENERAL.DELETE_ACC' | translate }}
                    </a>
                </p>
            </div>
            <div *ngIf="version" class="version">
                {{ 'GENERAL.VERSION' | translate }} {{ version }}
            </div>
        </div>
    </div>
</header>
