import {Component, Input} from '@angular/core';
import {AbstractButton} from "@libs/common-ui/buttons/components/abstract.button";
import {isNil} from "lodash-es";


const buttonClassesMap: Map<string, string> = new Map();
buttonClassesMap.set('full-primary', 'btn-primary');
buttonClassesMap.set('full-primary-lowlight', 'btn-primary');
buttonClassesMap.set('outline-primary', 'btn-outline-primary');
buttonClassesMap.set('full-secondary', 'btn-secondary');
buttonClassesMap.set('full-secondary-low', 'full-secondary-low');
buttonClassesMap.set('outline-secondary', 'btn-outline-secondary');
buttonClassesMap.set('outline-danger', 'btn-outline-danger');
buttonClassesMap.set('outline-dark', 'btn-outline-dark');
buttonClassesMap.set('full-light-dark', 'full-light-dark');


@Component({
  selector: 'general-button',
  templateUrl: './general-button.component.html',
  styleUrls: ['./general-button.component.scss']
})
export class GeneralButtonComponent extends AbstractButton {
  @Input() public backgroundStyle: 'outline' | 'full' = 'full';
  @Input() public padding: string = null;
  @Input() public size: 'small' | 'medium' | 'large' = 'medium';
  @Input() public color: 'primary' | 'primary-lowlight' | 'secondary' | 'secondary-low' | 'danger' | 'dark' | 'light-dark' = 'primary';
  @Input() public icon: string = null;
  @Input() public iconPosition: 'left' | 'right' = 'left';
  @Input() public iconOutlined: boolean = false;
  @Input() public isLoading: boolean = false;
  @Input() public borderStraight: boolean = false;
  @Input() public circleDiameter: string = null;
  @Input() public shape: 'round' | 'rectangular' = 'rectangular';
  @Input() public type: 'button' | 'submit' = 'button';
  @Input() public customClass: string = 'disabled-btn-loader';

  public handleClick($event: MouseEvent): void {
    if (!this.isLoading) {
      super.handleClick($event);
    }
  }

  public isButtonSubmit(): boolean {
    return this.type == 'submit';
  }

  public isIconOnLeft(): boolean {
    return !isNil(this.icon) && this.iconPosition == 'left';
  }

  public isIconOnRight(): boolean {
    return !isNil(this.icon) && this.iconPosition == 'right';
  }

  public getIconClasses(): string[] {
    return ['icon', this.iconOutlined ? 'material-icons-outlined' : 'material-icons'];
  }

  public getButtonStyle(): object {
    const style: any = {};
    if (this.circleDiameter) {
      style.width = this.circleDiameter;
      style.height = this.circleDiameter;
    }
    if (this.padding) {
      style.padding = this.padding;
    }
    return style;
  }

  public getButtonClasses(): string[] {
    const classes: string[] = ['btn', this.shape, this.getButtonTypeClass(), this.getButtonSizeClass()];
    if (this.highlight) {
      classes.push('active');
    }
    if (this.color == 'primary-lowlight') {
      classes.push('primary-lowlight');
    }
    if (this.icon != null && this.text != null) {
      classes.push('icon-plus-text');
    }
    if (this.isLoading) {
      classes.push('loading');
    }
    if (this.borderStraight) {
      classes.push('border-straight');
    }
    return classes;
  }

  private getButtonTypeClass(): string {
    const combination: string = this.backgroundStyle + '-' + this.color;
    const buttonClass: string = buttonClassesMap.get(combination);
    if (buttonClass != null) {
      return buttonClass;
    } else {
      console.warn(`Button class ${combination} is undefined!!`);
      return 'btn-primary';
    }
  }

  private getButtonSizeClass(): string {
    switch (this.size) {
      case "large":
        return 'btn-lg';
      case "small":
        return 'btn-sm';
      default:
        return 'btn-md';
    }
  }

}
