import { FormGroupState } from 'ngrx-forms';

import { AccountFormModel, initialFormState } from '../components/views/account/account-form.model';
import { DocumentArea } from '../model/documents.model';
import { Availability } from '@libs/shared/models/availability.model';
import { UserProfileType } from '@libs/shared/models/user-profile.type';
import { TechnicianPerformanceFeedback } from '../components/views/backoffice-performance-feedback-management/technician-performance-feedback.model';
import { SimpleFacilityWithUuid } from '@libs/shared/models/facility.model';

export const USER_PROFILE_FEATURE_KEY = 'userProfile';

export interface BlocklistDto {
  selectedFacilities: SimpleFacilityWithUuid[];
  availableFacilities: SimpleFacilityWithUuid[];
}

export interface AccountState {
  accountForm?: FormGroupState<AccountFormModel>;
  availability: Availability;
  availabilityStart: string;
  profileUrl: string;
  profileLoading: boolean;
  userProfile: UserProfileType;
  blacklist: BlocklistDto;
  documentAreas: Array<DocumentArea>;
  isDownloadingAllDocuments: boolean;
  isLoadingDocumentAreas: boolean;
  feedbacks: Array<TechnicianPerformanceFeedback>;
}

const initialUserProfileState = {
  mroUuid: null,
  isExactMatch: true,
  isOwnTechnician: false,
  userPhone: null,
  refNumber: null,
  ameTitles: [],
  ameTitlesOld: [],
  technicianId: null,
  presentation: '',
  country: '',
  state: '',
  street: '',
  city: '',
  zipCode: '',
  experience: null,
  airplanes: [],
  skills: [],
  otherSkills: null,
  technicianSelfIdentificationOutDto: null,
  otherAirplanes: '',
  _links: {
    self: {
      href: ''
    },
    documents: {
      href: ''
    },
    profilePicture: {
      href: ''
    }
  },
  _embedded: {
    profile: {
      userUuid: '',
      firstName: 'Unnamed',
      lastName: 'User',
      pictureUrl: null,
      accountStatus: '',
      profileStatus: '',
      completed: true,
      email: '',
      role: '',
      _links: {
        self: {
          href: ''
        }
      }
    },
    documents: [],
    pendingChanges: [],
    missingData: [],
    ameNomenclature: []
  }
};

const initialAvailabilityState = {
  months: [],
  _embedded: {
    calendarEvents: []
  },
  _links: {
    self: {
      href: ''
    }
  }
};

export const initialState: AccountState = {
  accountForm: initialFormState,
  userProfile: initialUserProfileState,
  availability: initialAvailabilityState,
  profileUrl: null,
  profileLoading: false,
  availabilityStart: '',
  blacklist: {
    selectedFacilities: [],
    availableFacilities: []
  },
  documentAreas: [],
  isDownloadingAllDocuments: false,
  isLoadingDocumentAreas: true,
  feedbacks: []
};
