<div
    class="abstract-filter"
    [ngSwitch]="filterConfig.type"
    [ngClass]="filterConfig.className || ''"
    style="text-align: start"
>
    <label *ngIf="filterConfig.label && filterConfig.type != FILTER_TYPES.Boolean"
        >{{ filterConfig.label | translate }}
    </label>
    <text-filter
        class="filter-control"
        *ngSwitchCase="FILTER_TYPES.Text"
        (onEnterPressed)="isEnterPressed($event)"
        (onChange)="onChangeHandler($event)"
        [filterConfig]="filterConfig"
    ></text-filter>
    <date-filter
        class="filter-control"
        *ngSwitchCase="FILTER_TYPES.Date"
        (onChange)="onChangeHandler($event)"
        [filterConfig]="filterConfig"
    ></date-filter>
    <enum-filter
        class="filter-control"
        *ngSwitchCase="FILTER_TYPES.Enum"
        (onChange)="onChangeHandler($event)"
        [filterConfig]="filterConfig"
    ></enum-filter>
    <ame-type-license-filter
        *ngSwitchCase="FILTER_TYPES.AmeTypeLicense"
        (onChange)="onChangeHandler($event)"
        [initialValue]="filterConfig.initialValue"
        [disableType]="filterConfig.disabled"
    ></ame-type-license-filter>
    <number-filter
        class="filter-control"
        *ngSwitchCase="FILTER_TYPES.Number"
        (onChange)="onChangeHandler($event)"
        [filterConfig]="filterConfig"
    ></number-filter>
    <date-range-filter
        class="filter-control"
        *ngSwitchCase="FILTER_TYPES.Range"
        (onChange)="onChangeHandler($event)"
        [filterConfig]="filterConfig"
    ></date-range-filter>
    <boolean-filter
        class="filter-control"
        *ngSwitchCase="FILTER_TYPES.Boolean"
        [filterConfig]="filterConfig"
        (onChange)="onChangeHandler($event)"
    ></boolean-filter>
</div>
